import React, { useEffect } from "react";
// import Header from "./ui/Header2";

import dialogImg from "./img/ic_zaperon_setup_block.png";
import succImg from "./img/ic_success.svg";
import Footer from "./sections/Footer";
import { Box, Modal } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { isMobile, isTablet } from "react-device-detect";
import { useState } from "react";

const modalSx = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    // display:"flex",
    // flexDirection:"column",
    // justifyContent:"alignItems",
    width: "328px",
    height: "378px",
    bgcolor: "background.paper",
    border: "none",
    boxShadow: 24,
    p: 4,
    borderRadius: 3,
};

const AccountVerified = () => {
    const navigate = useNavigate();
    const [openModal, setOpenModal] = useState(false);

    const setupBtnClick = () => {
        if (isMobile || isTablet) {
            setOpenModal(true);
            return;
        }
        navigate("/downloadClientApp", {
            state: {
                "navigated": true
            }
        });
    };

    const getCookie = (name) => {
        const cookies = document.cookie.split(';');
        for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i].trim();
            if (cookie.startsWith(name + '=')) {
                return decodeURIComponent(cookie.substring(name.length + 1));
            }
        }
        return null;
    };

    useEffect(() => {
        const verified = getCookie('verified');
        if (verified !== 'true') {
            navigate('/linkExpired')
        }
        // eslint-disable-next-line
    }, [])

    return (
        <div>
            {/* <Header /> */}

            <Modal
                keepMounted
                open={openModal}
                aria-labelledby="keep-mounted-modal-title"
                aria-describedby="keep-mounted-modal-description"
            >
                <Box sx={modalSx}>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            marginTop: "30px",
                            marginBottom: "16px",
                        }}
                    >
                        <img src={dialogImg} alt="dialog" />
                    </div>
                    <div
                        style={{
                            font: "normal normal 600 16px/22px Poppins",
                            width: "280px",
                            color: "#0B3558",
                            textAlign: "center",
                        }}
                    >
                        Please use your corporate Laptop or PC to setup Zaperon.
                    </div>
                    <div
                        onClick={() => setOpenModal(false)}
                        style={{
                            width: "151px",
                            height: "47px",
                            background: "#003FB9",
                            font: "normal normal 600 16px/25px Poppins",
                            color: "#FFFFFF",
                            display: "flex",
                            marginTop: "24px",
                            marginLeft: "auto",
                            marginRight: "auto",
                            justifyContent: "center",
                            alignItems: "center"
                        }}
                    >
                        OK
                    </div>
                </Box>
            </Modal>

            <div
                style={{ marginTop: "0px", marginLeft: "auto", marginRight: "auto" }}
            >
                <div
                    style={{
                        height: "100vh",
                        width: "auto",
                        backgroundColor: "#EEF3FF",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                    }}
                >
                    <div style={{ marginTop: "120px" }}>
                        <Box
                            component={"img"}
                            src={succImg}
                            sx={{
                                width: {
                                    xs: "165px",
                                    md: "200px",
                                    lg: "300px",
                                },
                            }}
                        />
                    </div>
                    <Box
                        sx={{
                            font: {
                                lg: "normal normal bold 48px/72px Poppins",
                                md: "normal normal bold 16px/25px Poppins",
                                sm: "normal normal bold 16px/25px Poppins",
                                xs: "normal normal bold 16px/25px Poppins",
                            },
                            color: "#003FB9",
                            textAlign: "center",
                        }}
                    >
                        Account Verified
                    </Box>
                    <Box
                        sx={{
                            font: {
                                lg: "normal normal normal 20px/30px Poppins",
                                md: "normal normal normal 14px/21px Poppins",
                                xs: "normal normal normal 14px/21px Poppins",
                            },
                            paddingX: "20px",
                            color: "#0B3558",
                            textAlign: "center",
                            paddingBottom: "32px",
                        }}
                    >
                        Please click on the button below to complete your Zaperon setup now.
                    </Box>
                    <Box
                        onClick={setupBtnClick}
                        sx={{
                            width: {
                                lg: "299px",
                                md: "200px",
                                xs: "200px",
                            },
                            height: "52px",
                            background: "#003FB9",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            color: "#FFFFFF",
                            font: {
                                lg: "normal normal 600 20px/30px Poppins",
                                md: "normal normal 600 16px/25px Poppins",
                                xs: "normal normal 600 16px/25px Poppins",
                            },
                            // marginTop: "32px",
                            borderRadius: "4px",
                            cursor: "pointer",
                        }}
                    >
                        Start Zaperon Setup
                    </Box>
                    <Box
                        sx={{
                            display: "none",
                            color: "#003FB9",
                            // textDecoration: "underline",
                            font: {
                                lg: "normal normal 600 16px/25px Poppins",
                                md: "normal normal 600 12px/25px Poppins",
                                xs: "normal normal 600 12px/25px Poppins",
                            },
                            marginTop: "16px",
                            // marginBottom: "120px",
                        }}
                    >
                        <Link to={""}>Set Up Later</Link>
                    </Box>
                </div>
            </div>

            <div style={{ marginTop: "64px" }}>
                <Footer />
            </div>
        </div>
    );
};

export default AccountVerified;
