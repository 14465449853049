import React, { useState } from 'react';
import './HamburgerMenu.css';
import down from "../img/ic_dropdown.svg"
import { Link } from 'react-router-dom';
const HamburgerMenu = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);


  const toggleSubMenu = () => {
    setIsSubMenuOpen(!isSubMenuOpen);
  };

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };
  // const handleMenuItemClick = () => {
  //   console.log("click");
  // };

  const handleMenuClick = (event) => {
    if (!event.target.closest('.menu')) {
      toggleMenu();
    }
  }



  return (
    <div className={`hamburger-menu ${isOpen ? 'open' : ''}`} onClick={toggleMenu}>
      {props.bg === "transparent" ? (<>
        <div id={isOpen ? "background-white" : "background-white"} className="bar" ></div>
        <div id={isOpen ? "background-white" : "background-white"} className="bar"></div>
        <div id={isOpen ? "background-white" : "background-white"} className="bar"></div>
      </>
      ) : (
        <>
          <div id="background-blue" className="bar " ></div>
          <div id="background-blue" className="bar"></div>
          <div id="background-blue" className="bar"></div>
        </>
      )}
      {isOpen && (
        <div className="menu" onClick={handleMenuClick}>

          <Link to={'/keyCapabilities'} className="menulist">Product <img src={down} width={"20xp"} height={"10px"} className='downimg' alt="" /> </Link>
          <Link to={'/useCases'} className="menulist">Use Cases <img src={down} width={"20xp"} height={"10px"} className='downimg' alt="" /> </Link>
          <Link to="/blog"> <div className='menulist' onClick={toggleSubMenu}>Blogs <img src={down} width={"20xp"} height={"10px"} className='downimg' alt="" /> </div></Link>
          <a href="https://docs.zaperon.com/"> <div className='menulist' onClick={toggleSubMenu}>Documentation <img src={down} width={"20xp"} height={"10px"} className='downimg' alt="" /> </div></a>
          <Link to="/partners"> <div className='menulist' onClick={toggleSubMenu}>Partners <img src={down} width={"20xp"} height={"10px"} className='downimg' alt="" /> </div></Link>
          {/* {isSubMenuOpen && (
             
                <div className='menulist' onClick={handleMenuItemClick}>Submenu Option 1</div>
              
            )} */}
          <div className="menulist" onClick={() => { props.scroll("About") }}>About <img src={down} width={"20xp"} height={"10px"} className='downimg' alt="" /> </div>

        </div>
      )}
    </div>
  );
};



export default HamburgerMenu;
