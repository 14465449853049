import React from "react";
// import Header from "./ui/Header2";
import Footer from "./sections/Footer";

import linkExp from "./img/ic_activation_link_expired.svg";
import { Box } from "@mui/material";

const LinkExpired = () => {
    return (
        <div>
            {/* <Header /> */}
            <div
                style={{ marginTop: "0px", marginLeft: "auto", marginRight: "auto" }}
            >
                <div
                    style={{
                        height: "100vh",
                        width: "auto",
                        backgroundColor: "#EEF3FF",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                    }}
                >
                    <div style={{ marginTop: "110px" }}>
                        <Box component={"img"} src={linkExp} sx={{
                            width: {
                                xs: "165px", md: "200px", lg: "300px"
                            }
                        }} />
                    </div>
                    <Box
                        sx={{
                            font: {
                                lg: "normal normal bold 48px/72px Poppins",
                                md: "normal normal bold 16px/25px Poppins",
                                sm: "normal normal bold 16px/25px Poppins",
                                xs: "normal normal bold 16px/25px Poppins"
                            },
                            color: "#003FB9",
                            textAlign: "center",
                        }}
                    >
                        Activation Link Expired!
                    </Box>
                    <Box
                        sx={{
                            font: {
                                lg: "normal normal normal 20px/30px Poppins",
                                md: "normal normal normal 14px/21px Poppins",
                                xs: "normal normal normal 14px/21px Poppins"
                            },
                            maxWidth:"800px",
                            color: "#0B3558",
                            textAlign: "center",
                        }}
                    >
                        Your link to activate zaperon account has expired. Please contact your administrator for the new link.
                    </Box>
                    <Box
                        sx={{
                            width: {
                                lg: "149px",
                                md: "120px",
                                xs: "110px"
                            },
                            height: "52px",
                            background: "#003FB9",
                            display: "none",
                            justifyContent: "center",
                            alignItems: "center",
                            color: "#FFFFFF",
                            font: {
                                lg: "normal normal 600 20px/30px Poppins",
                                md: "normal normal 600 16px/25px Poppins",
                                xs: "normal normal 600 16px/25px Poppins"
                            },
                            marginTop: "32px",
                            // marginBottom: "100px",
                            borderRadius: "4px",
                        }}
                    >
                        Okay
                    </Box>
                </div>
            </div>
            <div style={{ marginTop: "64px" }}>
                <Footer />
            </div>
        </div>
    );
};

export default LinkExpired;
