import { AppBar, Container, Toolbar, Button, Box } from "@mui/material";
import React, { useState } from "react";
import { useNavigate, Link, NavLink } from "react-router-dom";
// import Hero from "../sections/Hero";
import blog from "../img/ic_blog.svg"
import link from "../img/ic_visit.svg"
import "../sections/css/header.css";
import down from "../img/ic_dropdown.svg"
import HamburgerMenu from "./HamburgerMenu";
import logo from "../img/ic_zaperon_logo.svg"


const pages = ["Solution", "Why Zaperon?", "Resources", "About"];

function Header(props) {

  const currPath = window.location.pathname;
  // eslint-disable-next-line
  const [colors, setColors] = useState([
    "#FFFFFF",
    "#003FB9",
    "#0B3558",
    "0px 3px 6px #0000001F",
  ]);
  const navigate = useNavigate();

  const scrollFunction = (id) => {
    navigate("/");
    try {
      console.log(id);
      document.getElementById(id).scrollIntoView(true);
      window.scrollTo(
        0,
        document.getElementById(id).getBoundingClientRect().top -
        70 +
        window.scrollY
      );
      window.scrollTo({
        top:
          document.getElementById(id).getBoundingClientRect().top -
          30 +
          window.scrollY,
        left: 0,
        behavior: "smooth",
      });
    } catch (e) { }
  };
  const handleLinkoff = (event) => {
    const parentElement = event.target;
    const image = parentElement.querySelector('.navlink');
    image.style.display = 'none';


  };

  const handleLinkon = (event) => {
    const parentElement = event.target;
    const image = parentElement.querySelector('.navlink');
    if (image && image !== null) {
      image.style.display = 'inline-flex';
    }

  };





  const handleHover = (event) => {
    const parentElement = event.target;
    const image = parentElement.querySelector('.rotate-image');
    image.style.transformOrigin = 'center';
    image.style.transform = 'rotateX(-180deg)';

  };
  const handleLeave = (event) => {
    const parentElement = event.target;
    const image = parentElement.querySelector('.rotate-image');
    if (image) {
      image.style.transformOrigin = 'initial';
      image.style.transform = 'rotateX(0deg)';
    }
  };
  //   const changeNavbarColor = () => {
  //     if (window.scrollY > 0) {
  //       setColors(["#FFFFFF", "#003FB9", "#0B3558", "0px 3px 6px #0000001F"]);
  //     } else {
  //       setColors(["transparent", "#FFFFFF", "#FFFFFF", "none"]);
  //     }
  //   };
  //   window.addEventListener("scroll", changeNavbarColor);

  // if(props.flag==="contact"){
  //   setColors(["#FFFFFF", "#003FB9", "#0B3558", "0px 3px 6px #0000001F"]);
  // }
  return (
    <AppBar
      position="static"
      sx={{
        backgroundColor: colors[0],
        m: "auto",
        position: "fixed",
        top: 0,
        zIndex: "50",
        boxShadow: colors[3],
      }}
    >
      <Container disableGutters>
        <Toolbar
          sx={{
            height: { lg: "80px", md: "80px", sm: "80px", xs: "48px" },
            display: "flex",
            alignItems: "center",
            justifyContent: "space-around",
          }}
        >
          <Link to="/">
            {/* <div href="/" className="headerLink"> */}
            <Box

              direction={"column"}
              display="flex"
              alignItems={"center"}
              justifyContent={"center"}
            >
              <Box
                component={"img"}
                src={logo}
                alt={"Z"}
              // sx={{ marginLeft: { lg: "89px", md: "10px" }, marginRight: "2px" }}
              />

            </Box>
            {/* </div> */}
          </Link>
          {(currPath === "/blog" || currPath === "/getBlogDetails") &&
            <span style={{font:"normal normal bold 24px/24px Poppins", color:"#0B3558", borderLeft: "3px solid #0B3558", marginLeft:"14px", paddingLeft:"12px", height:"33px", display:"flex", alignItems:"center"}}>
              Blogs
            </span>
          }
          <Box component={"span"} sx={{ flexGrow: { sm: 1, xs: 0 } }} />

          {pages.map((Pages, idx) => (
            Pages === "Resources" ?
              <Box key={idx} className="dropdown" onMouseEnter={handleHover}
                onMouseLeave={handleLeave} >
                <Button

                  key={Pages}

                  sx={{
                    mx: { md: "10px", sm: "10px" },
                    p: "10px",
                    display: { md: "block", sm: "none", xs: "none" },
                    font: "normal normal 600 16px/25px Poppins,sans-serif",
                    letterSpacing: 0,
                    color: colors[2],
                    textTransform: "none",
                    ":hover": {
                      color: "#3659A7",
                      background: "none",
                    },
                  }}
                ><span className="hideme">{Pages}{colors[2] !== "#0B3558" ? "" : <img className="rotate-image" src={down} alt="" style={{ display: "", paddingLeft: "10px", maxWidth: "30px" }} />}</span>
                </Button>
                <Box className="dropdown-content">
                  <div className="triangle-up"></div>
                  <Link to="/blog">
                    <Box
                      onMouseEnter={handleLinkon}
                      onMouseLeave={handleLinkoff}
                      sx={{
                        display: { md: "block", sm: "none", xs: "none" },
                        font: "normal normal 600 16px/25px Poppins,sans-serif",
                        letterSpacing: 0,
                        textTransform: "none",
                        color: "#3659A7",
                        background: "none",

                      }}
                    >

                      <img src={blog} style={{ display: "inline-flex", padding: "4px" }} alt="" />  Blog <img className="navlink" src={link} width={"20px"} style={{ display: "none", position: "absolute", right: "25px", top: "25px", padding: "4px" }} alt="" />
                    </Box></Link>
                </Box>
              </Box>

              : <Button
                key={Pages}
                onClick={() => scrollFunction(Pages)}
                sx={{
                  mx: { md: "10px", sm: "10px" },
                  p: "10px",
                  display: { md: "block", sm: "none", xs: "none" },
                  font: "normal normal 600 16px/25px Poppins,sans-serif",
                  letterSpacing: 0,
                  color: colors[2],
                  textTransform: "none",
                  ":hover": {
                    color: "#3659A7",
                    background: "none",
                  },
                }}
              >
                {Pages}
              </Button >
          ))}
          <Button
            sx={{ marginRight: {sm:"-30px",xs:"-50px"}, paddin: "0px", display: { md: "none", sm: "block", xs: "block" } }}>
            <HamburgerMenu scroll={scrollFunction} />
          </Button>
          <NavLink to='/partners' style={{textDecoration:"none"}}>
            <Button sx={{
              mx: { md: "20px", sm: "10px" },
              p: "10px",
              display: { md: "block", sm: "none", xs: "none" },
              font: "normal normal 600 16px/25px Poppins,sans-serif",
              letterSpacing: 0,
              color: colors[2],
              textTransform: "none",
              ":hover": {
                color: "#3659A7",
                background: "none",
              },
            }}
            >
              Partners
            </Button>
          </NavLink>
          <Link to="/contact">
            <Button
              // onClick={() => navigate("/contact")}

              key={"Request a Demo"}
              sx={{
                px: "24px",
                py: "12px",
                // mr: {lg:'89px',md:"20px"},
                backgroundColor: "#003FB9",
                boxShadow: "0px 3px 6px #003FB929",
                borderRadius: "4px",
                opacity: "1",
                color: "#FFFFFF",
                display: { md: "block", sm: "none", xs: "none" },
                textTransform: "none",
                font: "normal normal 600 16px/25px Poppins",
                ":hover": {
                  background: "#3659A7 0% 0% no-repeat padding-box",
                  boxShadow: "0px 3px 6px #003FB929",
                  borderRadius: "4px",
                },
                // mr:"10"
              }}
            >
              Request a Demo
            </Button>
          </Link>

        </Toolbar>
      </Container>
    </AppBar>
  );
}

export default Header;
