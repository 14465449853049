import React, { useEffect } from 'react'
// import Header from '../ui/Header2';
import { Box, Modal } from '@mui/material';
import Lottie from 'lottie-react';
import Loader from "../img/ic_zaperon_loader.json"
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const CheckUserStatus = () => {
    const navigate = useNavigate();
    const baseUrl = process.env.REACT_APP_BASE_URL;
    const apiUrl = `${baseUrl}getUserActivationStatus`;
    // console.log(baseUrl);
  
  
    const verifyActivationStatus = async (email) => {
      try {
  
        const headers = {
          'Content-Type': 'application/json',
        };
        const resp = await axios.get(`${apiUrl}?userEmail=${email}`, { headers });
        if(!resp.data || (resp.data.activationStatus !== "EMAIL_VERIFIED" && resp.data.activationStatus !== "ACTIVE")){
          console.log(resp.data.activationStatus)
            navigate("/*")
            return;
        }
        const expirationDate = new Date();
        expirationDate.setDate(expirationDate.getDate() + 7);
  // eslint-disable-next-line
        const cookieValue = encodeURIComponent('verified') + '; expires=' + expirationDate.toUTCString();
        // eslint-disable-next-line
        document.cookie = 'verified' + '=' + true;
        navigate("/downloadClientApp")
  
      } catch (error) {
        navigate("/*")
      }
    }
  
    useEffect(() => {
      const path = window.location.pathname;
      const email = path.split("/confirmed/")[1];
      verifyActivationStatus(email)
      // eslint-disable-next-line
    }, [])
  
    return (
      <>
        {/* <Header /> */}
        <div style={{ background: "#EAEFFB", width: "100%", height: "100vh" }}>
          <Modal
            keepMounted
            open={true}
            style={{ zIndex: "500" }}
            aria-labelledby="keep-mounted-modal-title"
            aria-describedby="keep-mounted-modal-description"
          >
            <Box sx={{ width: "100vw", height: "100vh", background: " #00000080 0% 0% no-repeat padding-box", display: "flex", alignItems: "center", justifyContent: "center" }}>
              <Lottie animationData={Loader} />
            </Box>
          </Modal>
        </div>
      </>
    )
  }

export default CheckUserStatus