import { Box, Container } from '@mui/material';
import React from 'react';
import './css/Brands.css'

import ms from "../img/companyLogo/ic_ms_for_startups.png"
import ncoe from "../img/companyLogo/ic_ncoe.png"
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const brand = [

    {
        key: 1,
        img: "./img/img_startupindia_logo.webp",
        link: "https://www.startupindia.gov.in/",
    },
    {
        key: 2,
        img: "./img/img_iitstartup_logo.webp",
        link: "https://iitstartups.org/",
    },
    {
        key: 3,
        img: "./img/img_dsci_logo.webp",
        link: "https://www.dsci.in/",
    },
    {
        key: 6,
        img: ncoe,
        link: "https://www.n-coe.in/"
    },
    {
        key: 4,
        img: "./img/img_iimcalcutta_logo.webp",
        link: "https://iimcip.org/"
    },
    {
        key: 5,
        img: ms,
        link: "https://www.microsoft.com/en-us/startups"
    }
]


function Brands() {

    const settings = {
       infinite: true,
        slidesToShow: 5,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 0,
        speed: 2800,
        cssEase: 'linear',
        arrows: false,
        pauseOnHover: false,
        responsive: [
            {
                breakpoint: 400, // For screens smaller than 400px
                settings: {
                    slidesToShow: Math.min(1, brand.length), // Show 1 logo per screen
                },
            },
            {
                breakpoint: 820, // For screens smaller than 768px
                settings: {
                    slidesToShow: Math.min(2, brand.length), // Show 2 logos or less if there are fewer logos
                },
            },
            {
                breakpoint: 3000, // For screens larger than or equal to 768px
                settings: {
                    slidesToShow: Math.min(4, brand.length), // Show 4 logos or less if there are fewer logos
                },
            }
        ],
    };

    return (
        <Box component={"div"} sx={{ background: '#FFF 0% 0% no-repeat', p: {sm:"80px 0px 80px 0px", xs: "64px 0px 64px 0px"}, marginTop: "0", marginBottom: "0" }}>
            <Container>
                <Slider {...settings}>
                    {brand.map((image, index) => (
                        <div key={index}>
                            <Box sx={{marginLeft:{xs:"40px"}}}>
                                <img src={image.img} alt={` ${index + 1}`} />
                            </Box>
                        </div>
                    ))}
                </Slider>
            
            </Container>
        </Box >
    )
}

export default Brands