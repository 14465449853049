import React, { useEffect, useState } from 'react'
import fb from './Firebase'

const DB = fb.firestore();
const BlogsList = DB.collection('blogs');

const BlogList = () => {

    const[blogs, setBlogs] = useState([]);

console.log("Blogss fetched : ", blogs)

    useEffect(()=>{
        const unsubscribe = BlogsList.limit(100).onSnapshot(querySnapshot => {
            // Get all documents from collection - with IDs
            const data = querySnapshot.docs.map(doc => ({
              ...doc.data(),
              id: doc.id,
            }));
            // Update state
            setBlogs(data);
          });
  
          // Detach listener
          return unsubscribe;
    },[])

  return (
    <div>BlogList</div>
  )
}

export default BlogList