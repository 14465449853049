import { Box, Container, IconButton, Modal, Stack, Typography } from '@mui/material'
import React, { useState } from 'react'
import playBtnWhite from "../img/ic_play_white.svg"
import playBtn from "../img/ic_play.svg"
import styles from './css/Solution.module.css'
// import 
const DemoVideo = () => {
    const [playBtnHover, setPlayBtnHover] = useState(false)
    const [playVideo, setPlayVideo] = useState(false);
    const modal = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "80%",
        height: "70%",
        bgcolor: "background.paper",
        boxShadow: 24,
        // p: 4,
        borderRadius: 3,
        background: "transparent"
      };
      const toggleVideo = () => {
        setPlayVideo(!playVideo)
      }
  return (
    <Box
   sx={{background: "#FFF", width: "100%", minHeight:{md: "746px", sm: '700px', xs: "300px"}, paddingBottom: {lg: "0px", xs: "0px"}}}
  >
    <Container sx={{maxHeight: "500px", minWidth: "100%"}} className={styles.waveBg}>
      <Box display={"flex"} flexDirection="column" justifyContent={"center"} alignItems={'center'}>
        <Typography
          variant="h2"
          sx={{
            p: {
              sm: "80px 0px 16px 0px",
              xs: "64px 0px 16px 0px",
            },
            font: {
              lg: "normal normal 600 48px/120% Poppins",
              md: "normal normal 600 48px/120% Poppins",
              sm: "normal normal 600 48px/120% Poppins",
              xs: "normal normal 600 32px/120% Poppins",
            },
            color: "#0B3558",
            textAlign: "center"
          }}
        >
        Identity Protection at your fingertips
        </Typography>
        <Typography sx={{fontWeight: "400", cursor: "pointer", fontSize: {sm: "20px", xs: "16px"}, textAlign: "center", marginBottom: {sm: "80px", xs: "32px"}}} color={"secondary"}>Discover the most secure Identity security that you can implement with ease</Typography>
        <Box width={{lg: "800px",sm: "704px", xs: "328px"}} minHeight={{lg: "450px", xs: "160px", sm: "341px"}} className={styles.demoVideoArea}>
    
              <Stack display={{ lg: "flex", xs: "flex" }} flex={1} justifyContent={'center'} alignItems={'center'} alignContent={'center'} alignSelf={'center'} gap={1} >
                <IconButton onClick={toggleVideo} sx={{
                  height: "fit-content", width: "fit-content", backgroundColor: "transparent", '&:hover': {
                    backgroundColor: "transparent "
                  }
                }}>
                  <Box onMouseEnter={() => setPlayBtnHover(true)} onMouseLeave={() => setPlayBtnHover(false)} className="circle" sx={{ cursor: "pointer", border: "2px solid #FFF", opacity: 0.75, transform: {sm: 'scale(1)', xs: 'scale(0.5)'} }}>
                    <div className="play" style={{opacity: 1}}>
                    <Box component={'img'} src={playBtnHover ? playBtnWhite : playBtn} alt="play" sx={{opacity: 1}} />
                    </div>
                  </Box>
                </IconButton>
               
              </Stack>
        </Box>
        </Box>
        </Container>
        <Modal
          keepMounted
          open={playVideo}
          onClose={toggleVideo}
          aria-labelledby="keep-mounted-modal-title"
          aria-describedby="keep-mounted-modal-description"
        >
          <Box sx={modal}>
            <iframe
              width="100%"
              height="100%"
              src={`https://www.youtube.com/embed/JuDaBBqjNE8?si=L_lmLRpM2OHYmhUA${playVideo ? '&autoplay=1&rel=0&modestbranding=1' : ''
                }`}
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
            ></iframe>


          </Box>
        </Modal>
        </Box>
  )
}

export default DemoVideo