/* eslint-disable */

import React from 'react'
// import Header from '../ui/Header2'
import Hero from '../img/img_partners_hero.webp'
import VideoBackground from './VideoBackground'
import Close from '../img/ic_close.svg'
import Footer from '../sections/Footer'
import Scroll_divider from '../sections/Scroll_divider'
import { useEffect } from 'react'
import { Alert, Box, Button, FormControl, Input, MenuItem, Modal, OutlinedInput, Select, TextField, Tooltip } from '@mui/material'
import DownArrow from '../img/ic_chevron_down.svg'
import SuccIcon from '../img/ic_success.svg'
import { PhoneInput } from 'react-international-phone'
import "react-international-phone/style.css";
import './VideoBackground.css'; // Import your custom CSS
import { useState } from 'react'
import Header from '../ui/Header2'
import { Link } from 'react-router-dom'
import { useRef } from 'react'
import emailjs from '@emailjs/browser';
import FieldError from '../ui/FieldError'
import { Helmet } from 'react-helmet'


const addTwo = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    maxWidth: "896px",
    minHeight: "619px",
    bgcolor: "background.paper",
    border: "0px solid #000",
    boxShadow: 24,
    p: 4,
    borderRadius: 3,
};

const countries = [
    "Afghanistan",
    "Albania",
    "Algeria",
    "Andorra",
    "Angola",
    "Antigua and Barbuda",
    "Argentina",
    "Armenia",
    "Australia",
    "Austria",
    "Azerbaijan",
    "Bahamas",
    "Bahrain",
    "Bangladesh",
    "Barbados",
    "Belarus",
    "Belgium",
    "Belize",
    "Benin",
    "Bhutan",
    "Bolivia",
    "Bosnia and Herzegovina",
    "Botswana",
    "Brazil",
    "Brunei",
    "Bulgaria",
    "Burkina Faso",
    "Burundi",
    "Cabo Verde",
    "Cambodia",
    "Cameroon",
    "Canada",
    "Central African Republic",
    "Chad",
    "Chile",
    "China",
    "Colombia",
    "Comoros",
    "Congo (Congo-Brazzaville)",
    "Costa Rica",
    "Croatia",
    "Cuba",
    "Cyprus",
    "Czechia (Czech Republic)",
    "Democratic Republic of the Congo (Congo-Kinshasa)",
    "Denmark",
    "Djibouti",
    "Dominica",
    "Dominican Republic",
    "East Timor (Timor-Leste)",
    "Ecuador",
    "Egypt",
    "El Salvador",
    "Equatorial Guinea",
    "Eritrea",
    "Estonia",
    "Eswatini",
    "Ethiopia",
    "Fiji",
    "Finland",
    "France",
    "Gabon",
    "Gambia",
    "Georgia",
    "Germany",
    "Ghana",
    "Greece",
    "Grenada",
    "Guatemala",
    "Guinea",
    "Guinea-Bissau",
    "Guyana",
    "Haiti",
    "Holy See",
    "Honduras",
    "Hungary",
    "Iceland",
    "India",
    "Indonesia",
    "Iran",
    "Iraq",
    "Ireland",
    "Israel",
    "Italy",
    "Ivory Coast",
    "Jamaica",
    "Japan",
    "Jordan",
    "Kazakhstan",
    "Kenya",
    "Kiribati",
    "Kosovo",
    "Kuwait",
    "Kyrgyzstan",
    "Laos",
    "Latvia",
    "Lebanon",
    "Lesotho",
    "Liberia",
    "Libya",
    "Liechtenstein",
    "Lithuania",
    "Luxembourg",
    "Madagascar",
    "Malawi",
    "Malaysia",
    "Maldives",
    "Mali",
    "Malta",
    "Marshall Islands",
    "Mauritania",
    "Mauritius",
    "Mexico",
    "Micronesia",
    "Moldova",
    "Monaco",
    "Mongolia",
    "Montenegro",
    "Morocco",
    "Mozambique",
    "Myanmar (formerly Burma)",
    "Namibia",
    "Nauru",
    "Nepal",
    "Netherlands",
    "New Zealand",
    "Nicaragua",
    "Niger",
    "Nigeria",
    "North Korea",
    "North Macedonia (formerly Macedonia)",
    "Norway",
    "Oman",
    "Pakistan",
    "Palau",
    "Palestine State",
    "Panama",
    "Papua New Guinea",
    "Paraguay",
    "Peru",
    "Philippines",
    "Poland",
    "Portugal",
    "Qatar",
    "Romania",
    "Russia",
    "Rwanda",
    "Saint Kitts and Nevis",
    "Saint Lucia",
    "Saint Vincent and the Grenadines",
    "Samoa",
    "San Marino",
    "Sao Tome and Principe",
    "Saudi Arabia",
    "Senegal",
    "Serbia",
    "Seychelles",
    "Sierra Leone",
    "Singapore",
    "Slovakia",
    "Slovenia",
    "Solomon Islands",
    "Somalia",
    "South Africa",
    "South Korea",
    "South Sudan",
    "Spain",
    "Sri Lanka",
    "Sudan",
    "Suriname",
    "Sweden",
    "Switzerland",
    "Syria",
    "Taiwan",
    "Tajikistan",
    "Tanzania",
    "Thailand",
    "Timor-Leste",
    "Togo",
    "Tonga",
    "Trinidad and Tobago",
    "Tunisia",
    "Turkey",
    "Turkmenistan",
    "Tuvalu",
    "Uganda",
    "Ukraine",
    "United Arab Emirates",
    "United Kingdom",
    "United States of America",
    "Uruguay",
    "Uzbekistan",
    "Vanuatu",
    "Venezuela",
    "Vietnam",
    "Yemen",
    "Zambia",
    "Zimbabwe"
]


const PartnerPage = () => {

    const [openModal, setOpenModal] = useState(false);
    const [openThankModal, setOpenThankModal] = useState(false);
    const [country, setCountry] = useState('Select Country');
    const [phone, setPhone] = useState();
    const [phoneErr, setPhoneErr] = useState(false)

    const form = useRef();

    useEffect(() => {
        document.title = 'Partner';
        document.getElementsByTagName("META")[2].content = "Become a Partner";
    }, []);

    console.log(phone)

    function handleSubmit(e) {
        e.preventDefault();
        if (!phone || phone.length <= 6) {
            setPhoneErr(true)
            return;
        }
        setOpenModal(false);
        emailjs.sendForm(process.env.REACT_APP_service_ID, process.env.REACT_APP_DEMO_template_ID, form.current, process.env.REACT_APP_public_key)
            .then((result) => {
                setOpenModal(false);
                setOpenThankModal(true);
                console.log(result.text);
            }, (error) => {
                setOpenModal(false);
                setOpenThankModal(true);
                console.log(error.text);
            });
    }

    useEffect(() => {
        window.scroll(0, 0)
    }, [])



    return (
        <div>
            <Helmet>
                <link rel="canonical" href={"https://www.zaperon.com/partners"} />
            </Helmet>
            {/* <Header /> */}
            <div className='heropartner' style={{ marginTop: "230px", marginLeft: "auto", marginRight: "auto", width: "75%", minHeight: "630px", display: "flex", justifyContent: "center" }}>
                <div>
                    <h1 className='hero-heading' style={{ font: "normal normal bold 48px/72px Poppins", color: "#003FB9" }}>Let's Protect Organizations Together</h1>

                    <p style={{ font: "normal normal normal 20px/30px Poppins", color: "#0B3558", maxWidth: "580px" }}>Zaperon is proud to work with partners and implement zero trust at hybrid workplaces enabling them to focus on their business and growth without worrying about security.</p>
                    <Button sx={{
                        width: "278px",
                        height: "52px",
                        backgroundColor: "#003FB9",
                        borderRadius: "4px",
                        color: "#FFFFFF",
                        border: "none",
                        textTransform:"none",
                        font: "normal normal 600 20px/30px Poppins",
                        display: {
                            lg: "block",
                            md: "block",
                            sm: "none",
                            xs: "none"
                        },
                        "&:hover": {
                            backgroundColor: "#003FB9",
                        }

                    }} onClick={() => setOpenModal(true)}>Become a partner</Button>
                </div>

                <div>
                    <img src={Hero} alt='Hero' />
                </div>

                <Button sx={{
                    width: "278px",
                    height: "52px",
                    backgroundColor: "#003FB9",
                    borderRadius: "4px",
                    textTransform:"none",
                    color: "#FFFFFF",
                    border: "none",
                    font: "normal normal 600 20px/30px Poppins",
                    marginBottom: "80px",
                    display: {
                        lg: "none",
                        md: "none",
                        sm: "block",
                        xs: "block"
                    }
                }} onClick={() => setOpenModal(true)}>Become a partner</Button>
            </div>

            <div>
                <VideoBackground />
            </div>
            <div>
                <div
                    className='tile'
                    style={{
                        width: "1140px",
                        height: "173px",
                        background: "transparent linear-gradient(97deg, #804A9C 0%, #003FB9 100%) 0% 0% no-repeat padding-box",
                        borderRadius: "16px",
                        margin: "auto",
                        marginTop: "64px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center"
                    }}
                >
                    <div>
                        <h2 style={{ font: "normal normal bold 32px/48px Poppins", color: "#FFFFFF" }}>Let's Protect Organizations Together</h2>
                        <p style={{ font: "normal normal normal 20px/30px Poppins", color: "#FFFFFF" }}>To elevate cybersecurity strategies & safeguarding assets</p>
                    </div>
                    <div>
                        <button onClick={() => setOpenModal(true)} style={{ marginLeft: "216px", backgroundColor: "#FFFFFF", borderRadius: "4px", height: "52px", width: "234px", color: "#003FB9", font: "normal normal 600 20px/30px Poppins", border: "none" }}>
                            Become a Partner
                        </button>
                    </div>
                </div>

                <div style={{ marginTop: "32px" }}>
                    <Scroll_divider className="scroll" />
                </div>
                <div style={{}}>
                    <Footer />
                </div>
            </div>

            <Modal
                keepMounted
                open={openModal}
                onClose={() => setOpenModal(false)}
                aria-labelledby="keep-mounted-modal-title"
                aria-describedby="keep-mounted-modal-description"
            >
                <Box sx={addTwo}>
                    <div style={{ display: "flex" }}>
                        <img src={Close} alt='close' style={{ marginLeft: "auto", cursor: "pointer" }} onClick={() => setOpenModal(false)} />
                    </div>
                    <div style={{ textAlign: "center" }}>
                        <h2 className='modal-head' style={{ font: "normal normal bold 32px/48px Poppins", color: "#003FB9" }}>Ready to Become a Partner?</h2>
                        <p className='modal-p' style={{ font: "normal normal normal 16px/25px Poppins", color: "#0B3558", padding: "0 70px" }}>Let's work together in making organizations secure by delivering the most comprehensive zero trust security solution</p>
                    </div>
                    <div>
                        <form ref={form} onSubmit={handleSubmit}>
                            <div className='modal-col' style={{ display: "flex", justifyContent: "center", marginBottom: "16px" }}>
                                <TextField
                                    sx={{
                                        width: "350px",
                                        height: "55px",
                                        marginRight: "16px",
                                        font: "normal normal 600 16px/25px Poppins",
                                        color: "#0B3558"
                                    }}
                                    className='textfield'
                                    required
                                    type='text'
                                    variant='outlined'
                                    label='First Name'
                                    name="from_firstname"
                                    id="from_firstname"
                                />

                                <TextField
                                    sx={{
                                        width: "350px",
                                        height: "55px"
                                    }}
                                    required
                                    type='text'
                                    className='textfield'
                                    variant='outlined'
                                    label='Last Name'
                                    name="from_lastname"
                                    id="from_lastname"
                                />
                            </div>

                            <div className='modal-col' style={{ display: "flex", justifyContent: "center", marginBottom: "16px" }}>
                                <TextField
                                    sx={{
                                        width: "350px",
                                        height: "55px",
                                        marginRight: "16px"
                                    }}
                                    required
                                    type='email'
                                    className='textfield'
                                    variant='outlined'
                                    label='Work Email'
                                    name="from_email"
                                    id="from_email"
                                />

                                <TextField
                                    sx={{
                                        width: "350px",
                                        height: "55px",
                                        font: "normal normal 600 16px/25px Poppins"
                                    }}
                                    required
                                    type='text'
                                    className='textfield'
                                    variant='outlined'
                                    label='Company Name'
                                    name="from_company"
                                    id="from_company"
                                />
                            </div>

                            <div className='modal-col' style={{ display: "flex", justifyContent: "center", marginBottom: "24px", position: "relative" }}>
                                <div>

                                    <Select
                                        sx={{
                                            width: "350px",
                                            height: "55px",
                                            color: " #0B3558",
                                            border: "#A2A2A2",
                                            // font: "normal normal 600 16px/25px Poppins",
                                            marginRight: "16px"
                                        }}
                                        className='textfield'
                                        value={country}
                                        onChange={(e) => setCountry(e.target.value)}
                                        displayEmpty
                                        name="application_type"
                                        IconComponent={() => (
                                            <img
                                                src={DownArrow}
                                                alt="Custom Arrow Icon"
                                                style={{ width: '24px', height: '24px', marginRight: "16px", cursor: "pointer" }} // Adjust the size as needed
                                            />
                                        )}
                                        // onChange={(event) => handleChange(event, true)}
                                        input={<OutlinedInput />}
                                        // value={}
                                        // MenuProps={MenuProps}
                                        inputProps={{ 'aria-label': 'Without label' }}
                                    >
                                        <MenuItem value={'Select Country'}>
                                            Select Country
                                        </MenuItem>
                                        {countries.map((country) => (
                                            <MenuItem key={country} value={country}>{country}</MenuItem>
                                        ))}

                                    </Select>

                                </div>
                                {/* Phone */}
                                {openModal && <div style={{ position: "relative" }}>
                                    <FieldError error={phoneErr} msg="Enter a valid phone number" top={"-50px"} left={"90px"} />
                                    <PhoneInput
                                        defaultCountry='in'
                                        inputStyle={{ height: "55px", width: "280px" }}
                                        inputClassName='number'
                                        required
                                        countrySelectorStyleProps={{ buttonStyle: { height: "55px", width: "60px", marginRight: "8px", backgroundColor: "#F9F9F9" } }}
                                        onChange={(e) => {
                                            setPhone(e)
                                            setPhoneErr(false)
                                        }}
                                        // className='number'
                                        placeholder='Phone Number*'
                                        style={{}}
                                        name="from_phone"
                                        id="from_phone"
                                    />

                                </div>}

                            </div>

                            <div style={{ display: "flex" }}>

                                <button
                                    type='submit'
                                    className='modal-btn'
                                    style={{
                                        height: "65px",
                                        width: "716px",
                                        backgroundColor: "#003FB9",
                                        borderRadius: "4px",
                                        margin: "auto",
                                        border: "none",
                                        font: "normal normal 600 20px/30px Poppins",
                                        color: "#FFFFFF"
                                    }}
                                >
                                    Get Started
                                </button>

                            </div>
                        </form>

                        <div style={{ textAlign: "center", display: "flex", justifyContent: "center", marginTop: "24px" }}>
                            <p style={{ font: "normal normal normal 16px/25px Poppins", color: "#0B3558", maxWidth: "347px" }}>
                                By submitting the form, you are agreeing to our <span style={{ font: " normal normal 600 16px/25px Poppins", color: "#003FB9" }}>
                                    <Link to="/terms-conditions" style={{ textDecoration: "underline", font: " normal normal 600 16px/25px Poppins", color: "#003FB9" }}>
                                        Terms {" "}
                                    </Link>
                                </span>
                                & {" "}
                                <span style={{ font: " normal normal 600 16px/25px Poppins" }}>
                                    <Link to="/privacyPolicy" style={{ textDecoration: "underline", font: " normal normal 600 16px/25px Poppins", color: "#003FB9" }}>
                                        Privacy Policy
                                    </Link>
                                </span>.
                            </p>
                        </div>
                    </div>

                </Box>
            </Modal>

            <Modal
                keepMounted
                open={openThankModal}
                onClose={() => setOpenThankModal(false)}
                aria-labelledby="keep-mounted-modal-title"
                aria-describedby="keep-mounted-modal-description"
            >
                <Box sx={addTwo}>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                        <img src={SuccIcon} alt='Success icon' />
                    </div>
                    <div style={{ textAlign: "center" }}>
                        <h2 style={{ font: "normal normal bold 32px/48px Poppins", color: "#003FB9" }}>Thank You for Showing Interest!</h2>
                        <p style={{ font: "normal normal normal 16px/25px Poppins", color: "#0B3558" }}>We can't wait to become your partner.<br /> We will contact you shortly.</p>
                    </div>

                    <div style={{ display: "flex", justifyContent: "center", marginTop: "48px" }}>
                        <button
                            onClick={() => setOpenThankModal(false)}
                            style={{
                                width: "213px",
                                height: "52px",
                                border: "none",
                                borderRadius: "4px",
                                font: "normal normal 600 20px/30px Poppins",
                                color: "#FFFFFF",
                                backgroundColor: "#003FB9"
                            }}>
                            Okay, Got It
                        </button>
                    </div>
                </Box>
            </Modal>
        </div>
    )
}

export default PartnerPage