/* eslint-disable */

import React, { useEffect, useRef } from "react";
import Header2 from "../ui/Header2";
import Footer from "./Footer";
import emailjs from '@emailjs/browser';
import { Link } from "react-router-dom";
import {
  Grid,
  TextField,
  Button,
  Card,
  CardContent,
  Typography,
} from "@mui/material";
// import MuiPhoneNumber from 'material-ui-phone-number';
import "./css/contact.css";
import { Container, Box } from "@mui/system";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

function Contact() {
  const form = useRef();

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    document.title = 'Contact us for demo';
    document.getElementsByTagName("META")[2].content = "Contact us to schedule a Demo";
  }, []);

  const navigate = useNavigate();

  function handleSubmit(e) {
    e.preventDefault();
    emailjs.sendForm(process.env.REACT_APP_service_ID, process.env.REACT_APP_DEMO_template_ID, form.current, process.env.REACT_APP_public_key)
      .then((result) => {
        console.log(result.text);
      }, (error) => {
        console.log(error.text);
      });



    navigate("/thanks");
  }

  return (
    // <Container className="formContact">
    <div className="contact-full">
      <Helmet>
        <link rel="canonical" href={"https://www.zaperon.com/contact"} />
      </Helmet>
      {/* <Header2 /> */}
      <div className="form-contact">
        <div className="image-h">
          <Box
            // width={{xs:"48px"}}
            // height={{xs:"54px"}}
            marginLeft={{ lg: "-65px", md: "-65px", sm: "-65px", xs: "-50px" }}
            // sx={{marginLeft:{lg:"-65px",md:"-65px"}}}
            className="img-hi"
            component={"img"}
            src="./img/ic_callout.png"
            alt=""
            sx={{
              background:
                "transparent url('img/bg.svg') 0% 0% no-repeat padding-box",
            }}
          />
          <Typography
            gutterBottom
            variant="h1"
            textAlign={"center"}
            sx={{

              font: {
                lg: "normal normal bold 32px/48px Poppins",
                md: "normal normal bold 32px/48px Poppins",
                sm: "normal normal bold 32px/48px Poppins",
                xs: "normal normal bold 16px/25px Poppins",
              },

              color: "#003FB9",
              paddingBottom: "20px",
            }}
          >
            Contact us to schedule a Demo
          </Typography>
        </div>
        <div className="form">

          <form ref={form} onSubmit={handleSubmit}>
            <Grid
              container
              spacing={1}
              width={{ lg: "620px", sm: "620px", md: "620px", xs: "360px" }}
            >
              <Grid lg={6} sm={6} item>
                <TextField
                  // margin={{xs:"20px"}}
                  placeholder="Enter first name"
                  label="First Name"
                  variant="outlined"
                  fullWidth
                  required
                  name="from_firstname"
                  id="from_firstname"
                  sx={{

                    width: {
                      lg: "300px",
                      md: "300px",
                      sm: "300px",
                      xs: "158px",
                    },
                    '& .MuiFormLabel-root': {
                      fontSize: '16px',
                    },
                    fontSize: "16px",
                    height: { lg: "55px", xs: "55px" },
                    background: "#FFFFFF 0% 0% no-repeat padding-box",
                    opacity: "1",
                    marginBottom: "12px",
                  }}
                  style={{
                    font: "normal normal 600 16px/25px Poppins"
                  }}
                  inputProps={{ style: { font: "normal normal 600 16px/25px Poppins" } }}
                />
              </Grid>
              <Grid sm={6} item>

                <TextField
                  placeholder="Enter last name"
                  label="Last Name"
                  variant="outlined"
                  fullWidth
                  name="from_lastname"
                  id="from_lastname"
                  required

                  sx={{

                    width: {
                      lg: "300px",
                      md: "300px",
                      sm: "300px",
                      xs: "160px",
                    },
                    '& .MuiFormLabel-root': {
                      fontSize: '16px',
                    },
                    fontStyle: "normal normal 600 20px/18px Poppins",
                    fontSize: "16px",
                    height: { lg: "55px", xs: "55px" },
                    background: "#FFFFFF 0% 0% no-repeat padding-box",
                    opacity: "1",
                    marginBottom: "12px",

                    // marginLeft: { lg: "-16px",md:"-16px" },
                  }}
                  inputProps={{ style: { font: "normal normal 600 16px/25px Poppins" } }}
                />
              </Grid>
              <Grid xs={12} sm={6} item>
                <TextField
                  placeholder="Company Name"
                  label="Company Name"
                  variant="outlined"
                  name="from_company"
                  id="from_company"
                  fullWidth
                  sx={{

                    width: {
                      lg: "300px",
                      md: "300px",
                      sm: "300px",
                      xs: "328px",
                    },
                    '& .MuiFormLabel-root': {
                      fontSize: '16px',
                    },
                    fontStyle: "normal normal 600 20px/18px Poppins",
                    fontSize: "16px",
                    height: { lg: "55px", sm: "55px", md: "55px", xs: "55px" },
                    background: "#FFFFFF 0% 0% no-repeat padding-box",
                    opacity: "1",
                    marginBottom: "12px",
                  }}
                  inputProps={{ style: { font: "normal normal 600 16px/25px Poppins" } }}
                />
              </Grid>
              <Grid xs={12} sm={6} item>
                <TextField
                  placeholder="Designation"
                  label="Designation"
                  variant="outlined"
                  name="from_designation"
                  id="from_designation"
                  fullWidth
                  required
                  sx={{

                    width: {
                      lg: "300px",
                      md: "300px",
                      sm: "300px",
                      xs: "328px",
                    },
                    '& .MuiFormLabel-root': {
                      fontSize: '16px',
                    },
                    fontStyle: "normal normal 600 20px/18px Poppins",
                    fontSize: "16px",
                    height: { lg: "55px", sm: "55px", md: "55px", xs: "55px" },
                    background: "#FFFFFF 0% 0% no-repeat padding-box",
                    opacity: "1",
                    marginBottom: "12px",

                    // marginLeft: { lg: "-16px",md:"-16px" },
                  }}
                  inputProps={{ style: { font: "normal normal 600 16px/25px Poppins" } }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  type="email"
                  placeholder="Work Email"
                  label="Work Email"
                  variant="outlined"
                  fullWidth
                  required
                  name="from_email"
                  id="from_email"
                  sx={{

                    width: {
                      lg: "610px",
                      md: "610px",
                      sm: "610px",
                      xs: "328px",
                    },
                    '& .MuiFormLabel-root': {
                      fontSize: '16px',
                    },
                    fontStyle: "normal normal 600 20px/18px Poppins",
                    fontSize: "16px",
                    height: { lg: "55px", sm: "55px", md: "55px", xs: "55px" },
                    background: "#FFFFFF 0% 0% no-repeat padding-box",
                    opacity: "1",
                    marginBottom: "12px",
                  }}
                  inputProps={{ style: { font: "normal normal 600 16px/25px Poppins" } }}
                />
              </Grid>
              <Grid item lg={12} xs={12} sm={12}>
                {/* <h4><a href="https://intl-tel-input.com/" target="_blank">Country Code Selection</a></h4> */}
                {/* <Grid> */}

                <select className="ccode" name="from_countrycode"
                  defaultValue="91"
                  id="from_countrycode">
                  <option style={{ font: "normal normal 600 16px/25px Poppins" }} data-countrycode="IN" value="91">
                    India +91
                  </option>
                  <option style={{ font: "normal normal 600 16px/25px Poppins" }} data-countrycode="GB" value="44">
                    UK (+44)
                  </option>
                  <option style={{ font: "normal normal 600 16px/25px Poppins" }} data-countrycode="US" value="1">
                    USA (+1)
                  </option>
                  <optgroup style={{ font: "normal normal 500 14px/25px Poppins" }} label="Other countries">
                    <option style={{ font: "normal normal 600 16px/25px Poppins" }} data-countrycode="DZ" value="213">
                      Algeria (+213)
                    </option>
                    <option style={{ font: "normal normal 600 16px/25px Poppins" }} data-countrycode="AD" value="376">
                      Andorra (+376)
                    </option>
                    <option style={{ font: "normal normal 600 16px/25px Poppins" }} data-countrycode="AO" value="244">
                      Angola (+244)
                    </option>
                    <option style={{ font: "normal normal 600 16px/25px Poppins" }} data-countrycode="AI" value="1264">
                      Anguilla (+1264)
                    </option>
                    <option style={{ font: "normal normal 600 16px/25px Poppins" }} data-countrycode="AG" value="1268">
                      Antigua &amp; Barbuda (+1268)
                    </option>
                    <option style={{ font: "normal normal 600 16px/25px Poppins" }} data-countrycode="AR" value="54">
                      Argentina (+54)
                    </option>
                    <option style={{ font: "normal normal 600 16px/25px Poppins" }} data-countrycode="AM" value="374">
                      Armenia (+374)
                    </option>
                    <option style={{ font: "normal normal 600 16px/25px Poppins" }} data-countrycode="AW" value="297">
                      Aruba (+297)
                    </option>
                    <option style={{ font: "normal normal 600 16px/25px Poppins" }} data-countrycode="AU" value="61">
                      Australia (+61)
                    </option>
                    <option style={{ font: "normal normal 600 16px/25px Poppins" }} data-countrycode="AT" value="43">
                      Austria (+43)
                    </option>
                    <option style={{ font: "normal normal 600 16px/25px Poppins" }} data-countrycode="AZ" value="994">
                      Azerbaijan (+994)
                    </option>
                    <option style={{ font: "normal normal 600 16px/25px Poppins" }} data-countrycode="BS" value="1242">
                      Bahamas (+1242)
                    </option>
                    <option style={{ font: "normal normal 600 16px/25px Poppins" }} data-countrycode="BH" value="973">
                      Bahrain (+973)
                    </option>
                    <option style={{ font: "normal normal 600 16px/25px Poppins" }} data-countrycode="BD" value="880">
                      Bangladesh (+880)
                    </option>
                    <option style={{ font: "normal normal 600 16px/25px Poppins" }} data-countrycode="BB" value="1246">
                      Barbados (+1246)
                    </option>
                    <option style={{ font: "normal normal 600 16px/25px Poppins" }} data-countrycode="BY" value="375">
                      Belarus (+375)
                    </option>
                    <option style={{ font: "normal normal 600 16px/25px Poppins" }} data-countrycode="BE" value="32">
                      Belgium (+32)
                    </option>
                    <option style={{ font: "normal normal 600 16px/25px Poppins" }} data-countrycode="BZ" value="501">
                      Belize (+501)
                    </option>
                    <option style={{ font: "normal normal 600 16px/25px Poppins" }} data-countrycode="BJ" value="229">
                      Benin (+229)
                    </option>
                    <option style={{ font: "normal normal 600 16px/25px Poppins" }} data-countrycode="BM" value="1441">
                      Bermuda (+1441)
                    </option>
                    <option style={{ font: "normal normal 600 16px/25px Poppins" }} data-countrycode="BT" value="975">
                      Bhutan (+975)
                    </option>
                    <option style={{ font: "normal normal 600 16px/25px Poppins" }} data-countrycode="BO" value="591">
                      Bolivia (+591)
                    </option>
                    <option style={{ font: "normal normal 600 16px/25px Poppins" }} data-countrycode="BA" value="387">
                      Bosnia Herzegovina (+387)
                    </option>
                    <option style={{ font: "normal normal 600 16px/25px Poppins" }} data-countrycode="BW" value="267">
                      Botswana (+267)
                    </option>
                    <option style={{ font: "normal normal 600 16px/25px Poppins" }} data-countrycode="BR" value="55">
                      Brazil (+55)
                    </option>
                    <option style={{ font: "normal normal 600 16px/25px Poppins" }} data-countrycode="BN" value="673">
                      Brunei (+673)
                    </option>
                    <option style={{ font: "normal normal 600 16px/25px Poppins" }} data-countrycode="BG" value="359">
                      Bulgaria (+359)
                    </option>
                    <option style={{ font: "normal normal 600 16px/25px Poppins" }} data-countrycode="BF" value="226">
                      Burkina Faso (+226)
                    </option>
                    <option style={{ font: "normal normal 600 16px/25px Poppins" }} data-countrycode="BI" value="257">
                      Burundi (+257)
                    </option>
                    <option style={{ font: "normal normal 600 16px/25px Poppins" }} data-countrycode="KH" value="855">
                      Cambodia (+855)
                    </option>
                    <option style={{ font: "normal normal 600 16px/25px Poppins" }} data-countrycode="CM" value="237">
                      Cameroon (+237)
                    </option>
                    <option style={{ font: "normal normal 600 16px/25px Poppins" }} data-countrycode="CA" value="1">
                      Canada (+1)
                    </option>
                    <option style={{ font: "normal normal 600 16px/25px Poppins" }} data-countrycode="CV" value="238">
                      Cape Verde Islands (+238)
                    </option>
                    <option style={{ font: "normal normal 600 16px/25px Poppins" }} data-countrycode="KY" value="1345">
                      Cayman Islands (+1345)
                    </option>
                    <option style={{ font: "normal normal 600 16px/25px Poppins" }} data-countrycode="CF" value="236">
                      Central African Republic (+236)
                    </option>
                    <option style={{ font: "normal normal 600 16px/25px Poppins" }} data-countrycode="CL" value="56">
                      Chile (+56)
                    </option>
                    <option style={{ font: "normal normal 600 16px/25px Poppins" }} data-countrycode="CN" value="86">
                      China (+86)
                    </option>
                    <option style={{ font: "normal normal 600 16px/25px Poppins" }} data-countrycode="CO" value="57">
                      Colombia (+57)
                    </option>
                    <option style={{ font: "normal normal 600 16px/25px Poppins" }} data-countrycode="KM" value="269">
                      Comoros (+269)
                    </option>
                    <option style={{ font: "normal normal 600 16px/25px Poppins" }} data-countrycode="CG" value="242">
                      Congo (+242)
                    </option>
                    <option style={{ font: "normal normal 600 16px/25px Poppins" }} data-countrycode="CK" value="682">
                      Cook Islands (+682)
                    </option>
                    <option style={{ font: "normal normal 600 16px/25px Poppins" }} data-countrycode="CR" value="506">
                      Costa Rica (+506)
                    </option>
                    <option style={{ font: "normal normal 600 16px/25px Poppins" }} data-countrycode="HR" value="385">
                      Croatia (+385)
                    </option>
                    <option style={{ font: "normal normal 600 16px/25px Poppins" }} data-countrycode="CU" value="53">
                      Cuba (+53)
                    </option>
                    <option style={{ font: "normal normal 600 16px/25px Poppins" }} data-countrycode="CY" value="90392">
                      Cyprus North (+90392)
                    </option>
                    <option style={{ font: "normal normal 600 16px/25px Poppins" }} data-countrycode="CY" value="357">
                      Cyprus South (+357)
                    </option>
                    <option style={{ font: "normal normal 600 16px/25px Poppins" }} data-countrycode="CZ" value="42">
                      Czech Republic (+42)
                    </option>
                    <option style={{ font: "normal normal 600 16px/25px Poppins" }} data-countrycode="DK" value="45">
                      Denmark (+45)
                    </option>
                    <option style={{ font: "normal normal 600 16px/25px Poppins" }} data-countrycode="DJ" value="253">
                      Djibouti (+253)
                    </option>
                    <option style={{ font: "normal normal 600 16px/25px Poppins" }} data-countrycode="DM" value="1809">
                      Dominica (+1809)
                    </option>
                    <option style={{ font: "normal normal 600 16px/25px Poppins" }} data-countrycode="DO" value="1809">
                      Dominican Republic (+1809)
                    </option>
                    <option style={{ font: "normal normal 600 16px/25px Poppins" }} data-countrycode="EC" value="593">
                      Ecuador (+593)
                    </option>
                    <option style={{ font: "normal normal 600 16px/25px Poppins" }} data-countrycode="EG" value="20">
                      Egypt (+20)
                    </option>
                    <option style={{ font: "normal normal 600 16px/25px Poppins" }} data-countrycode="SV" value="503">
                      El Salvador (+503)
                    </option>
                    <option style={{ font: "normal normal 600 16px/25px Poppins" }} data-countrycode="GQ" value="240">
                      Equatorial Guinea (+240)
                    </option>
                    <option style={{ font: "normal normal 600 16px/25px Poppins" }} data-countrycode="ER" value="291">
                      Eritrea (+291)
                    </option>
                    <option style={{ font: "normal normal 600 16px/25px Poppins" }} data-countrycode="EE" value="372">
                      Estonia (+372)
                    </option>
                    <option style={{ font: "normal normal 600 16px/25px Poppins" }} data-countrycode="ET" value="251">
                      Ethiopia (+251)
                    </option>
                    <option style={{ font: "normal normal 600 16px/25px Poppins" }} data-countrycode="FK" value="500">
                      Falkland Islands (+500)
                    </option>
                    <option style={{ font: "normal normal 600 16px/25px Poppins" }} data-countrycode="FO" value="298">
                      Faroe Islands (+298)
                    </option>
                    <option style={{ font: "normal normal 600 16px/25px Poppins" }} data-countrycode="FJ" value="679">
                      Fiji (+679)
                    </option>
                    <option style={{ font: "normal normal 600 16px/25px Poppins" }} data-countrycode="FI" value="358">
                      Finland (+358)
                    </option>
                    <option style={{ font: "normal normal 600 16px/25px Poppins" }} data-countrycode="FR" value="33">
                      France (+33)
                    </option>
                    <option style={{ font: "normal normal 600 16px/25px Poppins" }} data-countrycode="GF" value="594">
                      French Guiana (+594)
                    </option>
                    <option style={{ font: "normal normal 600 16px/25px Poppins" }} data-countrycode="PF" value="689">
                      French Polynesia (+689)
                    </option>
                    <option style={{ font: "normal normal 600 16px/25px Poppins" }} data-countrycode="GA" value="241">
                      Gabon (+241)
                    </option>
                    <option style={{ font: "normal normal 600 16px/25px Poppins" }} data-countrycode="GM" value="220">
                      Gambia (+220)
                    </option>
                    <option style={{ font: "normal normal 600 16px/25px Poppins" }} data-countrycode="GE" value="7880">
                      Georgia (+7880)
                    </option>
                    <option style={{ font: "normal normal 600 16px/25px Poppins" }} data-countrycode="DE" value="49">
                      Germany (+49)
                    </option>
                    <option style={{ font: "normal normal 600 16px/25px Poppins" }} data-countrycode="GH" value="233">
                      Ghana (+233)
                    </option>
                    <option style={{ font: "normal normal 600 16px/25px Poppins" }} data-countrycode="GI" value="350">
                      Gibraltar (+350)
                    </option>
                    <option style={{ font: "normal normal 600 16px/25px Poppins" }} data-countrycode="GR" value="30">
                      Greece (+30)
                    </option>
                    <option style={{ font: "normal normal 600 16px/25px Poppins" }} data-countrycode="GL" value="299">
                      Greenland (+299)
                    </option>
                    <option style={{ font: "normal normal 600 16px/25px Poppins" }} data-countrycode="GD" value="1473">
                      Grenada (+1473)
                    </option>
                    <option style={{ font: "normal normal 600 16px/25px Poppins" }} data-countrycode="GP" value="590">
                      Guadeloupe (+590)
                    </option>
                    <option style={{ font: "normal normal 600 16px/25px Poppins" }} data-countrycode="GU" value="671">
                      Guam (+671)
                    </option>
                    <option style={{ font: "normal normal 600 16px/25px Poppins" }} data-countrycode="GT" value="502">
                      Guatemala (+502)
                    </option>
                    <option style={{ font: "normal normal 600 16px/25px Poppins" }} data-countrycode="GN" value="224">
                      Guinea (+224)
                    </option>
                    <option style={{ font: "normal normal 600 16px/25px Poppins" }} data-countrycode="GW" value="245">
                      Guinea - Bissau (+245)
                    </option>
                    <option style={{ font: "normal normal 600 16px/25px Poppins" }} data-countrycode="GY" value="592">
                      Guyana (+592)
                    </option>
                    <option style={{ font: "normal normal 600 16px/25px Poppins" }} data-countrycode="HT" value="509">
                      Haiti (+509)
                    </option>
                    <option style={{ font: "normal normal 600 16px/25px Poppins" }} data-countrycode="HN" value="504">
                      Honduras (+504)
                    </option>
                    <option style={{ font: "normal normal 600 16px/25px Poppins" }} data-countrycode="HK" value="852">
                      Hong Kong (+852)
                    </option>
                    <option style={{ font: "normal normal 600 16px/25px Poppins" }} data-countrycode="HU" value="36">
                      Hungary (+36)
                    </option>
                    <option style={{ font: "normal normal 600 16px/25px Poppins" }} data-countrycode="IS" value="354">
                      Iceland (+354)
                    </option>
                    <option style={{ font: "normal normal 600 16px/25px Poppins" }} data-countrycode="ID" value="62">
                      Indonesia (+62)
                    </option>
                    <option style={{ font: "normal normal 600 16px/25px Poppins" }} data-countrycode="IR" value="98">
                      Iran (+98)
                    </option>
                    <option style={{ font: "normal normal 600 16px/25px Poppins" }} data-countrycode="IQ" value="964">
                      Iraq (+964)
                    </option>
                    <option style={{ font: "normal normal 600 16px/25px Poppins" }} data-countrycode="IE" value="353">
                      Ireland (+353)
                    </option>
                    <option style={{ font: "normal normal 600 16px/25px Poppins" }} data-countrycode="IL" value="972">
                      Israel (+972)
                    </option>
                    <option style={{ font: "normal normal 600 16px/25px Poppins" }} data-countrycode="IT" value="39">
                      Italy (+39)
                    </option>
                    <option style={{ font: "normal normal 600 16px/25px Poppins" }} data-countrycode="JM" value="1876">
                      Jamaica (+1876)
                    </option>
                    <option style={{ font: "normal normal 600 16px/25px Poppins" }} data-countrycode="JP" value="81">
                      Japan (+81)
                    </option>
                    <option style={{ font: "normal normal 600 16px/25px Poppins" }} data-countrycode="JO" value="962">
                      Jordan (+962)
                    </option>
                    <option style={{ font: "normal normal 600 16px/25px Poppins" }} data-countrycode="KZ" value="7">
                      Kazakhstan (+7)
                    </option>
                    <option style={{ font: "normal normal 600 16px/25px Poppins" }} data-countrycode="KE" value="254">
                      Kenya (+254)
                    </option>
                    <option style={{ font: "normal normal 600 16px/25px Poppins" }} data-countrycode="KI" value="686">
                      Kiribati (+686)
                    </option>
                    <option style={{ font: "normal normal 600 16px/25px Poppins" }} data-countrycode="KP" value="850">
                      Korea North (+850)
                    </option>
                    <option style={{ font: "normal normal 600 16px/25px Poppins" }} data-countrycode="KR" value="82">
                      Korea South (+82)
                    </option>
                    <option style={{ font: "normal normal 600 16px/25px Poppins" }} data-countrycode="KW" value="965">
                      Kuwait (+965)
                    </option>
                    <option style={{ font: "normal normal 600 16px/25px Poppins" }} data-countrycode="KG" value="996">
                      Kyrgyzstan (+996)
                    </option>
                    <option style={{ font: "normal normal 600 16px/25px Poppins" }} data-countrycode="LA" value="856">
                      Laos (+856)
                    </option>
                    <option style={{ font: "normal normal 600 16px/25px Poppins" }} data-countrycode="LV" value="371">
                      Latvia (+371)
                    </option>
                    <option style={{ font: "normal normal 600 16px/25px Poppins" }} data-countrycode="LB" value="961">
                      Lebanon (+961)
                    </option>
                    <option style={{ font: "normal normal 600 16px/25px Poppins" }} data-countrycode="LS" value="266">
                      Lesotho (+266)
                    </option>
                    <option style={{ font: "normal normal 600 16px/25px Poppins" }} data-countrycode="LR" value="231">
                      Liberia (+231)
                    </option>
                    <option style={{ font: "normal normal 600 16px/25px Poppins" }} data-countrycode="LY" value="218">
                      Libya (+218)
                    </option>
                    <option style={{ font: "normal normal 600 16px/25px Poppins" }} data-countrycode="LI" value="417">
                      Liechtenstein (+417)
                    </option>
                    <option style={{ font: "normal normal 600 16px/25px Poppins" }} data-countrycode="LT" value="370">
                      Lithuania (+370)
                    </option>
                    <option style={{ font: "normal normal 600 16px/25px Poppins" }} data-countrycode="LU" value="352">
                      Luxembourg (+352)
                    </option>
                    <option style={{ font: "normal normal 600 16px/25px Poppins" }} data-countrycode="MO" value="853">
                      Macao (+853)
                    </option>
                    <option style={{ font: "normal normal 600 16px/25px Poppins" }} data-countrycode="MK" value="389">
                      Macedonia (+389)
                    </option>
                    <option style={{ font: "normal normal 600 16px/25px Poppins" }} data-countrycode="MG" value="261">
                      Madagascar (+261)
                    </option>
                    <option style={{ font: "normal normal 600 16px/25px Poppins" }} data-countrycode="MW" value="265">
                      Malawi (+265)
                    </option>
                    <option style={{ font: "normal normal 600 16px/25px Poppins" }} data-countrycode="MY" value="60">
                      Malaysia (+60)
                    </option>
                    <option style={{ font: "normal normal 600 16px/25px Poppins" }} data-countrycode="MV" value="960">
                      Maldives (+960)
                    </option>
                    <option style={{ font: "normal normal 600 16px/25px Poppins" }} data-countrycode="ML" value="223">
                      Mali (+223)
                    </option>
                    <option style={{ font: "normal normal 600 16px/25px Poppins" }} data-countrycode="MT" value="356">
                      Malta (+356)
                    </option>
                    <option style={{ font: "normal normal 600 16px/25px Poppins" }} data-countrycode="MH" value="692">
                      Marshall Islands (+692)
                    </option>
                    <option style={{ font: "normal normal 600 16px/25px Poppins" }} data-countrycode="MQ" value="596">
                      Martinique (+596)
                    </option>
                    <option style={{ font: "normal normal 600 16px/25px Poppins" }} data-countrycode="MR" value="222">
                      Mauritania (+222)
                    </option>
                    <option style={{ font: "normal normal 600 16px/25px Poppins" }} data-countrycode="YT" value="269">
                      Mayotte (+269)
                    </option>
                    <option style={{ font: "normal normal 600 16px/25px Poppins" }} data-countrycode="MX" value="52">
                      Mexico (+52)
                    </option>
                    <option style={{ font: "normal normal 600 16px/25px Poppins" }} data-countrycode="FM" value="691">
                      Micronesia (+691)
                    </option>
                    <option style={{ font: "normal normal 600 16px/25px Poppins" }} data-countrycode="MD" value="373">
                      Moldova (+373)
                    </option>
                    <option style={{ font: "normal normal 600 16px/25px Poppins" }} data-countrycode="MC" value="377">
                      Monaco (+377)
                    </option>
                    <option style={{ font: "normal normal 600 16px/25px Poppins" }} data-countrycode="MN" value="976">
                      Mongolia (+976)
                    </option>
                    <option style={{ font: "normal normal 600 16px/25px Poppins" }} data-countrycode="MS" value="1664">
                      Montserrat (+1664)
                    </option>
                    <option style={{ font: "normal normal 600 16px/25px Poppins" }} data-countrycode="MA" value="212">
                      Morocco (+212)
                    </option>
                    <option style={{ font: "normal normal 600 16px/25px Poppins" }} data-countrycode="MZ" value="258">
                      Mozambique (+258)
                    </option>
                    <option style={{ font: "normal normal 600 16px/25px Poppins" }} data-countrycode="MN" value="95">
                      Myanmar (+95)
                    </option>
                    <option style={{ font: "normal normal 600 16px/25px Poppins" }} data-countrycode="NA" value="264">
                      Namibia (+264)
                    </option>
                    <option style={{ font: "normal normal 600 16px/25px Poppins" }} data-countrycode="NR" value="674">
                      Nauru (+674)
                    </option>
                    <option style={{ font: "normal normal 600 16px/25px Poppins" }} data-countrycode="NP" value="977">
                      Nepal (+977)
                    </option>
                    <option style={{ font: "normal normal 600 16px/25px Poppins" }} data-countrycode="NL" value="31">
                      Netherlands (+31)
                    </option>
                    <option style={{ font: "normal normal 600 16px/25px Poppins" }} data-countrycode="NC" value="687">
                      New Caledonia (+687)
                    </option>
                    <option style={{ font: "normal normal 600 16px/25px Poppins" }} data-countrycode="NZ" value="64">
                      New Zealand (+64)
                    </option>
                    <option style={{ font: "normal normal 600 16px/25px Poppins" }} data-countrycode="NI" value="505">
                      Nicaragua (+505)
                    </option>
                    <option style={{ font: "normal normal 600 16px/25px Poppins" }} data-countrycode="NE" value="227">
                      Niger (+227)
                    </option>
                    <option style={{ font: "normal normal 600 16px/25px Poppins" }} data-countrycode="NG" value="234">
                      Nigeria (+234)
                    </option>
                    <option style={{ font: "normal normal 600 16px/25px Poppins" }} data-countrycode="NU" value="683">
                      Niue (+683)
                    </option>
                    <option style={{ font: "normal normal 600 16px/25px Poppins" }} data-countrycode="NF" value="672">
                      Norfolk Islands (+672)
                    </option>
                    <option style={{ font: "normal normal 600 16px/25px Poppins" }} data-countrycode="NP" value="670">
                      Northern Marianas (+670)
                    </option>
                    <option style={{ font: "normal normal 600 16px/25px Poppins" }} data-countrycode="NO" value="47">
                      Norway (+47)
                    </option>
                    <option style={{ font: "normal normal 600 16px/25px Poppins" }} data-countrycode="OM" value="968">
                      Oman (+968)
                    </option>
                    <option style={{ font: "normal normal 600 16px/25px Poppins" }} data-countrycode="PW" value="680">
                      Palau (+680)
                    </option>
                    <option style={{ font: "normal normal 600 16px/25px Poppins" }} data-countrycode="PA" value="507">
                      Panama (+507)
                    </option>
                    <option style={{ font: "normal normal 600 16px/25px Poppins" }} data-countrycode="PG" value="675">
                      Papua New Guinea (+675)
                    </option>
                    <option style={{ font: "normal normal 600 16px/25px Poppins" }} data-countrycode="PY" value="595">
                      Paraguay (+595)
                    </option>
                    <option style={{ font: "normal normal 600 16px/25px Poppins" }} data-countrycode="PE" value="51">
                      Peru (+51)
                    </option>
                    <option style={{ font: "normal normal 600 16px/25px Poppins" }} data-countrycode="PH" value="63">
                      Philippines (+63)
                    </option>
                    <option style={{ font: "normal normal 600 16px/25px Poppins" }} data-countrycode="PL" value="48">
                      Poland (+48)
                    </option>
                    <option style={{ font: "normal normal 600 16px/25px Poppins" }} data-countrycode="PT" value="351">
                      Portugal (+351)
                    </option>
                    <option style={{ font: "normal normal 600 16px/25px Poppins" }} data-countrycode="PR" value="1787">
                      Puerto Rico (+1787)
                    </option>
                    <option style={{ font: "normal normal 600 16px/25px Poppins" }} data-countrycode="QA" value="974">
                      Qatar (+974)
                    </option>
                    <option style={{ font: "normal normal 600 16px/25px Poppins" }} data-countrycode="RE" value="262">
                      Reunion (+262)
                    </option>
                    <option style={{ font: "normal normal 600 16px/25px Poppins" }} data-countrycode="RO" value="40">
                      Romania (+40)
                    </option>
                    <option style={{ font: "normal normal 600 16px/25px Poppins" }} data-countrycode="RU" value="7">
                      Russia (+7)
                    </option>
                    <option style={{ font: "normal normal 600 16px/25px Poppins" }} data-countrycode="RW" value="250">
                      Rwanda (+250)
                    </option>
                    <option style={{ font: "normal normal 600 16px/25px Poppins" }} data-countrycode="SM" value="378">
                      San Marino (+378)
                    </option>
                    <option style={{ font: "normal normal 600 16px/25px Poppins" }} data-countrycode="ST" value="239">
                      Sao Tome &amp; Principe (+239)
                    </option>
                    <option style={{ font: "normal normal 600 16px/25px Poppins" }} data-countrycode="SA" value="966">
                      Saudi Arabia (+966)
                    </option>
                    <option style={{ font: "normal normal 600 16px/25px Poppins" }} data-countrycode="SN" value="221">
                      Senegal (+221)
                    </option>
                    <option style={{ font: "normal normal 600 16px/25px Poppins" }} data-countrycode="CS" value="381">
                      Serbia (+381)
                    </option>
                    <option style={{ font: "normal normal 600 16px/25px Poppins" }} data-countrycode="SC" value="248">
                      Seychelles (+248)
                    </option>
                    <option style={{ font: "normal normal 600 16px/25px Poppins" }} data-countrycode="SL" value="232">
                      Sierra Leone (+232)
                    </option>
                    <option style={{ font: "normal normal 600 16px/25px Poppins" }} data-countrycode="SG" value="65">
                      Singapore (+65)
                    </option>
                    <option style={{ font: "normal normal 600 16px/25px Poppins" }} data-countrycode="SK" value="421">
                      Slovak Republic (+421)
                    </option>
                    <option style={{ font: "normal normal 600 16px/25px Poppins" }} data-countrycode="SI" value="386">
                      Slovenia (+386)
                    </option>
                    <option style={{ font: "normal normal 600 16px/25px Poppins" }} data-countrycode="SB" value="677">
                      Solomon Islands (+677)
                    </option>
                    <option style={{ font: "normal normal 600 16px/25px Poppins" }} data-countrycode="SO" value="252">
                      Somalia (+252)
                    </option>
                    <option style={{ font: "normal normal 600 16px/25px Poppins" }} data-countrycode="ZA" value="27">
                      South Africa (+27)
                    </option>
                    <option style={{ font: "normal normal 600 16px/25px Poppins" }} data-countrycode="ES" value="34">
                      Spain (+34)
                    </option>
                    <option style={{ font: "normal normal 600 16px/25px Poppins" }} data-countrycode="LK" value="94">
                      Sri Lanka (+94)
                    </option>
                    <option style={{ font: "normal normal 600 16px/25px Poppins" }} data-countrycode="SH" value="290">
                      St. Helena (+290)
                    </option>
                    <option style={{ font: "normal normal 600 16px/25px Poppins" }} data-countrycode="KN" value="1869">
                      St. Kitts (+1869)
                    </option>
                    <option style={{ font: "normal normal 600 16px/25px Poppins" }} data-countrycode="SC" value="1758">
                      St. Lucia (+1758)
                    </option>
                    <option style={{ font: "normal normal 600 16px/25px Poppins" }} data-countrycode="SD" value="249">
                      Sudan (+249)
                    </option>
                    <option style={{ font: "normal normal 600 16px/25px Poppins" }} data-countrycode="SR" value="597">
                      Suriname (+597)
                    </option>
                    <option style={{ font: "normal normal 600 16px/25px Poppins" }} data-countrycode="SZ" value="268">
                      Swaziland (+268)
                    </option>
                    <option style={{ font: "normal normal 600 16px/25px Poppins" }} data-countrycode="SE" value="46">
                      Sweden (+46)
                    </option>
                    <option style={{ font: "normal normal 600 16px/25px Poppins" }} data-countrycode="CH" value="41">
                      Switzerland (+41)
                    </option>
                    <option style={{ font: "normal normal 600 16px/25px Poppins" }} data-countrycode="SI" value="963">
                      Syria (+963)
                    </option>
                    <option style={{ font: "normal normal 600 16px/25px Poppins" }} data-countrycode="TW" value="886">
                      Taiwan (+886)
                    </option>
                    <option style={{ font: "normal normal 600 16px/25px Poppins" }} data-countrycode="TJ" value="7">
                      Tajikstan (+7)
                    </option>
                    <option style={{ font: "normal normal 600 16px/25px Poppins" }} data-countrycode="TH" value="66">
                      Thailand (+66)
                    </option>
                    <option style={{ font: "normal normal 600 16px/25px Poppins" }} data-countrycode="TG" value="228">
                      Togo (+228)
                    </option>
                    <option style={{ font: "normal normal 600 16px/25px Poppins" }} data-countrycode="TO" value="676">
                      Tonga (+676)
                    </option>
                    <option style={{ font: "normal normal 600 16px/25px Poppins" }} data-countrycode="TT" value="1868">
                      Trinidad &amp; Tobago (+1868)
                    </option>
                    <option style={{ font: "normal normal 600 16px/25px Poppins" }} data-countrycode="TN" value="216">
                      Tunisia (+216)
                    </option>
                    <option style={{ font: "normal normal 600 16px/25px Poppins" }} data-countrycode="TR" value="90">
                      Turkey (+90)
                    </option>
                    <option style={{ font: "normal normal 600 16px/25px Poppins" }} data-countrycode="TM" value="7">
                      Turkmenistan (+7)
                    </option>
                    <option style={{ font: "normal normal 600 16px/25px Poppins" }} data-countrycode="TM" value="993">
                      Turkmenistan (+993)
                    </option>
                    <option style={{ font: "normal normal 600 16px/25px Poppins" }} data-countrycode="TC" value="1649">
                      Turks &amp; Caicos Islands (+1649)
                    </option>
                    <option style={{ font: "normal normal 600 16px/25px Poppins" }} data-countrycode="TV" value="688">
                      Tuvalu (+688)
                    </option>
                    <option style={{ font: "normal normal 600 16px/25px Poppins" }} data-countrycode="UG" value="256">
                      Uganda (+256)
                    </option>
                    <option style={{ font: "normal normal 600 16px/25px Poppins" }} data-countrycode="GB" value="44">
                      UK (+44)
                    </option>
                    <option style={{ font: "normal normal 600 16px/25px Poppins" }} data-countrycode="UA" value="380">
                      Ukraine (+380)
                    </option>
                    <option style={{ font: "normal normal 600 16px/25px Poppins" }} data-countrycode="AE" value="971">
                      United Arab Emirates (+971)
                    </option>
                    <option style={{ font: "normal normal 600 16px/25px Poppins" }} data-countrycode="UY" value="598">
                      Uruguay (+598)
                    </option>
                    <option style={{ font: "normal normal 600 16px/25px Poppins" }} data-countrycode="US" value="1">
                      USA (+1)
                    </option>
                    <option style={{ font: "normal normal 600 16px/25px Poppins" }} data-countrycode="UZ" value="7">
                      Uzbekistan (+7)
                    </option>
                    <option style={{ font: "normal normal 600 16px/25px Poppins" }} data-countrycode="VU" value="678">
                      Vanuatu (+678)
                    </option>
                    <option style={{ font: "normal normal 600 16px/25px Poppins" }} data-countrycode="VA" value="379">
                      Vatican City (+379)
                    </option>
                    <option style={{ font: "normal normal 600 16px/25px Poppins" }} data-countrycode="VE" value="58">
                      Venezuela (+58)
                    </option>
                    <option style={{ font: "normal normal 600 16px/25px Poppins" }} data-countrycode="VN" value="84">
                      Vietnam (+84)
                    </option>
                    <option style={{ font: "normal normal 600 16px/25px Poppins" }} data-countrycode="VG" value="84">
                      Virgin Islands - British (+1284)
                    </option>
                    <option style={{ font: "normal normal 600 16px/25px Poppins" }} data-countrycode="VI" value="84">
                      Virgin Islands - US (+1340)
                    </option>
                    <option style={{ font: "normal normal 600 16px/25px Poppins" }} data-countrycode="WF" value="681">
                      Wallis &amp; Futuna (+681)
                    </option>
                    <option style={{ font: "normal normal 600 16px/25px Poppins" }} data-countrycode="YE" value="969">
                      Yemen (North)(+969)
                    </option>
                    <option style={{ font: "normal normal 600 16px/25px Poppins" }} data-countrycode="YE" value="967">
                      Yemen (South)(+967)
                    </option>
                    <option style={{ font: "normal normal 600 16px/25px Poppins" }} data-countrycode="ZM" value="260">
                      Zambia (+260)
                    </option>
                    <option style={{ font: "normal normal 600 16px/25px Poppins" }} data-countrycode="ZW" value="263">
                      Zimbabwe (+263)
                    </option>
                  </optgroup>
                </select>
                {/* </Grid> */}
                <TextField
                  // marginRight="0px"
                  type="text"

                  placeholder="Enter phone number"
                  label="Phone Number"
                  variant="outlined"
                  name="from_phone"
                  id="from_phone"
                  fullWidth
                  required
                  sx={{

                    width: {
                      lg: "471px",
                      md: "471px",
                      sm: "471px",
                      xs: "213px",
                    },
                    '& .MuiFormLabel-root': {
                      fontSize: '16px',
                    },
                    fontStyle: "normal normal 600 20px/18px Poppins",
                    fontSize: "16px",
                    height: { lg: "55px", sm: "55px", md: "55px", xs: "55px" },

                    background: "#FFFFFF 0% 0% no-repeat padding-box",
                    opacity: "1",
                  }}
                  inputProps={{ style: { font: "normal normal 600 16px/25px Poppins" } }}
                />
              </Grid>
              {/* <MuiPhoneNumber defaultCountry={'us'}/> */}
              {/* <PhoneInput
                  country={"us"}
                /> */}

              <Grid item xs={12}>
                <Button
                  type="submit"
                  variant="contained"
                  fullWidth
                  sx={{

                    marginTop: "15px",
                    background: "#003FB9 0% 0% no-repeat padding-box",
                    height: { lg: "65px", md: "60px", sm: "60px", xs: "55px" },
                    width: {
                      lg: "610px",
                      md: "610px",
                      sm: "610px",
                      xs: "328px",
                    },

                    // background: "#003FB9 0% 0% no-repeat padding-box",
                    borderRadius: "4px",
                    opacity: 1,
                    font: "normal normal 600 20px/30px Poppins",
                    textTransform: "none",
                  }}
                >
                  Get Started
                </Button>
              </Grid>
            </Grid>
          </form>
        </div>
        <Typography
          // className="tc-contact"
          gutterBottom
          variant="h5"
          textAlign={"center"}
          sx={{

            font: {
              lg: "normal normal normal 16px/25px Poppins",
              md: "normal normal normal 14px/21px Poppins",
              sm: "normal normal normal 14px/21px Poppins",
              xs: "normal normal normal 12px/18px Poppins",
            },

            color: "#0B3558",
            letterSpacing: 0,
            marginTop: "30px",
            // paddingBottom: "20px",
          }}
        >
          By submitting the form, you are agreeing to
        </Typography>
        <Typography
          paddingBottom={{ lg: "20px", md: "20px", sm: "20px", xs: "0px" }}
          sx={{
            color: "#0B3558",
            font: {
              lg: "normal normal 600 16px/25px Poppins",
              md: "normal normal 600 14px/21px Poppins",
              sm: "normal normal 600 14px/21px Poppins",
              xs: "normal normal 600 12px/18px Poppins",
            },
            textDecoration: "none",
            marginRight: { lg: "23px" },
          }}
        >
          <Link to="/terms-conditions">Terms</Link> &
          <Link to="/privacyPolicy"> Privacy Policy</Link>
        </Typography>
      </div>
      <Footer />
    </div>
  );
}

export default Contact;

<Container
  className="formContact"
//   sx={{ width: "720px", height: "519px", color: "#EEF3FF" }}
>
  {/* <Typography gutterBottom variant="h3" align="center" ></Typography> */}
  <Grid>
    <Card>
      <CardContent>
        {/* <Box
                component={"img"}
                src="./img/bg.svg"
                alt=""
                sx={{
                  background:
                    "transparent url('img/bg.svg') 0% 0% no-repeat padding-box",
                }}
              /> */}
        <Typography
          gutterBottom
          variant="h5"
          textAlign={"center"}
          sx={{
            font: "normal normal bold 32px/48px Poppins",
            color: "#003FB9",
            paddingBottom: "20px",
          }}
        >
          Contact us to schedule a Demo
        </Typography>
        <Typography
          variant="body2"
          color="textSecondary"
          component="p"
          gutterBottom
        ></Typography>
        <form>
          <Grid container spacing={1}>
            <Grid xs={12} sm={6} item>
              <TextField
                placeholder="Enter first name"
                label="First Name"
                variant="outlined"
                fullWidth
                required
              />
            </Grid>
            <Grid xs={12} sm={6} item>
              <TextField
                placeholder="Enter last name"
                label="Last Name"
                variant="outlined"
                fullWidth
                required

              />
            </Grid>
            <Grid xs={12} sm={6} item>
              <TextField
                placeholder="Company Name"
                label="Company Name"
                variant="outlined"
                fullWidth
              />
            </Grid>
            <Grid xs={12} sm={6} item>
              <TextField
                placeholder="Designation"
                label="Designation"
                variant="outlined"
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                type="email"
                placeholder="Work Email"
                label="Work Email"
                variant="outlined"
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={12} sm={10}>
              <TextField
                type="number"
                placeholder="Enter phone number"
                label="Phone Number"
                variant="outlined"
                fullWidth
                required
              />
            </Grid>
            {/* <MuiPhoneNumber defaultCountry={'us'}/> */}
            {/* <PhoneInput
                  country={"us"}
                /> */}

            <Grid item xs={12}>
              <Button
                type="submit"
                variant="contained"
                fullWidth
                sx={{
                  background: "#003FB9 0% 0% no-repeat padding-box",
                  height: "65px",
                  width: "616px",
                }}
                onSubmit={() => { }}
              >
                Get Started
              </Button>
            </Grid>
          </Grid>
        </form>
      </CardContent>
    </Card>
  </Grid>
</Container>;
