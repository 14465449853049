/* eslint-disable */

import React, { useEffect } from "react";
import Header2 from "../ui/Header2";
import { Box, Typography, List, ListItem } from "@mui/material";
import Footer from "./Footer";
import Scroll_divider from "./Scroll_divider";
import { Helmet } from "react-helmet";

const TermsCondition = () => {

  useEffect(() => {
    window.scroll(0, 0)
  }, [])

  useEffect(() => {
    document.title = 'Terms & Conditions';
    document.getElementsByTagName("META")[2].content = "Zaperon Zero Trust Terms & Conditions";
  }, []);
  return (
    <div>
      <Helmet>
        <link rel="canonical" href={"https://www.zaperon.com/terms-conditions"} />
      </Helmet>
      {/* <Header2 /> */}
      <Box
        sx={{
          height: { lg: "200px", md: "200px", sm: "200px", xs: "164px" },
          background: "#EEF3FF 0% 0% no-repeat padding-box",
          textAlign: "center",
        }}
      >
        <Typography
          gutterBottom
          variant="h1"
          textAlign={"center"}
          sx={{
            font: {
              lg: "normal normal bold 32px/48px Poppins",
              md: "normal normal bold 32px/48px Poppins",
              sm: " normal normal bold 24px/35px Poppins",
              xs: "normal normal bold 16px/25px Poppins",
            },
            color: "#003FB9",
            marginTop: { lg: "80px", md: "80px", sm: "80px", xs: "48px" },
            paddingTop: { lg: "78px", md: "78px", sm: "80px", xs: "65px" },
          }}
        >
          TERMS & CONDITIONS
        </Typography>
      </Box>
      <Box>
        <Typography
          sx={{
            textAlign: "left",
            alignItems: "left",
            font: {
              lg: "normal normal normal 16px/25px Poppins",
              sm: "normal normal normal 16px/25px Poppins",
              md: "normal normal normal 16px/25px Poppins",
              xs: "normal normal normal 12px/18px Poppins",
            },
            letterSpacing: "0px",
            color: "#0B3558",
            marginTop: "48px",
            paddingLeft: { lg: "111px", md: "111px", sm: "32px", xs: "20px" },
            paddingRight: { lg: "111px", md: "111px", sm: "32px", xs: "20px" },
          }}
        >
          Please read these Terms of Service (“Terms”) carefully before using
          website, products or services offered by Zaperon that is registered as
          FinLock Technologies Private Limited under the Companies Act, 2013
          (‘FinLock/ Us/ Zaperon/ Company’), having its registered office at
          N-222, First Floor, Greater Kailash Part-1, New Delhi-110048. This
          agreement describes certain terms and conditions to access and use
          Zaperon products and its Informational Channels (‘Agreement’) by a
          visitor or a user.
        </Typography>
      </Box>
      <Box marginTop="48px">
        <Typography
          sx={{
            textAlign: "left",
            font: {
              lg: "normal normal 600 32px/48px Poppins",
              sm: "normal normal 600 24px/35px Poppins",
              md: "normal normal 600 32px/48px Poppins",
              xs: "normal normal 600 16px/25px Poppins",
            },
            letterSpacing: "0px",
            color: "#0B3558",
            opacity: "1",
            marginBottom: { lg: "-30px", xs: "16px" },
            paddingLeft: { lg: "111px", md: "111px", sm: "32px", xs: "20px" },
            paddingRight: { lg: "111px", md: "111px", sm: "32px", xs: "20px" },
          }}
        >
          1. Acceptance of Terms and Conditions/ this Agreement:
        </Typography>
        <Typography
          sx={{
            textAlign: "left",
            alignItems: "left",
            font: {
              lg: "normal normal normal 16px/25px Poppins",
              sm: "normal normal normal 16px/25px Poppins",
              md: "normal normal normal 16px/25px Poppins",
              xs: "normal normal normal 12px/18px Poppins",
            },
            letterSpacing: "0px",
            color: "#0B3558",
            marginTop: { lg: "48px" },
            paddingLeft: { lg: "111px", md: "111px", sm: "32px", xs: "20px" },
            paddingRight: { lg: "111px", md: "111px", sm: "32px", xs: "20px" },
          }}
        >
          {" "}
          <List sx={{ listStyleType: "disc", pl: 7 }}>
            <ListItem
              sx={{
                display: "list-item",
                font: {
                  lg: "normal normal normal 16px/25px Poppins",
                  sm: "normal normal normal 16px/25px Poppins",
                  md: "normal normal normal 16px/25px Poppins",
                  xs: "normal normal normal 12px/18px Poppins",
                },
                letterSpacing: "0px",
                color: "#0B3558",
                //   paddingLeft: { lg: "111px", md: "111px", sm: "111px", xs: "20px" },
                // paddingRight: { lg: "111px", md: "111px", sm: "111px", xs: "20px" },
              }}
            >
              It is understood that every visitor or user has read these terms
              and conditions carefully and by downloading the Zaperon products
              and/ or accessing the Informational Channels, agreed to be bound
              by the terms and conditions below.
            </ListItem>
            <ListItem
              sx={{
                display: "list-item",
                font: {
                  lg: "normal normal normal 16px/25px Poppins",
                  sm: "normal normal normal 16px/25px Poppins",
                  md: "normal normal normal 16px/25px Poppins",
                  xs: "normal normal normal 12px/18px Poppins",
                },
                letterSpacing: "0px",
                color: "#0B3558",
              }}
            >
              Zaperon reserves the right to alter, amend and modify these terms
              and conditions at its sole discretion. All such amendments and
              modifications will be duly notified on the website. You are
              responsible for being familiar with the current version of these
              terms and conditions at every point at which you access the
              services of Zaperon. By the continued accessing of Zaperon’s
              services and/ or accessing the Informational Channels, you
              implicitly agree to be bound by the revised terms and conditions,
              as they may be.
            </ListItem>
            <ListItem
              sx={{
                display: "list-item",
                font: {
                  lg: "normal normal normal 16px/25px Poppins",
                  sm: "normal normal normal 16px/25px Poppins",
                  md: "normal normal normal 16px/25px Poppins",
                  xs: "normal normal normal 12px/18px Poppins",
                },
                letterSpacing: "0px",
                color: "#0B3558",
              }}
            >
              You, a user/ visitor, expressly agree to be bound by this
              Agreement whether it is only for accessing the Informational
              Channels or whether it is as a user of the Zaperon products. By
              accessing the Informational Channels or downloading the
              application, you, the user/ visitor, prove your acceptance of this
              Agreement, which implies that you are of legal contracting age and
              no argument to the contrary, will invalidate this agreement.
            </ListItem>
            <ListItem
              sx={{
                display: "list-item",
                font: "normal normal normal 16px/25px Poppins",
                letterSpacing: "0px",
                color: "#0B3558",
              }}
            >
              If you are representing a certain individual/s, company/companies,
              third party or any entity/entities, whether registered/
              unregistered, in any capacity, then you as its representative,
              confirm that you have the valid authority and the right to do so
              for and on behalf of them. You also represent that you have the
              right to bind such third-party legal entity to this Agreement and
              give permission to Zaperon to treat said entity as the principal
              user/ visitor of Zaperon’s services and/ or Informational
              Channels, rendering such a third-party legal entity bound by the
              terms of this Agreement.
            </ListItem>
            <ListItem
              sx={{
                display: "list-item",
                font: {
                  lg: "normal normal normal 16px/25px Poppins",
                  sm: "normal normal normal 16px/25px Poppins",
                  md: "normal normal normal 16px/25px Poppins",
                  xs: "normal normal normal 12px/18px Poppins",
                },
                letterSpacing: "0px",
                color: "#0B3558",
              }}
            >
              If you, a user, are not agreeable to the terms and conditions, it
              is requested that you not download the application or access the
              Informational Channels or avail any services of Zaperon.
            </ListItem>
          </List>
        </Typography>
      </Box>
      <Box marginTop="48px">
        <Typography
          sx={{
            textAlign: "left",
            font: {
              lg: "normal normal 600 32px/48px Poppins",
              sm: "normal normal 600 24px/35px Poppins",
              md: "normal normal 600 32px/48px Poppins",
              xs: "normal normal 600 16px/25px Poppins",
            },
            letterSpacing: "0px",
            color: "#0B3558",
            opacity: "1",
            marginBottom: { lg: "-30px", xs: "16px" },
            paddingLeft: { lg: "111px", md: "111px", sm: "32px", xs: "20px" },
            paddingRight: { lg: "111px", md: "111px", sm: "32px", xs: "20px" },
          }}
        >
          2. Definitions:
        </Typography>
        <Typography
          sx={{
            textAlign: "left",
            alignItems: "left",
            font: {
              lg: "normal normal normal 16px/25px Poppins",
              sm: "normal normal normal 16px/25px Poppins",
              md: "normal normal normal 16px/25px Poppins",
              xs: "normal normal normal 12px/18px Poppins",
            },
            letterSpacing: "0px",
            color: "#0B3558",
            marginTop: { lg: "48px" },
            paddingLeft: { lg: "111px", md: "111px", sm: "32px", xs: "20px" },
            paddingRight: { lg: "111px", md: "111px", sm: "32px", xs: "20px" },
          }}
        >
          {" "}
          <List sx={{ listStyleType: "disc", pl: 7 }}>
            <ListItem
              sx={{
                display: "list-item",
                font: {
                  lg: "normal normal normal 16px/25px Poppins",
                  sm: "normal normal normal 16px/25px Poppins",
                  md: "normal normal normal 16px/25px Poppins",
                  xs: "normal normal normal 12px/18px Poppins",
                },
                letterSpacing: "0px",
                color: "#0B3558",
                //   paddingLeft: { lg: "111px", md: "111px", sm: "111px", xs: "20px" },
                // paddingRight: { lg: "111px", md: "111px", sm: "111px", xs: "20px" },
              }}
            >
              ‘User’ or ‘visitor’ or ‘you’ means and includes any person who
              accesses, avails, or uses the services of ‘Zaperon’ including the
              website, desktop application, mobile phone application, web plugin
              ordered from directly from the Company or an authorized
              distributor or partner or accesses the Informational Channels;
            </ListItem>
            <ListItem
              sx={{
                display: "list-item",
                font: {
                  lg: "normal normal normal 16px/25px Poppins",
                  sm: "normal normal normal 16px/25px Poppins",
                  md: "normal normal normal 16px/25px Poppins",
                  xs: "normal normal normal 12px/18px Poppins",
                },
                letterSpacing: "0px",
                color: "#0B3558",
              }}
            >
              ‘Informational Channels’ includes Zaperon’s website
              (‘www.zaperon.com’ or any such address in the future) and all the
              content therein or social media pages on websites such as
              Facebook, Instagram, Linkedin, Twitter et. al., which shall be
              used by Zaperon to share information regarding its Services. It is
              clarified that this is not an exhaustive list.
            </ListItem>
            <ListItem
              sx={{
                display: "list-item",
                font: {
                  lg: "normal normal normal 16px/25px Poppins",
                  sm: "normal normal normal 16px/25px Poppins",
                  md: "normal normal normal 16px/25px Poppins",
                  xs: "normal normal normal 12px/18px Poppins",
                },
                letterSpacing: "0px",
                color: "#0B3558",
              }}
            >
              ‘Products’ means, collectively, all Zaperon SaaS, Software and all
              Support Programs, including all Upgrades and includes the right to
              use the Zaperon products as well as any other services provided by
              Zaperon, as defined in the ordering document (collectively, the
              “services”).
            </ListItem>
            <ListItem
              sx={{
                display: "list-item",
                font: "normal normal normal 16px/25px Poppins",
                letterSpacing: "0px",
                color: "#0B3558",
              }}
            >
              ‘Program Documentation’ refers to the program user manual as well
              as any other materials provided by Zaperon as part of the product
              stack.
            </ListItem>
            <ListItem
              sx={{
                display: "list-item",
                font: {
                  lg: "normal normal normal 16px/25px Poppins",
                  sm: "normal normal normal 16px/25px Poppins",
                  md: "normal normal normal 16px/25px Poppins",
                  xs: "normal normal normal 12px/18px Poppins",
                },
                letterSpacing: "0px",
                color: "#0B3558",
              }}
            >
              The term “users” shall mean those individuals authorized by you or
              on your behalf to use the services, as defined in the ordering
              document. The term “your data” refers to the data provided by you
              that resides in your services environment.
            </ListItem>
          </List>
        </Typography>
      </Box>
      <Box
        marginTop="48px"
      // paddingLeft={{ lg: "111px" }}
      // paddingRight={{ lg: "111px" }}
      >
        <Typography
          sx={{
            textAlign: "left",
            font: {
              lg: "normal normal 600 32px/48px Poppins",
              sm: "normal normal 600 24px/35px Poppins",
              md: "normal normal 600 32px/48px Poppins",
              xs: "normal normal 600 16px/25px Poppins",
            },
            letterSpacing: "0px",
            color: "#0B3558",
            opacity: "1",
            marginBottom: { lg: "-30px", xs: "16px" },
            paddingLeft: { lg: "111px", md: "111px", sm: "32px", xs: "20px" },
            paddingRight: { lg: "111px", md: "111px", sm: "32px", xs: "20px" },
          }}
        >
          3. Applicability of Agreement:
        </Typography>
        <Typography
          sx={{
            textAlign: "left",
            alignItems: "left",
            font: {
              lg: "normal normal normal 16px/25px Poppins",
              sm: "normal normal normal 16px/25px Poppins",
              md: "normal normal normal 16px/25px Poppins",
              xs: "normal normal normal 12px/18px Poppins",
            },
            letterSpacing: "0px",
            color: "#0B3558",
            marginTop: "48px",
            paddingLeft: { lg: "111px", md: "111px", sm: "32px", xs: "20px" },
            paddingRight: { lg: "111px", md: "111px", sm: "32px", xs: "20px" },
          }}
        >
          This agreement is valid for the ordering document which this agreement
          accompanies.
        </Typography>
      </Box>
      <Box
        marginTop="48px"
      // paddingLeft={{ lg: "111px" }}
      // paddingRight={{ lg: "111px" }}
      >
        <Typography
          sx={{
            textAlign: "left",
            font: {
              lg: "normal normal 600 32px/48px Poppins",
              sm: "normal normal 600 24px/35px Poppins",
              md: "normal normal 600 32px/48px Poppins",
              xs: "normal normal 600 16px/25px Poppins",
            },
            letterSpacing: "0px",
            color: "#0B3558",
            opacity: "1",
            marginBottom: { lg: "-30px", xs: "-26px" },
            // marginBottom: { lg: "-30px" },
            paddingLeft: { lg: "111px", md: "111px", sm: "32px", xs: "20px" },
            paddingRight: { lg: "111px", md: "111px", sm: "32px", xs: "20px" },
          }}
        >
          4. Rights Granted
        </Typography>
        <Typography
          sx={{
            textAlign: "left",
            alignItems: "left",
            font: {
              lg: "normal normal normal 16px/25px Poppins",
              sm: "normal normal normal 16px/25px Poppins",
              md: "normal normal normal 16px/25px Poppins",
              xs: "normal normal normal 12px/18px Poppins",
            },
            letterSpacing: "0px",
            color: "#0B3558",
            marginTop: "48px",
            paddingLeft: { lg: "111px", md: "111px", sm: "32px", xs: "20px" },
            paddingRight: { lg: "111px", md: "111px", sm: "32px", xs: "20px" },
          }}
        >
          Upon Zaperon’s acceptance of your order and for the duration of the
          services term defined in the ordering document, you have the
          non-exclusive, non-assignable, royalty free, worldwide limited right
          to use the services solely for your internal business operations and
          subject to the terms of the agreement. You may allow your users to use
          the services for this purpose and you are responsible for your users’
          compliance with the agreement. The services are provided as described
          in, and subject to, the services policies referenced in the ordering
          document. You acknowledge that Zaperon has no delivery obligation and
          will not ship copies of the Zaperon products to you as part of the
          services. You agree that you do not acquire under the agreement any
          authority to use the Zaperon programs specified in the ordering
          document in excess of the scope and/or duration of the services. Upon
          the end of the agreement or the services thereunder, your right to use
          the Zaperon programs specified in the ordering document and the
          services shall terminate.
        </Typography>
      </Box>
      <Box
        marginTop="48px"
      // paddingLeft={{ lg: "111px" }}
      // paddingRight={{ lg: "111px" }}
      >
        <Typography
          sx={{
            textAlign: "left",
            font: {
              lg: "normal normal 600 32px/48px Poppins",
              sm: "normal normal 600 24px/35px Poppins",
              md: "normal normal 600 32px/48px Poppins",
              xs: "normal normal 600 16px/25px Poppins",
            },
            letterSpacing: "0px",
            color: "#0B3558",
            opacity: "1",
            marginBottom: { lg: "-30px", xs: "-25px" },
            paddingLeft: { lg: "111px", md: "111px", sm: "32px", xs: "20px" },
            paddingRight: { lg: "111px", md: "111px", sm: "32px", xs: "20px" },
          }}
        >
          5. Ownership and Restrictions
        </Typography>
        <Typography
          sx={{
            textAlign: "left",
            alignItems: "left",
            font: {
              lg: "normal normal normal 16px/25px Poppins",
              sm: "normal normal normal 16px/25px Poppins",
              md: "normal normal normal 16px/25px Poppins",
              xs: "normal normal normal 12px/18px Poppins",
            },
            letterSpacing: "0px",
            color: "#0B3558",
            marginTop: "48px",
            paddingLeft: { lg: "111px", md: "111px", sm: "32px", xs: "20px" },
            paddingRight: { lg: "111px", md: "111px", sm: "32px", xs: "20px" },
          }}
        >
          You retain all ownership and intellectual property rights in and to
          your data. Zaperon or its licensors retain all ownership and
          intellectual property rights to the services and Zaperon programs.
          Zaperon retains all ownership and intellectual property rights to
          anything developed and delivered under the agreement. Third party
          technology that may be appropriate or necessary for use with some
          Zaperon programs is specified in the program documentation or ordering
          document as applicable. Your right to use such third party technology
          is governed by the terms of the third party technology license
          agreement specified by Zaperon and not under the agreement. You may
          not:
        </Typography>
        <List
          sx={{
            listStyleType: "disc",
            pl: 7,
            paddingLeft: { lg: "150px", md: "111px", sm: "32px", xs: "20px" },
            paddingRight: { lg: "111px", md: "111px", sm: "32px", xs: "20px" },
          }}
        >
          <ListItem
            sx={{
              display: "list-item",
              font: {
                lg: "normal normal normal 16px/25px Poppins",
                sm: "normal normal normal 16px/25px Poppins",
                md: "normal normal normal 16px/25px Poppins",
                xs: "normal normal normal 12px/18px Poppins",
              },
              letterSpacing: "0px",
              color: "#0B3558",
              //   paddingLeft: { lg: "111px", md: "111px", sm: "111px", xs: "20px" },
              // paddingRight: { lg: "111px", md: "111px", sm: "111px", xs: "20px" },
            }}
          >
            remove or modify any program markings or any notice of Zaperon’s or
            its licensors’ proprietary rights;
          </ListItem>
          <ListItem
            sx={{
              display: "list-item",
              font: {
                lg: "normal normal normal 16px/25px Poppins",
                sm: "normal normal normal 16px/25px Poppins",
                md: "normal normal normal 16px/25px Poppins",
                xs: "normal normal normal 12px/18px Poppins",
              },
              letterSpacing: "0px",
              color: "#0B3558",
            }}
          >
            make the programs or materials resulting from the services available
            in any manner to any third party for use in the third party’s
            business operations (unless such access is expressly permitted for
            the specific program license or materials from the services you have
            acquired);
          </ListItem>
          <ListItem
            sx={{
              display: "list-item",
              font: {
                lg: "normal normal normal 16px/25px Poppins",
                sm: "normal normal normal 16px/25px Poppins",
                md: "normal normal normal 16px/25px Poppins",
                xs: "normal normal normal 12px/18px Poppins",
              },
              letterSpacing: "0px",
              color: "#0B3558",
            }}
          >
            modify, make derivative works of, disassemble, reverse compile, or
            reverse engineer any part of the services (the foregoing prohibition
            includes but is not limited to review of data structures or similar
            materials produced by programs), or access or use the services in
            order to build or support, and/or assist a third party in building
            or supporting, products or services competitive to Zaperon ;
          </ListItem>
          <ListItem
            sx={{
              display: "list-item",
              font: "normal normal normal 16px/25px Poppins",
              letterSpacing: "0px",
              color: "#0B3558",
            }}
          >
            disclose results of any services or program benchmark tests without
            Zaperon’s prior written consent; and
          </ListItem>
          <ListItem
            sx={{
              display: "list-item",
              font: {
                lg: "normal normal normal 16px/25px Poppins",
                sm: "normal normal normal 16px/25px Poppins",
                md: "normal normal normal 16px/25px Poppins",
                xs: "normal normal normal 12px/18px Poppins",
              },
              letterSpacing: "0px",
              color: "#0B3558",
            }}
          >
            license, sell, rent, lease, transfer, assign, distribute, display,
            host, outsource, disclose, permit timesharing or service bureau use,
            or otherwise commercially exploit or make the services, Zaperon
            programs or materials available, to any third party other than, as
            expressly permitted under the terms of the agreement.
          </ListItem>
        </List>
      </Box>
      <Box
        marginTop="48px"
      // paddingLeft={{ lg: "111px" }}
      // paddingRight={{ lg: "111px" }}
      >
        <Typography
          sx={{
            textAlign: "left",
            alignItems: "left",
            font: {
              lg: "normal normal normal 16px/25px Poppins",
              sm: "normal normal normal 16px/25px Poppins",
              md: "normal normal normal 16px/25px Poppins",
              xs: "normal normal normal 12px/18px Poppins",
            },
            letterSpacing: "0px",
            color: "#0B3558",
            marginTop: "-38px",
            paddingLeft: { lg: "111px", md: "111px", sm: "32px", xs: "20px" },
            paddingRight: { lg: "111px", md: "111px", sm: "32px", xs: "20px" },
          }}
        >
          The rights granted to you under the agreement are also conditioned on
          the following:
        </Typography>
        <List
          sx={{
            listStyleType: "disc",
            pl: 7,
            paddingLeft: { lg: "150px", md: "111px", sm: "32px", xs: "20px" },
            paddingRight: { lg: "111px", md: "111px", sm: "32px", xs: "20px" },
          }}
        >
          <ListItem
            sx={{
              display: "list-item",
              font: {
                lg: "normal normal normal 16px/25px Poppins",
                sm: "normal normal normal 16px/25px Poppins",
                md: "normal normal normal 16px/25px Poppins",
                xs: "normal normal normal 12px/18px Poppins",
              },
              letterSpacing: "0px",
              color: "#0B3558",
              //   paddingLeft: { lg: "111px", md: "111px", sm: "111px", xs: "20px" },
              // paddingRight: { lg: "111px", md: "111px", sm: "111px", xs: "20px" },
            }}
          >
            the rights of any user for usage of the services (e.g., on a “named
            user” basis) cannot be shared or used by more than one individual
            (unless such user is reassigned in its entirety to another
            authorized user, in which case the prior authorized user shall no
            longer have any right to use the product);
          </ListItem>
          <ListItem
            sx={{
              display: "list-item",
              font: {
                lg: "normal normal normal 16px/25px Poppins",
                sm: "normal normal normal 16px/25px Poppins",
                md: "normal normal normal 16px/25px Poppins",
                xs: "normal normal normal 12px/18px Poppins",
              },
              letterSpacing: "0px",
              color: "#0B3558",
            }}
          >
            except as expressly provided herein, no part of the services may be
            copied, reproduced, distributed, republished, downloaded, displayed,
            posted or transmitted in any form or by any means, including but not
            limited to electronic, mechanical, photocopying, recording, or other
            means; and
          </ListItem>
          <ListItem
            sx={{
              display: "list-item",
              font: {
                lg: "normal normal normal 16px/25px Poppins",
                sm: "normal normal normal 16px/25px Poppins",
                md: "normal normal normal 16px/25px Poppins",
                xs: "normal normal normal 12px/18px Poppins",
              },
              letterSpacing: "0px",
              color: "#0B3558",
            }}
          >
            you agree to make every reasonable effort to prevent unauthorized
            third parties from accessing the services.
          </ListItem>
        </List>
        <Typography
          sx={{
            textAlign: "left",
            alignItems: "left",
            font: {
              lg: "normal normal normal 16px/25px Poppins",
              sm: "normal normal normal 16px/25px Poppins",
              md: "normal normal normal 16px/25px Poppins",
              xs: "normal normal normal 12px/18px Poppins",
            },
            letterSpacing: "0px",
            color: "#0B3558",
            // marginTop: "-38px",
            paddingLeft: { lg: "111px", md: "111px", sm: "32px", xs: "20px" },
            paddingRight: { lg: "111px", md: "111px", sm: "32px", xs: "20px" },
          }}
        >
          Included Third Party Software
        </Typography>
        <Typography
          sx={{
            textAlign: "left",
            alignItems: "left",
            font: {
              lg: "normal normal normal 16px/25px Poppins",
              sm: "normal normal normal 16px/25px Poppins",
              md: "normal normal normal 16px/25px Poppins",
              xs: "normal normal normal 12px/18px Poppins",
            },
            letterSpacing: "0px",
            color: "#0B3558",
            marginTop: "18px",
            paddingLeft: { lg: "161px", md: "111px", sm: "32px", xs: "20px" },
            paddingRight: { lg: "111px", md: "111px", sm: "32px", xs: "20px" },
          }}
        >
          Zaperon may use third-party software in the development and operation
          of Zaperon Products. Use of this software is governed by the
          applicable license agreements from each of the software vendors. Any
          Software embedded in the Zaperon product and to the extent portions of
          the Software are distributed under and subject to open source licenses
          obligating Zaperon to make the source code for such portions publicly
          available, Zaperon will make such source code portions (including
          Zaperon modifications, as appropriate) available upon request.
        </Typography>
      </Box>
      <Box
        marginTop="48px"
      // paddingLeft={{ lg: "111px" }}
      // paddingRight={{ lg: "111px" }}
      >
        <Typography
          sx={{
            textAlign: "left",
            font: {
              lg: "normal normal 600 32px/48px Poppins",
              sm: "normal normal 600 24px/35px Poppins",
              md: "normal normal 600 32px/48px Poppins",
              xs: "normal normal 600 16px/25px Poppins",
            },
            letterSpacing: "0px",
            color: "#0B3558",
            opacity: "1",
            marginBottom: { lg: "-30px", xs: "-25px" },
            paddingLeft: { lg: "111px", md: "111px", sm: "32px", xs: "20px" },
            paddingRight: { lg: "111px", md: "111px", sm: "32px", xs: "20px" },
          }}
        >
          6. Warranties, Disclaimers and Exclusive Remedies:
        </Typography>
        <Typography
          sx={{
            textAlign: "left",
            alignItems: "left",
            font: {
              lg: "normal normal normal 16px/25px Poppins",
              sm: "normal normal normal 16px/25px Poppins",
              md: "normal normal normal 16px/25px Poppins",
              xs: "normal normal normal 12px/18px Poppins",
            },
            letterSpacing: "0px",
            color: "#0B3558",
            marginTop: "48px",
            paddingLeft: { lg: "111px", md: "111px", sm: "32px", xs: "20px" },
            paddingRight: { lg: "111px", md: "111px", sm: "32px", xs: "20px" },
          }}
        >
          Zaperon warrants that the services will perform in all material
          respects in accordance with the services policies referenced in the
          ordering document. If the services provided to you for any given month
          during the services term were not performed as warranted, you must
          provide written notice to Zaperon as specified in the ordering
          document no later than five business days after the last day of that
          particular month or within such other period stated in the ordering
          document. ZAPERON DOES NOT GUARANTEE THAT THE SERVICES WILL BE
          PERFORMED ERROR-FREE OR UNINTERRUPTED, OR THAT ZAPERON WILL CORRECT
          ALL SERVICES ERRORS. YOU ACKNOWLEDGE THAT ZAPERON DOES NOT CONTROL THE
          TRANSFER OF DATA OVER COMMUNICATIONS FACILITIES, INCLUDING THE
          INTERNET, AND THAT THE SERVICE MAY BE SUBJECT TO LIMITATIONS, DELAYS,
          AND OTHER PROBLEMS INHERENT IN THE USE OF SUCH COMMUNICATIONS
          FACILITIES. ZAPERON IS NOT RESPONSIBLE FOR ANY DELAYS, DELIVERY
          FAILURES, OR OTHER DAMAGE RESULTING FROM SUCH PROBLEMS.
        </Typography>
      </Box>
      <Box
        marginTop="48px"
      // paddingLeft={{ lg: "111px" }}
      // paddingRight={{ lg: "111px" }}
      >
        <Typography
          sx={{
            textAlign: "left",
            font: {
              lg: "normal normal 600 32px/48px Poppins",
              sm: "normal normal 600 24px/35px Poppins",
              md: "normal normal 600 32px/48px Poppins",
              xs: "normal normal 600 16px/25px Poppins",
            },
            letterSpacing: "0px",
            color: "#0B3558",
            opacity: "1",
            marginBottom: { lg: "-30px", xs: "-25px" },
            paddingLeft: { lg: "111px", md: "111px", sm: "32px", xs: "20px" },
            paddingRight: { lg: "111px", md: "111px", sm: "32px", xs: "20px" },
          }}
        >
          7. Trial Use of the Services:
        </Typography>
        <Typography
          sx={{
            textAlign: "left",
            alignItems: "left",
            font: {
              lg: "normal normal normal 16px/25px Poppins",
              sm: "normal normal normal 16px/25px Poppins",
              md: "normal normal normal 16px/25px Poppins",
              xs: "normal normal normal 12px/18px Poppins",
            },
            letterSpacing: "0px",
            color: "#0B3558",
            marginTop: "48px",
            paddingLeft: { lg: "111px", md: "111px", sm: "32px", xs: "20px" },
            paddingRight: { lg: "111px", md: "111px", sm: "32px", xs: "20px" },
          }}
        >
          If specified in the ordering document, you may order certain services
          for trial, nonproduction purposes subject to the terms and conditions
          of the agreement. Services acquired for trial purposes are provided
          “as is” and Zaperon does not offer any warranties for such services.
        </Typography>
      </Box>
      <Box
        marginTop="48px"
      // paddingLeft={{ lg: "111px" }}
      // paddingRight={{ lg: "111px" }}
      >
        <Typography
          sx={{
            textAlign: "left",
            font: {
              lg: "normal normal 600 32px/48px Poppins",
              sm: "normal normal 600 24px/35px Poppins",
              md: "normal normal 600 32px/48px Poppins",
              xs: "normal normal 600 16px/25px Poppins",
            },
            letterSpacing: "0px",
            color: "#0B3558",
            opacity: "1",
            marginBottom: { lg: "-30px", xs: "-25px" },
            paddingLeft: { lg: "111px", md: "111px", sm: "32px", xs: "20px" },
            paddingRight: { lg: "111px", md: "111px", sm: "32px", xs: "20px" },
          }}
        >
          8. Indemnification:
        </Typography>

        <Typography
          sx={{
            textAlign: "left",
            alignItems: "left",
            font: {
              lg: "normal normal normal 16px/25px Poppins",
              sm: "normal normal normal 16px/25px Poppins",
              md: "normal normal normal 16px/25px Poppins",
              xs: "normal normal normal 12px/18px Poppins",
            },
            letterSpacing: "0px",
            color: "#0B3558",
            marginTop: "48px",
            paddingLeft: { lg: "111px", md: "111px", sm: "32px", xs: "20px" },
            paddingRight: { lg: "111px", md: "111px", sm: "32px", xs: "20px" },
          }}
        >
          If a third party makes a claim against either you or Zaperon
          (“Recipient” which may refer to you or Zaperon depending upon which
          party received the Material), that any information, design,
          specification, instruction, software, service, data, or material
          (“Material”) furnished by either you or Zaperon (“Provider” which may
          refer to you or Zaperon depending on which party provided the
          Material), and used by the Recipient infringes its intellectual
          property rights, the Provider, at its sole cost and expense, will
          defend the Recipient against the claim and indemnify the Recipient
          from the damages, liabilities, costs and expenses awarded by the court
          to the third party claiming infringement or the settlement agreed to
          by the Provider, if the Recipient does the following:
        </Typography>
        <List
          sx={{
            listStyleType: "disc",
            pl: 7,
            paddingLeft: { lg: "150px", md: "111px", sm: "32px", xs: "20px" },
            paddingRight: { lg: "111px", md: "111px", sm: "32px", xs: "20px" },
          }}
        >
          <ListItem
            sx={{
              display: "list-item",
              font: {
                lg: "normal normal normal 16px/25px Poppins",
                sm: "normal normal normal 16px/25px Poppins",
                md: "normal normal normal 16px/25px Poppins",
                xs: "normal normal normal 12px/18px Poppins",
              },
              letterSpacing: "0px",
              color: "#0B3558",
              //   paddingLeft: { lg: "111px", md: "111px", sm: "111px", xs: "20px" },
              // paddingRight: { lg: "111px", md: "111px", sm: "111px", xs: "20px" },
            }}
          >
            notifies the Provider promptly in writing, not later than 30 days
            after the Recipient receives notice of the claim (or sooner if
            required by applicable law);
          </ListItem>
          <ListItem
            sx={{
              display: "list-item",
              font: {
                lg: "normal normal normal 16px/25px Poppins",
                sm: "normal normal normal 16px/25px Poppins",
                md: "normal normal normal 16px/25px Poppins",
                xs: "normal normal normal 12px/18px Poppins",
              },
              letterSpacing: "0px",
              color: "#0B3558",
            }}
          >
            gives the Provider sole control of the defense and any settlement
            negotiations; and
          </ListItem>
          <ListItem
            sx={{
              display: "list-item",
              font: {
                lg: "normal normal normal 16px/25px Poppins",
                sm: "normal normal normal 16px/25px Poppins",
                md: "normal normal normal 16px/25px Poppins",
                xs: "normal normal normal 12px/18px Poppins",
              },
              letterSpacing: "0px",
              color: "#0B3558",
            }}
          >
            gives the Provider the information, authority, and assistance the
            Provider needs to defend against or settle the claim.
          </ListItem>
        </List>
        <Typography
          sx={{
            textAlign: "left",
            alignItems: "left",
            font: {
              lg: "normal normal normal 16px/25px Poppins",
              sm: "normal normal normal 16px/25px Poppins",
              md: "normal normal normal 16px/25px Poppins",
              xs: "normal normal normal 12px/18px Poppins",
            },
            letterSpacing: "0px",
            color: "#0B3558",
            // marginTop: "-8px",
            paddingLeft: { lg: "111px", md: "111px", sm: "32px", xs: "20px" },
            paddingRight: { lg: "111px", md: "111px", sm: "32px", xs: "20px" },
          }}
        >
          If the Provider believes or it is determined that any of the Material
          may have violated a third party’s intellectual property rights, the
          Provider may choose to either modify the Material to be non-infringing
          (while substantially preserving its utility or functionality) or
          obtain a license to allow for continued use, or if these alternatives
          are not commercially reasonable, the Provider may end the license for,
          and require return of, the applicable Material and refund any unused,
          prepaid fees the Recipient may have paid to the other party for such
          Material. If such return materially affects Zaperon’s ability to meet
          its obligations under the relevant order, then Zaperon may, at its
          option and upon 30 days prior written notice, terminate the order. The
          Provider will not indemnify the Recipient if the Recipient alters the
          Material or uses it outside the scope of use identified in the
          Provider’s user documentation or services policies or if the Recipient
          uses a version of the Materials which has been superseded, if the
          infringement claim could have been avoided by using an unaltered
          current version of the Material which was provided to the Recipient.
          The Provider will not indemnify the Recipient to the extent that an
          infringement claim is based upon (i) any information, design,
          specification, instruction, software, data, or material not furnished
          by the Provider, or (ii) any Material from a third party portal or
          other external source that is accessible to you within or from the
          service (e.g., a third party Web page accessed via a hyperlink).
          Zaperon will not indemnify you to the extent that an infringement
          claim is based upon the combination of any Material with any products
          or services not provided by Zaperon . Zaperon will not indemnify you
          for infringement caused by your actions against any third party if the
          services as delivered to you and used in accordance with the terms of
          the agreement would not otherwise infringe any third party
          intellectual property rights. Zaperon will not indemnify you for any
          infringement claim that is based on: (1) a patent that you were made
          aware of prior to the effective date of the agreement (pursuant to a
          claim, demand, or notice); or (2) your actions prior to the effective
          date of the agreement. This section provides the parties’ exclusive
          remedy for any infringement claims or damages.
        </Typography>
      </Box>
      <Box
        marginTop="48px"
      // paddingLeft={{ lg: "111px" }}
      // paddingRight={{ lg: "111px" }}
      >
        <Typography
          sx={{
            textAlign: "left",
            font: {
              lg: "normal normal 600 32px/48px Poppins",
              sm: "normal normal 600 24px/35px Poppins",
              md: "normal normal 600 32px/48px Poppins",
              xs: "normal normal 600 16px/25px Poppins",
            },
            letterSpacing: "0px",
            color: "#0B3558",
            opacity: "1",
            marginBottom: { lg: "-30px", xs: "-25px" },
            paddingLeft: { lg: "111px", md: "111px", sm: "32px", xs: "20px" },
            paddingRight: { lg: "111px", md: "111px", sm: "32px", xs: "20px" },
          }}
        >
          9. Support Services:
        </Typography>
        <Typography
          sx={{
            textAlign: "left",
            alignItems: "left",
            font: "normal normal normal 16px/25px Poppins",
            font: {
              lg: "normal normal normal 16px/25px Poppins",
              sm: "normal normal normal 16px/25px Poppins",
              md: "normal normal normal 16px/25px Poppins",
              xs: "normal normal normal 12px/18px Poppins",
            },
            letterSpacing: "0px",
            color: "#0B3558",
            marginTop: "48px",
            paddingLeft: { lg: "111px", md: "111px", sm: "32px", xs: "20px" },
            paddingRight: { lg: "111px", md: "111px", sm: "32px", xs: "20px" },
          }}
        >
          Support services provided under the agreement are specified in the
          services policies referenced in the ordering document.
        </Typography>
      </Box>
      <Box
        marginTop="48px"
      // paddingLeft={{ lg: "111px" }}
      // paddingRight={{ lg: "111px" }}
      >
        <Typography
          sx={{
            textAlign: "left",
            font: {
              lg: "normal normal 600 32px/48px Poppins",
              sm: "normal normal 600 24px/35px Poppins",
              md: "normal normal 600 32px/48px Poppins",
              xs: "normal normal 600 16px/25px Poppins",
            },
            letterSpacing: "0px",
            color: "#0B3558",
            opacity: "1",
            marginBottom: { lg: "-30px", xs: "-25px" },
            paddingLeft: { lg: "111px", md: "111px", sm: "32px", xs: "20px" },
            paddingRight: { lg: "111px", md: "111px", sm: "32px", xs: "20px" },
          }}
        >
          10. End of Agreement:
        </Typography>
        <Typography
          sx={{
            textAlign: "left",
            alignItems: "left",
            font: {
              lg: "normal normal normal 16px/25px Poppins",
              sm: "normal normal normal 16px/25px Poppins",
              md: "normal normal normal 16px/25px Poppins",
              xs: "normal normal normal 12px/18px Poppins",
            },
            letterSpacing: "0px",
            color: "#0B3558",
            marginTop: "48px",
            marginBottom: "48px",
            paddingLeft: { lg: "111px", md: "111px", sm: "32px", xs: "20px" },
            paddingRight: { lg: "111px", md: "111px", sm: "32px", xs: "20px" },
          }}
        >
          Services provided under this agreement shall be provided for the
          period defined in the ordering document unless earlier terminated in
          accordance with the agreement. The term of the services and any
          renewal years are collectively defined as the “services term.” At the
          end of the services term, all rights to use the services, including
          all Zaperon products listed in the ordering document, shall end. If
          either of us breaches a material term of the agreement and fails to
          correct the breach within 30 days of written specification of the
          breach, then the breaching party is in default and the non-breaching
          party may terminate the applicable ordering document under which the
          breach occurred. If Zaperon ends the ordering document as specified in
          the preceding sentence, you must pay within 30 days all amounts which
          have accrued prior to such end, as well as all sums remaining unpaid
          for the services ordered under the agreement plus related taxes and
          expenses. If Zaperon ends the services under the Indemnification
          section, you must pay within 30 days all amounts remaining unpaid for
          services plus related taxes and expenses. The non-breaching party may
          agree in its sole discretion to extend the 30 day period for so long
          as the breaching party continues reasonable efforts to cure the
          breach. You agree that if you are in default under the agreement, you
          may not use the services ordered. You further agree that if you have
          used an Zaperon Financing Division contract to pay for the fees due
          under an order and you are in default under that contract, you may not
          use the services that are subject to such contract. In addition,
          Zaperon may immediately suspend your password, account, and use of the
          services (i) if you fail to pay Zaperon as required under the
          agreement and do not cure within the first ten days of the 30 day cure
          period, or (ii) if you violate any provisions of the Products
          agreement. Zaperon may terminate the services hereunder if any of the
          foregoing is not cured within 30 days after Zaperon’s initial notice
          thereof. Any suspension by Zaperon of the services under this
          paragraph shall not excuse you from your obligation to make payment(s)
          under the agreement. At your request, and for a period of up to 60
          days after the termination of the applicable ordering document,
          Zaperon may permit you to use the services solely to the extent
          necessary for you to retrieve a file of your data then in the services
          environment. You agree and acknowledge that Zaperon has no obligation
          to retain your data and that your data may be irretrievably deleted
          after 60 days following the termination of the ordering document.
          Provisions that survive termination or expiration of the agreement are
          those relating to limitation of liability, infringement indemnity,
          payment, and others which by their nature are intended to survive.
        </Typography>
      </Box>
      <Box
        marginTop="48px"
      // paddingLeft={{ lg: "111px" }}
      // paddingRight={{ lg: "111px" }}
      >
        <Typography
          sx={{
            textAlign: "left",
            font: {
              lg: "normal normal 600 32px/48px Poppins",
              sm: "normal normal 600 24px/35px Poppins",
              md: "normal normal 600 32px/48px Poppins",
              xs: "normal normal 600 16px/25px Poppins",
            },
            letterSpacing: "0px",
            color: "#0B3558",
            opacity: "1",
            marginBottom: { lg: "-30px", xs: "-25px" },
            paddingLeft: { lg: "111px", md: "111px", sm: "32px", xs: "20px" },
            paddingRight: { lg: "111px", md: "111px", sm: "32px", xs: "20px" },
          }}
        >
          11. Fees and Taxes:
        </Typography>
        <Typography
          sx={{
            textAlign: "left",
            alignItems: "left",
            font: {
              lg: "normal normal normal 16px/25px Poppins",
              sm: "normal normal normal 16px/25px Poppins",
              md: "normal normal normal 16px/25px Poppins",
              xs: "normal normal normal 12px/18px Poppins",
            },
            letterSpacing: "0px",
            color: "#0B3558",
            marginTop: "48px",
            marginBottom: "48px",
            paddingLeft: { lg: "111px", md: "111px", sm: "32px", xs: "20px" },
            paddingRight: { lg: "111px", md: "111px", sm: "32px", xs: "20px" },
          }}
        >
          You agree to pay for all products ordered as set forth in the
          applicable ordering document. All fees due under the agreement are
          non-cancellable and the sums paid non-refundable. You agree to pay any
          sales, value-added or other similar taxes imposed by applicable law
          that Zaperon must pay based on the services you ordered, except for
          taxes based on Zaperon’s income. You will reimburse Zaperon for
          reasonable expenses related to providing any on-site portion of the
          services. Fees for services listed in an ordering document are
          exclusive of taxes and expenses. All amounts invoiced hereunder are
          due and payable within 30 days of the date of the invoice. You agree
          that you have not relied on the future availability of any services,
          programs or updates in entering into the payment obligations in the
          ordering document; however, the preceding does not relieve Zaperon of
          its obligation to deliver services that you have ordered per the terms
          of the agreement.
        </Typography>
      </Box>
      <Box
        marginTop="48px"
      // paddingLeft={{ lg: "111px" }}
      // paddingRight={{ lg: "111px" }}
      >
        <Typography
          sx={{
            textAlign: "left",
            font: {
              lg: "normal normal 600 32px/48px Poppins",
              sm: "normal normal 600 24px/35px Poppins",
              md: "normal normal 600 32px/48px Poppins",
              xs: "normal normal 600 16px/25px Poppins",
            },
            letterSpacing: "0px",
            color: "#0B3558",
            opacity: "1",
            marginBottom: { lg: "-30px", xs: "-25px" },
            paddingLeft: { lg: "111px", md: "111px", sm: "32px", xs: "20px" },
            paddingRight: { lg: "111px", md: "111px", sm: "32px", xs: "20px" },
          }}
        >
          12. Nondisclosure:
        </Typography>
        <Typography
          sx={{
            textAlign: "left",
            alignItems: "left",
            font: {
              lg: "normal normal normal 16px/25px Poppins",
              sm: "normal normal normal 16px/25px Poppins",
              md: "normal normal normal 16px/25px Poppins",
              xs: "normal normal normal 12px/18px Poppins",
            },
            letterSpacing: "0px",
            color: "#0B3558",
            marginTop: "48px",
            marginBottom: "48px",
            paddingLeft: { lg: "111px", md: "111px", sm: "32px", xs: "20px" },
            paddingRight: { lg: "111px", md: "111px", sm: "32px", xs: "20px" },
          }}
        >
          By virtue of the agreement, the parties may have access to information
          that is confidential to one another (“confidential information”). We
          each agree to disclose only information that is required for the
          performance of obligations under the agreement. Confidential
          information shall be limited to the terms and pricing under the
          agreement, your data residing in the services environment, and all
          information clearly identified as confidential at the time of
          disclosure. A party’s confidential information shall not include
          information that: (a) is or becomes a part of the public domain
          through no act or omission of the other party; (b) was in the other
          party’s lawful possession prior to the disclosure and had not been
          obtained by the other party either directly or indirectly from the
          disclosing party; (c) is lawfully disclosed to the other party by a
          third party without restriction on the disclosure; or (d) is
          independently developed by the other party. We each agree to hold each
          other’s confidential information in confidence for a period of three
          years from the date of disclosure. Also, we each agree to disclose
          confidential information only to those employees or agents who are
          required to protect it against unauthorized disclosure in a manner no
          less protective than under the agreement. Zaperon will protect the
          confidentiality of your data residing in the services environment in
          accordance with the Zaperon security practices specified in the
          services policies referenced in the ordering document. Nothing shall
          prevent either party from disclosing the terms or pricing under the
          agreement in any legal proceeding arising from or in connection with
          the agreement or from disclosing the confidential information to a
          governmental entity as required by law.
        </Typography>
      </Box>
      <Box
        marginTop="48px"
      // paddingLeft={{ lg: "111px" }}
      // paddingRight={{ lg: "111px" }}
      >
        <Typography
          sx={{
            textAlign: "left",
            font: {
              lg: "normal normal 600 32px/48px Poppins",
              sm: "normal normal 600 24px/35px Poppins",
              md: "normal normal 600 32px/48px Poppins",
              xs: "normal normal 600 16px/25px Poppins",
            },
            letterSpacing: "0px",
            color: "#0B3558",
            opacity: "1",
            marginBottom: { lg: "-30px", xs: "-25px" },
            paddingLeft: { lg: "111px", md: "111px", sm: "32px", xs: "20px" },
            paddingRight: { lg: "111px", md: "111px", sm: "32px", xs: "20px" },
          }}
        >
          13. Entire Agreement:
        </Typography>
        <Typography
          sx={{
            textAlign: "left",
            alignItems: "left",
            font: {
              lg: "normal normal normal 16px/25px Poppins",
              sm: "normal normal normal 16px/25px Poppins",
              md: "normal normal normal 16px/25px Poppins",
              xs: "normal normal normal 12px/18px Poppins",
            },
            letterSpacing: "0px",
            color: "#0B3558",
            marginTop: "48px",
            marginBottom: "48px",
            paddingLeft: { lg: "111px", md: "111px", sm: "32px", xs: "20px" },
            paddingRight: { lg: "111px", md: "111px", sm: "32px", xs: "20px" },
          }}
        >
          You agree that the agreement (including the information which is
          incorporated into the agreement by written reference (including
          reference to information contained in a URL or referenced policy), is
          the complete agreement for the services ordered by you, and that the
          agreement supersedes all prior or contemporaneous agreements or
          representations, written or oral, regarding such services. If any term
          of the agreement is found to be invalid or unenforceable, the
          remaining provisions will remain effective and such term shall be
          replaced with a term consistent with the purpose and intent of the
          agreement. It is expressly agreed that the terms of the agreement,
          including any Zaperon ordering document, shall supersede the terms in
          any purchase order or other non-Zaperon document and no terms included
          in any such purchase order or other non-Zaperon document shall apply
          to the services ordered. The agreement may not be modified and the
          rights and restrictions may not be altered or waived except in a
          writing signed or accepted online through the Zaperon central
          administration portal by authorized representatives of you and of
          Zaperon.
        </Typography>
      </Box>
      <Box
        marginTop="48px"
      // paddingLeft={{ lg: "111px" }}
      // paddingRight={{ lg: "111px" }}
      >
        <Typography
          sx={{
            textAlign: "left",
            font: {
              lg: "normal normal 600 32px/48px Poppins",
              sm: "normal normal 600 24px/35px Poppins",
              md: "normal normal 600 32px/48px Poppins",
              xs: "normal normal 600 16px/25px Poppins",
            },
            letterSpacing: "0px",
            color: "#0B3558",
            opacity: "1",
            marginBottom: { lg: "-30px", xs: "-25px" },
            paddingLeft: { lg: "111px", md: "111px", sm: "32px", xs: "20px" },
            paddingRight: { lg: "111px", md: "111px", sm: "32px", xs: "20px" },
          }}
        >
          14. Limitation of Liability:
        </Typography>
        <Typography
          sx={{
            textAlign: "left",
            alignItems: "left",
            font: {
              lg: "normal normal normal 16px/25px Poppins",
              sm: "normal normal normal 16px/25px Poppins",
              md: "normal normal normal 16px/25px Poppins",
              xs: "normal normal normal 12px/18px Poppins",
            },
            letterSpacing: "0px",
            color: "#0B3558",
            marginTop: "48px",
            marginBottom: "48px",
            paddingLeft: { lg: "111px", md: "111px", sm: "32px", xs: "20px" },
            paddingRight: { lg: "111px", md: "111px", sm: "32px", xs: "20px" },
          }}
        >
          NEITHER PARTY SHALL BE LIABLE FOR ANY INDIRECT, INCIDENTAL, SPECIAL,
          PUNITIVE, OR CONSEQUENTIAL DAMAGES, OR ANY LOSS OF REVENUE OR PROFITS
          (EXCLUDING FEES UNDER THE AGREEMENT), DATA, OR DATA USE. ZAPERON’S
          MAXIMUM LIABILITY FOR ANY DAMAGES ARISING OUT OF OR RELATED TO THIS
          PRODUCTS AGREEMENT OR YOUR ORDER, WHETHER IN CONTRACT OR TORT, OR
          OTHERWISE, SHALL IN NO EVENT EXCEED, IN THE AGGREGATE, THE TOTAL
          AMOUNTS ACTUALLY PAID TO ZAPERON FOR THE SERVICES UNDER THE ORDER THAT
          IS THE SUBJECT OF THE CLAIM IN THE TWELVE (12) MONTH PERIOD
          IMMEDIATELY PRECEDING THE EVENT GIVING RISE TO SUCH CLAIM. ANY DAMAGE
          IN YOUR FAVOR AGAINST ZAPERON SHALL BE REDUCED BY ANY REFUND OR CREDIT
          RECEIVED BY YOU UNDER THE AGREEMENT AND ANY SUCH REFUND AND CREDIT
          SHALL APPLY TOWARDS THE LIMITATION OF LIABILITY.
        </Typography>
      </Box>
      <Box
        marginTop="48px"
      // paddingLeft={{ lg: "111px" }}
      // paddingRight={{ lg: "111px" }}
      >
        <Typography
          sx={{
            textAlign: "left",
            font: {
              lg: "normal normal 600 32px/48px Poppins",
              sm: "normal normal 600 24px/35px Poppins",
              md: "normal normal 600 32px/48px Poppins",
              xs: "normal normal 600 16px/25px Poppins",
            },
            letterSpacing: "0px",
            color: "#0B3558",
            opacity: "1",
            marginBottom: { lg: "16px", xs: "-25px" },
            paddingLeft: { lg: "111px", md: "111px", sm: "32px", xs: "20px" },
            paddingRight: { lg: "111px", md: "111px", sm: "32px", xs: "20px" },
          }}
        >
          15. Other:
        </Typography>
        <List
          sx={{
            listStyleType: "disc",
            pl: 7,
            paddingLeft: { lg: "150px", md: "111px", sm: "32px", xs: "20px" },
            paddingRight: { lg: "111px", md: "111px", sm: "32px", xs: "20px" },
          }}
        >
          <ListItem
            sx={{
              display: "list-item",
              font: {
                lg: "normal normal normal 16px/25px Poppins",
                sm: "normal normal normal 16px/25px Poppins",
                md: "normal normal normal 16px/25px Poppins",
                xs: "normal normal normal 12px/18px Poppins",
              },
              letterSpacing: "0px",
              color: "#0B3558",
              //   paddingLeft: { lg: "111px", md: "111px", sm: "111px", xs: "20px" },
              // paddingRight: { lg: "111px", md: "111px", sm: "111px", xs: "20px" },
            }}
          >
            Zaperon is an independent contractor and we agree that no
            partnership, joint venture, or agency relationship exists between
            us. We each will be responsible for paying our own employees,
            including employment related taxes and insurance.
          </ListItem>
          <ListItem
            sx={{
              display: "list-item",
              font: {
                lg: "normal normal normal 16px/25px Poppins",
                sm: "normal normal normal 16px/25px Poppins",
                md: "normal normal normal 16px/25px Poppins",
                xs: "normal normal normal 12px/18px Poppins",
              },
              letterSpacing: "0px",
              color: "#0B3558",
            }}
          >
            You shall obtain at your sole expense any rights and consents from
            third parties necessary for Zaperon and its subcontractors to
            perform the services under the agreement.{" "}
          </ListItem>
          <ListItem
            sx={{
              display: "list-item",
              font: {
                lg: "normal normal normal 16px/25px Poppins",
                sm: "normal normal normal 16px/25px Poppins",
                md: "normal normal normal 16px/25px Poppins",
                xs: "normal normal normal 12px/18px Poppins",
              },
              letterSpacing: "0px",
              color: "#0B3558",
            }}
          >
            The agreement is governed by the substantive and procedural laws of
            India and you and Zaperon agree to submit to the exclusive
            jurisdiction of, and venue in, the courts at Bangalore in India in
            any dispute arising out of or relating to the agreement.
          </ListItem>
          <ListItem
            sx={{
              display: "list-item",
              font: "normal normal normal 16px/25px Poppins",
              letterSpacing: "0px",
              color: "#0B3558",
            }}
          >
            You may not assign the agreement or give or transfer the services or
            an interest in them to another individual or entity.
          </ListItem>
          <ListItem
            sx={{
              display: "list-item",
              font: {
                lg: "normal normal normal 16px/25px Poppins",
                sm: "normal normal normal 16px/25px Poppins",
                md: "normal normal normal 16px/25px Poppins",
                xs: "normal normal normal 12px/18px Poppins",
              },
              letterSpacing: "0px",
              color: "#0B3558",
            }}
          >
            Except for actions for nonpayment or breach of Zaperon’s proprietary
            rights, no action, regardless of form, arising out of or relating to
            the agreement may be brought by either party more than two years
            after the cause of action has accrued.
          </ListItem>
          <ListItem
            sx={{
              display: "list-item",
              font: {
                lg: "normal normal normal 16px/25px Poppins",
                sm: "normal normal normal 16px/25px Poppins",
                md: "normal normal normal 16px/25px Poppins",
                xs: "normal normal normal 12px/18px Poppins",
              },
              letterSpacing: "0px",
              color: "#0B3558",
            }}
          >
            Zaperon may audit your use of the services. You agree to cooperate
            with Zaperon’s audit and provide reasonable assistance and access to
            information. Any such audit shall not unreasonably interfere with
            your normal business operations. You agree to pay within 30 days of
            written notification any fees applicable to your use of the services
            in excess of your rights. If you do not pay, Zaperon can end your
            services and/or the agreement. You agree that Zaperon shall not be
            responsible for any of your costs incurred in cooperating with the
            audit.
          </ListItem>
          <ListItem
            sx={{
              display: "list-item",
              font: {
                lg: "normal normal normal 16px/25px Poppins",
                sm: "normal normal normal 16px/25px Poppins",
                md: "normal normal normal 16px/25px Poppins",
                xs: "normal normal normal 12px/18px Poppins",
              },
              letterSpacing: "0px",
              color: "#0B3558",
            }}
          >
            You understand that Zaperon’s business partners, including any third
            party firms retained by you to provide computer consulting services,
            are independent of Zaperon and are not Zaperon’s agents. Zaperon is
            not liable for nor bound by any acts of any such business partner,
            unless the business partner is providing services as an Zaperon
            subcontractor on an engagement ordered under this Products
            agreement.
          </ListItem>
        </List>
      </Box>
      <Box
        marginTop="48px"
      // paddingLeft={{ lg: "111px" }}
      // paddingRight={{ lg: "111px" }}
      >
        <Typography
          sx={{
            textAlign: "left",
            font: {
              lg: "normal normal 600 32px/48px Poppins",
              sm: "normal normal 600 24px/35px Poppins",
              md: "normal normal 600 32px/48px Poppins",
              xs: "normal normal 600 16px/25px Poppins",
            },
            letterSpacing: "0px",
            color: "#0B3558",
            opacity: "1",
            marginBottom: { lg: "-30px", xs: "-25px" },
            paddingLeft: { lg: "111px", md: "111px", sm: "32px", xs: "20px" },
            paddingRight: { lg: "111px", md: "111px", sm: "32px", xs: "20px" },
          }}
        >
          16. Force Majeure:
        </Typography>
        <Typography
          sx={{
            textAlign: "left",
            alignItems: "left",
            font: {
              lg: "normal normal normal 16px/25px Poppins",
              sm: "normal normal normal 16px/25px Poppins",
              md: "normal normal normal 16px/25px Poppins",
              xs: "normal normal normal 12px/18px Poppins",
            },
            letterSpacing: "0px",
            color: "#0B3558",
            marginTop: "48px",
            marginBottom: "48px",
            paddingLeft: { lg: "111px", md: "111px", sm: "32px", xs: "20px" },
            paddingRight: { lg: "111px", md: "111px", sm: "32px", xs: "20px" },
          }}
        >
          Neither of us shall be responsible for failure or delay of performance
          if caused by: an act of war, hostility, or sabotage; act of God;
          electrical, internet, or telecommunication outage that is not caused
          by the obligated party; government restrictions (including the denial
          or cancellation of any export or other license); other event outside
          the reasonable control of the obligated party. We both will use
          reasonable efforts to mitigate the effect of a force majeure event. If
          such event continues for more than 30 days, either of us may cancel
          unperformed services upon written notice. This section does not excuse
          either party’s obligation to take reasonable steps to follow its
          normal disaster recovery procedures or your obligation to pay for the
          services.
        </Typography>
      </Box>
      <Box
        marginTop="48px"
      // paddingLeft={{ lg: "111px" }}
      // paddingRight={{ lg: "111px" }}
      >
        <Typography
          sx={{
            textAlign: "left",
            font: {
              lg: "normal normal 600 32px/48px Poppins",
              sm: "normal normal 600 24px/35px Poppins",
              md: "normal normal 600 32px/48px Poppins",
              xs: "normal normal 600 16px/25px Poppins",
            },
            letterSpacing: "0px",
            color: "#0B3558",
            opacity: "1",
            marginBottom: { lg: "-30px", xs: "-25px" },
            paddingLeft: { lg: "111px", md: "111px", sm: "32px", xs: "20px" },
            paddingRight: { lg: "111px", md: "111px", sm: "32px", xs: "20px" },
          }}
        >
          17. Your Data:
        </Typography>
        <Typography
          sx={{
            textAlign: "left",
            alignItems: "left",
            font: {
              lg: "normal normal normal 16px/25px Poppins",
              sm: "normal normal normal 16px/25px Poppins",
              md: "normal normal normal 16px/25px Poppins",
              xs: "normal normal normal 12px/18px Poppins",
            },
            letterSpacing: "0px",
            color: "#0B3558",
            marginTop: "48px",
            marginBottom: "48px",
            paddingLeft: { lg: "111px", md: "111px", sm: "32px", xs: "20px" },
            paddingRight: { lg: "111px", md: "111px", sm: "32px", xs: "20px" },
          }}
        >
          You agree to provide any notices and obtain any consents related to
          your use of the services and Zaperon’s provision of the services,
          including those related to the collection, use, processing, transfer
          and disclosure of personal information. You shall have sole
          responsibility for the accuracy, quality, integrity, legality,
          reliability, appropriateness and ownership of all of your data.
        </Typography>
      </Box>
      <Box
        marginTop="48px"
      // paddingLeft={{ lg: "111px" }}
      // paddingRight={{ lg: "111px" }}
      >
        <Typography
          sx={{
            textAlign: "left",
            font: {
              lg: "normal normal 600 32px/48px Poppins",
              sm: "normal normal 600 24px/35px Poppins",
              md: "normal normal 600 32px/48px Poppins",
              xs: "normal normal 600 16px/25px Poppins",
            },
            letterSpacing: "0px",
            color: "#0B3558",
            opacity: "1",
            marginBottom: { lg: "-30px", xs: "-25px" },
            paddingLeft: { lg: "111px", md: "111px", sm: "32px", xs: "20px" },
            paddingRight: { lg: "111px", md: "111px", sm: "32px", xs: "20px" },
          }}
        >
          18. Restrictions on Use of the Services:
        </Typography>
        <Typography
          sx={{
            textAlign: "left",
            alignItems: "left",
            font: {
              lg: "normal normal normal 16px/25px Poppins",
              sm: "normal normal normal 16px/25px Poppins",
              md: "normal normal normal 16px/25px Poppins",
              xs: "normal normal normal 12px/18px Poppins",
            },
            letterSpacing: "0px",
            color: "#0B3558",
            marginTop: "48px",
            marginBottom: "48px",
            paddingLeft: { lg: "111px", md: "111px", sm: "32px", xs: "20px" },
            paddingRight: { lg: "111px", md: "111px", sm: "32px", xs: "20px" },
          }}
        >
          You agree not to use or permit use of the services, including by
          uploading, emailing, posting, publishing or otherwise transmitting any
          material, for any purpose that may (a) menace or harass any person or
          cause damage or injury to any person or property, (b) involve the
          publication of any material that is false, defamatory, harassing or
          obscene, (c) violate privacy rights or promote bigotry, racism, hatred
          or harm, (d) constitute unsolicited bulk e-mail, “junk mail”, “spam”
          or chain letters; (e) constitute an infringement of intellectual
          property or other proprietary rights, or (f) otherwise violate
          applicable laws, ordinances or regulations. In addition to any other
          rights afforded to Zaperon under the agreement, Zaperon reserves the
          right to remove or disable usage to any material that violates the
          foregoing restrictions. Zaperon shall have no liability to you in the
          event that Zaperon takes such action. You agree to defend and
          indemnify Zaperon against any claim arising out of a violation of your
          obligations under this section.
        </Typography>
      </Box>
      <Box
        marginTop="48px"
      // paddingLeft={{ lg: "111px" }}
      // paddingRight={{ lg: "111px" }}
      >
        <Typography
          sx={{
            textAlign: "left",
            font: {
              lg: "normal normal 600 32px/48px Poppins",
              sm: "normal normal 600 24px/35px Poppins",
              md: "normal normal 600 32px/48px Poppins",
              xs: "normal normal 600 16px/25px Poppins",
            },
            letterSpacing: "0px",
            color: "#0B3558",
            opacity: "1",
            marginBottom: { lg: "-30px", xs: "-25px" },
            paddingLeft: { lg: "111px", md: "111px", sm: "32px", xs: "20px" },
            paddingRight: { lg: "111px", md: "111px", sm: "32px", xs: "20px" },
          }}
        >
          19. Services Tools:
        </Typography>
        <Typography
          sx={{
            textAlign: "left",
            alignItems: "left",
            font: {
              lg: "normal normal normal 16px/25px Poppins",
              sm: "normal normal normal 16px/25px Poppins",
              md: "normal normal normal 16px/25px Poppins",
              xs: "normal normal normal 12px/18px Poppins",
            },
            letterSpacing: "0px",
            color: "#0B3558",
            marginTop: "48px",
            marginBottom: "48px",
            paddingLeft: { lg: "111px", md: "111px", sm: "32px", xs: "20px" },
            paddingRight: { lg: "111px", md: "111px", sm: "32px", xs: "20px" },
          }}
        >
          Zaperon may use tools, scripts, software, and utilities (collectively,
          the “tools”) to monitor and administer the services and to help
          resolve your Zaperon service requests. The tools will not collect,
          report or store any of your data residing in the service production
          environment, except as necessary to troubleshoot service requests or
          other problems in the service. Data collected by the tools (excluding
          production data) may also be used to assist in managing Zaperon’s
          product and service portfolio and for license management. You agree
          that (a) except as set forth in the following paragraph, you may not
          use the tools, and (b) you will not use or restore the tools from any
          tape backup at any time following termination of the agreement. If
          Zaperon provides you with use of any tools in connection with the
          services, your right to use such tools is governed by the license
          terms that Zaperon specifies for such tools; however, if Zaperon does
          not specify license terms for such tools, you shall have a
          non-transferable, non-exclusive, limited right to use such tools
          solely to facilitate your administration and monitoring of your
          services environment, subject to the terms of the agreement. Any such
          tools are provided by Zaperon on an “as is” basis and Zaperon does not
          provide technical support or offer any warranties for such tools. Your
          right to use such tools will terminate upon the earlier of Zaperon ’s
          notice, the end of the services term, or the date that the license to
          use such tools ends under the license terms specified for such tools.
        </Typography>
      </Box>
      <Box
        marginTop="48px"
      // paddingLeft={{ lg: "111px" }}
      // paddingRight={{ lg: "111px" }}
      >
        <Typography
          sx={{
            textAlign: "left",
            font: {
              lg: "normal normal 600 32px/48px Poppins",
              sm: "normal normal 600 24px/35px Poppins",
              md: "normal normal 600 32px/48px Poppins",
              xs: "normal normal 600 16px/25px Poppins",
            },
            letterSpacing: "0px",
            color: "#0B3558",
            opacity: "1",
            marginBottom: { lg: "-30px", xs: "-25px" },
            paddingLeft: { lg: "111px", md: "111px", sm: "32px", xs: "20px" },
            paddingRight: { lg: "111px", md: "111px", sm: "32px", xs: "20px" },
          }}
        >
          20. Statistical Information:
        </Typography>
        <Typography
          sx={{
            textAlign: "left",
            alignItems: "left",
            font: {
              lg: "normal normal normal 16px/25px Poppins",
              sm: "normal normal normal 16px/25px Poppins",
              md: "normal normal normal 16px/25px Poppins",
              xs: "normal normal normal 12px/18px Poppins",
            },
            letterSpacing: "0px",
            color: "#0B3558",
            marginTop: "48px",
            marginBottom: "48px",
            paddingLeft: { lg: "111px", md: "111px", sm: "32px", xs: "20px" },
            paddingRight: { lg: "111px", md: "111px", sm: "32px", xs: "20px" },
          }}
        >
          Zaperon may compile statistical information related to the performance
          of the services, and may make such information publicly available,
          provided that such information does not incorporate your data and/or
          identify your confidential information or include your company’s name.
          Zaperon retains all intellectual property rights in such information.
        </Typography>
      </Box>
      <Box
        marginTop="48px"
      // paddingLeft={{ lg: "111px" }}
      // paddingRight={{ lg: "111px" }}
      >
        <Typography
          sx={{
            textAlign: "left",
            font: {
              lg: "normal normal 600 32px/48px Poppins",
              sm: "normal normal 600 24px/35px Poppins",
              md: "normal normal 600 32px/48px Poppins",
              xs: "normal normal 600 16px/25px Poppins",
            },
            letterSpacing: "0px",
            color: "#0B3558",
            opacity: "1",
            marginBottom: { lg: "-30px", xs: "-25px" },
            paddingLeft: { lg: "111px", md: "111px", sm: "32px", xs: "20px" },
            paddingRight: { lg: "111px", md: "111px", sm: "32px", xs: "20px" },
          }}
        >
          21. Third Party Web Sites, Content, Products and Services:
        </Typography>
        <Typography
          sx={{
            textAlign: "left",
            alignItems: "left",
            font: {
              lg: "normal normal normal 16px/25px Poppins",
              sm: "normal normal normal 16px/25px Poppins",
              md: "normal normal normal 16px/25px Poppins",
              xs: "normal normal normal 12px/18px Poppins",
            },
            letterSpacing: "0px",
            color: "#0B3558",
            marginTop: "48px",
            marginBottom: "48px",
            paddingLeft: { lg: "111px", md: "111px", sm: "32px", xs: "20px" },
            paddingRight: { lg: "111px", md: "111px", sm: "32px", xs: "20px" },
          }}
        >
          The services may enable you to add links to Web sites and access to
          content, products and services of third parties, including users,
          advertisers, affiliates and sponsors of such third parties. Zaperon is
          not responsible for any third-party Web sites or third-party content
          provided on or through the services and you bear all risks associated
          with the access and use of such Web sites and third-party content,
          products and services.
        </Typography>
      </Box>
      <Box
        marginTop="48px"
      // paddingLeft={{ lg: "111px" }}
      // paddingRight={{ lg: "111px" }}
      >
        <Typography
          sx={{
            textAlign: "left",
            font: {
              lg: "normal normal 600 32px/48px Poppins",
              sm: "normal normal 600 24px/35px Poppins",
              md: "normal normal 600 32px/48px Poppins",
              xs: "normal normal 600 16px/25px Poppins",
            },
            letterSpacing: "0px",
            color: "#0B3558",
            opacity: "1",
            marginBottom: { lg: "-30px", xs: "-25px" },
            paddingLeft: { lg: "111px", md: "111px", sm: "32px", xs: "20px" },
            paddingRight: { lg: "111px", md: "111px", sm: "32px", xs: "20px" },
          }}
        >
          22. Customer Reference:
        </Typography>
        <Typography
          sx={{
            textAlign: "left",
            alignItems: "left",
            font: {
              lg: "normal normal normal 16px/25px Poppins",
              sm: "normal normal normal 16px/25px Poppins",
              md: "normal normal normal 16px/25px Poppins",
              xs: "normal normal normal 12px/18px Poppins",
            },
            letterSpacing: "0px",
            color: "#0B3558",
            marginTop: "48px",
            marginBottom: "48px",
            paddingLeft: { lg: "111px", md: "111px", sm: "32px", xs: "20px" },
            paddingRight: { lg: "111px", md: "111px", sm: "32px", xs: "20px" },
          }}
        >
          You agree (i) that Zaperon may identify you as a recipient of services
          and use your logo in sales presentations, marketing materials and
          press releases, and (ii) to develop a brief customer profile for use
          by Zaperon on Zaperon.com or Zaperon’s Information Channels for
          promotional purposes.
        </Typography>
      </Box>
      <Scroll_divider />
      <Footer />
    </div>
  );
};

export default TermsCondition;
