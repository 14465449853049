import React from "react";
import Footer from "./sections/Footer";
// import Header2 from "./ui/Header2";
import "./ThankYou.css";
function ThankYou() {
  return (
    <>
      {/* <Header2 /> */}
      {/* <Whitesections
        pent_img={[3, 4]}
        pent_size={[1, 0]}
        margin={["80px", "415px"]}
        id={""}
        background={"#EEF3FF"}
      > */}
        <div className="flex thanks">
          <img src="./img/ic_successful.svg" alt="startick" />
          <h1>Thank you for requesting a Demo!</h1>
          <p>We will contact you shortly.</p>
          <button>Okay, Got It</button>
        </div>
      {/* </Whitesections> */}
      <Footer />
    </>
  );
}

export default ThankYou;
