/* eslint-disable */

import React from 'react'
import Header from './ui/Header2'
import ImagePC from './img/404_illustration.svg'
import ImageTablet from './img/404_illustration_med.svg'
import ImageMobile from './img/404_illustration_small.svg'
import { Link } from 'react-router-dom'
import Footer from './sections/Footer'
import { useState } from 'react'
import { useEffect } from 'react'


function PageNotFound() {

  const [screenSize, setScreenSize] = useState({ width: window.innerWidth, height: window.innerHeight });

  useEffect(() => {
    const handleResize = () => {
      setScreenSize({ width: window.innerWidth, height: window.innerHeight });
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  let selectedImage;

  // Define your image selection logic based on screen size
  if (screenSize.width >= 1366) {
    selectedImage = ImagePC;
  } else if (screenSize.width >= 768) {
    selectedImage = ImageTablet;
  } else {
    selectedImage = ImageMobile;
  }

  // console.log(selectedImage)

  return (
    <div>
      {/* <Header /> */}
      <div style={{
        marginTop: "80px",
        width: "100%",
        height: "591px",
        backgroundColor: "#0B3558",
        display: "flex",
        flexDirection: "column",
        alignItems: "center", // Center vertically
        justifyContent: "center" // Center horizontally
      }}>
        <div style={{ position: "absolute", top: "170px" }}>
          <h1 className='pagenotfound_h' style={{ font: "normal normal bold 48px/72px Poppins", color: "#FFFFFF", textAlign:"center" }}>Page Not Found!</h1>
          <p className='pagenotfound_p' style={{ font: "normal normal normal 20px/30px Poppins", color: "#FFFFFF", textAlign: "center" }}>You've reached, where nobody <br /> gone before</p>
        </div>
        <img src={selectedImage} alt="Your Image" />
        <div style={{ position: "absolute", top: "520px" }}>
          <Link to="/">
            <span style={{
              font: "normal normal 600 20px/30px Poppins",
              color: "#FFFFFF",
              backgroundColor: "#003FB9",
              borderRadius: "4px",
              padding:"12px 48px"
            }}>
              Take Me Home
            </span>
          </Link>
        </div>
      </div>
      <div style={{marginTop:"70px"}}>
            <Footer />
      </div>
    </div>
  )
}

export default PageNotFound