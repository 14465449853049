import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

firebase.initializeApp({
    apiKey: "AIzaSyDHJNWIVN7fSulp977EZ_y5xRkmpcFM6kw",
    authDomain: "blogs-zaperon.firebaseapp.com",
    databaseURL: "https://blogs-zaperon-default-rtdb.asia-southeast1.firebasedatabase.app",
    projectId: "blogs-zaperon",
    storageBucket: "blogs-zaperon.appspot.com",
    messagingSenderId: "229849911865",
    appId: "1:229849911865:web:fe1959ce3daaa99f1033e8"
});

const fb = firebase;

export default fb;