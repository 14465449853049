import { Box, Container } from '@mui/material'
import React ,{useEffect,useState} from 'react'
import './css/Scroll_divider.css'

const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    left:0,
    behavior: 'smooth',
  })
}
function Scroll_divider() {

  const [showScroll, setShowScroll] = useState(false)
  
  const listenToScroll = () => {

    let heightToHideFrom = 450;
    const winScroll = document.body.scrollTop || 
        document.documentElement.scrollTop;

    if (winScroll > heightToHideFrom) {  
      setShowScroll(true);
    } else {
      setShowScroll(false);
    }  
  };

  useEffect(() => {   
    window.addEventListener("scroll", listenToScroll);
    return () => 
       window.removeEventListener("scroll", listenToScroll); 
  }, [])

  return (
    <>
      <Box component={"div"} padding={{
            lg: "0px 0px 80px 0px",
            md: "0px 0px 80px 0px",
            sm: "0px 0px 48px 0px",
            xs: "0px 0px 48px 0px",
          }} >
         <Box
          component={"div"}
          sx={{
            width: {lg: "1264px", xs: "100%"},
            height: "2px",
            backgroundColor: "#CEDCFF",
            padding: 0,
            margin: {
              lg: "10px auto 0px auto",
              md: "10px auto 24px auto",
              sm: "10px auto 24px auto",
              xs: "0px 0px 0px 0px",
            },
          }}
        />
        <Container>

        {showScroll &&
        <div className='flex scrl-divi' onClick={scrollToTop} title={'Scroll to top'} >
          <span>
          <img src="../img/ic_scroll_top.svg" alt="" height="16px" width="16px" />
          </span>
        </div>}
        </Container>
      </Box>
    </>
  )
}

export default Scroll_divider