import { Box, Button, Card, CardContent, CardMedia, Stack, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import {ReactComponent as ArrowSvg} from "../img/visit-link-arrow.svg"
import fb from '../Firebase';
import { useNavigate } from 'react-router-dom';
const DB = fb.firestore();
const BlogsList = DB.collection('blogs');
const BlogCard = ({date, title, image, link , id}) => {
  const navigate = useNavigate()
    return <Card onClick={() => navigate(`${link}?id=${id}`)} sx={{ maxWidth: {md: 368, sm:  "fit-content", xs:328}, minWidth: {md: 368, sm: "100%", xs: 328 }, minHeight: {lg: 'fit-content', xs: 'fit-content'}, border: "2px solid #CEDCFF", boxShadow: "none", borderRadius: "12px", cursor: "pointer", transition: 'transform 300ms', ':hover': {transform: 'translateY(-15px)', boxShadow: '0px 48px 48px 0px rgba(0, 0, 0, 0.06)'} }}>
    <CardMedia
      component="img" // Set component to "img" to apply max-height properly
      sx={{
        height: 207, // Set initial height
        maxWidth: '100%', // Ensure the image respects the container's width
      }}
      image={image}// Replace with your image source
      alt="green iguana" // Replace with your alt text
    />
    <CardContent sx={{ marginBottom: '12px' , padding: '32px', background: "#FFFFFF" }}>
    <Typography gutterBottom variant="h5" component="div" sx={{
        fontFamily: 'Poppins',
        fontWeight: 400,
        fontSize: {
          xs: '16px',
          sm: '20px',
          md: '16px',
          lg: '16px',
        },
        marginBottom: "12px",
        lineHeight: "150%",
        color: "#6E6F70"
      }}>
        {date}
      </Typography>
      <Typography gutterBottom variant="h5" component="div" sx={{
        fontFamily: 'Poppins',
        fontWeight: 600,
        fontSize: {
          xs: '16px',
          sm: '20px',
          md: '24px',
          lg: '24px',
        },
        lineHeight: {
          xs: '25px',
          sm: '30px',
          md: '30px',
          lg: '30px',
        },
        height: "87px",
        marginBottom: 0
      }}>
        {title}
      </Typography>
      <Button variant='text' endIcon={<ArrowSvg fill='#003FB9'/>} color='primary' sx={{fontSize:{sm: "20px",xs: "16px"},fontWeight:"600", ":hover": {backgroundColor: 'inherit'}, padding: 0, paddingRight: '16px', textTransform:"capitalize", marginTop: "32px"}} >Learn More </Button>
    </CardContent>
  </Card>
}
const BlogsArea = () => {
    const navigate = useNavigate()
    const [blogList, setBlogsList] = useState([])
    useEffect(() => {
        const unsubscribe = BlogsList.limit(100).onSnapshot(querySnapshot => {
          // Get all documents from collection - with IDs
          const data = querySnapshot.docs.map(doc => ({
            ...doc.data(),
            id: doc.id
          }));
          // Update state
          setBlogsList(data);
        });
    
        // Detach listener
        // setLoading(false);
        return unsubscribe;
      }, [])
    
  return (
    <Box sx={{background: "linear-gradient(270deg, #EEF3FF 0.28%, #FFF 50.01%, #EEF3FF 99.73%)", paddingTop: {sm:"80px", xs: "64px"}, paddingBottom: {xs: "64px", sm: 0}}}>
    <Box width={{lg: '1200px', xs: "100%"}} style={{marginLeft: "auto", marginRight: "auto"}}>
    <Stack flexDirection={'row'} alignItems={'center'} justifyContent={'space-between'} padding={'32px'} paddingTop={0} paddingBottom={0} marginLeft={'auto'} marginRight={'auto'}>
        <Typography color={'secondary'} fontSize={{md: '48px', xs: "32px"}} width={'fit-content'} sx={{marginLeft: {xs: "auto", sm: "0px"}, marginRight: {xs: "auto", sm: "0px"}}} textAlign={{xs: 'center', md: "left"}} fontWeight={'600'}>What's New?</Typography>
        <Button onClick={() => navigate('/blog')} variant='contained' endIcon={<ArrowSvg fill='#FFF' />} sx={{fontSize: "20px", fontWeight: '600', textTransform: "capitalize", width: "237px", height: "48px", display: {xs: "none", sm: "inline-flex"}}}>Explore More </Button>
    </Stack>
    <Stack flexDirection={{lg: 'row', xs: "column"}} gap={'32px'} alignItems={'center'} justifyContent={'space-between'} padding={{sm: '80px 32px 32px 32px', xs: "32px 16px 80px 8px"}} paddingRight={'20px'} paddingTop={{lg: "80px", md: "80px", xs: "48px"}} paddingBottom={{sm: '80px', xs: "48px"}} marginLeft={'auto'} marginRight={'auto'}>
    {blogList && blogList.length > 0 && blogList
  .filter((item, index) => index < 3)
  .map((item, index) => (
    <BlogCard id={item.id} link={item.link} key={index} title={item.title} image={item.image} date={item.blogDate} />
  ))
}
    </Stack>
    <Button onClick={() => navigate('/blog')} variant='contained' endIcon={<ArrowSvg fill='#FFF' />} sx={{fontSize: "16px", fontWeight: '600', textTransform: "capitalize", width: "90%", marginLeft: "auto", marginRight: "auto", height: "48px", display: {xs: "flex", sm: "none"}}}>Explore More </Button>

    </Box>
    </Box>
  )
}

export default BlogsArea