import React from 'react';
import Organizations from './sections/Organizations';
import Limitations from './sections/Limitations';
import Hero from './sections/Hero';
import Solution from './sections/Solution';
import Footer from './sections/Footer';
import Scrolldivider from './sections/Scroll_divider';
import Brands from './sections/Brands';
import Bookdemo from './sections/Bookdemo';
import Carousel from './sections/Carousel';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import Acheivments from './sections/Acheivments';
import ProtectIdentity from './sections/ProtectIdentity';
import IntegrateApp from './sections/IntegrateApp';
import DemoVideo from './sections/DemoVideo';
import BlogsArea from './sections/BlogsArea';
import { Box } from '@mui/material';
export default function App(props) {
  const carouselReviews = [
    "We were using Tableau, a SaaS application that could open on any device. With Zaperon, we were able to restrict its access to only our authorized devices. Zaperon is a key to our digital transformation journey.",
    "Zaperon provides us comprehensive application security. We now know which users and devices are using our applications from different locations and times.",
    "Zaperon made onboarding/offboarding of our employees fast and secure. Now, no need to create or remove accounts in all the applications every time and no more password reset requests.",

  ];
  const Reviewers = [
    "Vikas Dutta ",
    "Madhur Prakash",
    "Pankaj Batra",

  ];
  const Agencies = [
    "Head-IT, New Allenberry Works",
    "Founder, Amarya Technologies",
    "CISO, Leading Wealth Management Firm",
  ];

  const schema =
  {
    "@context": "http://schema.org/",
    "@type": "Organization",
    "name": "Zaperon",
    "logo": "https://www.zaperon.com/static/media/ic_zaperon_logo.0f0385a7a03a960d29237b40cf8e0163.svg",
    "url": "https://www.zaperon.com/",
    "address": {
      "@type": "PostalAddress",
      "addressLocality": "Delhi",
      "addressRegion": "Delhi",
      "addressCountry": "India"
    },
    "sameAs": []
  }


  useEffect(() => {
    window.scroll(0, 0)
  }, [])

  return (
    <>
      <Helmet>
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Secure access to your applications and cloud" />
        <meta property="og:url" content="https://www.zaperon.com/" />
        <meta property="og:image" content="https://www.zaperon.com/img/ic_sse_diagram.svg" />
        <meta property="og:description" content="Secure your Hybrid Workforce: Users, Applications, Data & Devices. Passwordless Authentication. MFA. Single Sign-On. Phishing Protection. Replace VPN" />
      </Helmet>
      <Helmet>
        <script type="application/ld+json">{JSON.stringify(schema)}</script>
      </Helmet>
      <Helmet>
          <link rel="canonical" href={"https://www.zaperon.com/"} />
        </Helmet>
      <Helmet>
      </Helmet>
      <Box sx={{overflowX: "hidden"}}>
      <Hero headerToggler={props.headerToggler} />
      <ProtectIdentity />
      <Organizations />
      <Limitations />
      <Solution />
      <IntegrateApp />
      <DemoVideo />
      <Brands />
      <Acheivments />
      <Carousel reviews={carouselReviews} reviewer={Reviewers} Agencies={Agencies} />
      <BlogsArea />
      <Bookdemo />
      <Scrolldivider />
      <Footer />
      </Box>
    </>
  );
}
