import { Container, Typography, Box, Stack } from '@mui/material'
import React, { useEffect } from 'react'
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import './css/Limitations.css'
const Limitations = () => {
    useEffect(() => {
      const gridImgs = document.querySelectorAll('.grid-img');
      const gridTexts = document.querySelectorAll('.grid-text');
  
      const handleMouseOver = (e) => {
        gridTexts.forEach((gridText) => {
          gridText.classList.add('hidden');
        });
        const currentText = e.currentTarget.querySelector('.grid-text');
        if (currentText) {
          currentText.classList.remove('hidden');
        }
      };
  
      const handleMouseOut = () => {
        gridTexts.forEach((gridText) => {
          gridText.classList.remove('hidden');
        });
      };
  
      gridImgs.forEach((gridImg) => {
        gridImg.addEventListener('mouseover', handleMouseOver);
        gridImg.addEventListener('mouseout', handleMouseOut);
      });
  
      return () => {
        gridImgs.forEach((gridImg) => {
          gridImg.removeEventListener('mouseover', handleMouseOver);
          gridImg.removeEventListener('mouseout', handleMouseOut);
        });
      };
    }, []);
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 5000,
    };
  return (
    <Box className='limitationBg'
  >
    <Container  width={"1264px"} sx={{marginLeft:"auto", marginRight: "auto", display: 'flex', flex: 1, justifyContent: "center"}}>
      <Box display={"flex"} flexDirection="column" justifyContent={"center"}>
        <Typography
          variant="h2"
          sx={{
            m: {
              sm: "80px 0px 80px 0px",
              xs: "64px 0px 48px 0px",
            },
            font: {
              lg: "normal normal 600 48px/120% Poppins",
              md: "normal normal 600 48px/120% Poppins",
              sm: "normal normal 600 48px/120% Poppins",
              xs: "normal normal 600 24px/120% Poppins",
            },
            color: "#0B3558",
            textAlign: "center"
          }}
        >
        Existing IAM and ZTNA Solutions are not secure
        </Typography>
        <Box>
        <Stack display={{xs: "flex", lg: "none"}} width={"93vw"} marginLeft="auto" padding={{sm: '0 10px 80px 10px', xs: '0 10px 64px 10px'}} >
        <Slider className='slider'  {...settings}>
        <Box className='grid-img grid-img1'>
        <Typography component={'p'}
          lineHeight={'120%'}
          fontWeight={'600'}
          fontSize={'24px'}
          paddingLeft={'48px'}
          paddingRight={'32px'}
          className='grid-text'
        >
          Use of passwords increase vulnerability to cyberattacks
        </Typography>
        </Box>
        <Box className='grid-img grid-img2'>
        <Typography component={'p'}
          lineHeight={'120%'}
          fontWeight={'600'}
          fontSize={'24px'}
          paddingLeft={'48px'}
          paddingRight={'32px'}
          className='grid-text'
        >
           Traditional MFA can be easily hacked allowing unauthorized access
        </Typography>
        </Box>
        <Box className='grid-img grid-img3'>
        <Typography component={'p'}
          lineHeight={'120%'}
          fontWeight={'600'}
          fontSize={'24px'}
          paddingLeft={'48px'}
          paddingRight={'32px'}
          className='grid-text'
        >
         Third-party desktop & on-premise apps are not supported
        </Typography>
        </Box>
        <Box className='grid-img grid-img4'>
        <Typography component={'p'}
          lineHeight={'120%'}
          fontWeight={'600'}
          fontSize={'24px'}
          paddingLeft={'48px'}
          paddingRight={'32px'}
          className='grid-text'
        >
          Security teams don't get visibility on identity-based threats
        </Typography>
        </Box>
        </Slider>
        </Stack>
        <Stack display={{lg: "flex", xs: "none"}} flexDirection={'row'} justifyContent={'center'} height={'fit-content'}  marginBottom={"80px"} width={"1264px"} marginLeft={"auto"} marginRight={"auto"}>
      <Box className='grid-img grid-img1'>
        <Typography component={'p'}
          lineHeight={'120%'}
          fontWeight={'600'}
          fontSize={'24px'}
          paddingLeft={'48px'}
          paddingRight={'32px'}
          className='grid-text grid-text1 hidden'
        >
          Use of passwords increase vulnerability to cyberattacks
        </Typography>
      </Box>
      <Box className='grid-img grid-img2'>
        <Typography component={'p'}
          lineHeight={'28px'}
          fontWeight={'600'}
          fontSize={'24px'}
          paddingLeft={'48px'}
          paddingRight={'32px'}
          className='grid-text grid-text2'

        >
          Traditional MFA can be easily hacked allowing unauthorized access
        </Typography>
      </Box>
      <Box className='grid-img grid-img3'>
        <Typography component={'p'}
          lineHeight={'28px'}
          fontWeight={'600'}
          fontSize={'24px'}
          paddingLeft={'48px'}
          paddingRight={'32px'}
          className='grid-text grid-text3'
        >
          Third-party desktop & on-premise apps are not supported
        </Typography>
      </Box>
      <Box className='grid-img grid-img4'>
        <Typography component={'p'}
          lineHeight={'28px'}
          fontWeight={'600'}
          fontSize={'24px'}
          paddingLeft={'48px'}
          paddingRight={'32px'}
          className='grid-text grid-text4'
        >
          Security teams don't get visibility on identity-based threats
        </Typography>
      </Box>
    </Stack>
    </Box>
        </Box>
        </Container></Box>
  )
}

export default Limitations