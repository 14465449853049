/* eslint-disable */

import React, { useEffect } from "react";
import Header2 from "../ui/Header2";
import { Box, Typography, List, ListItem } from "@mui/material";
import Footer from "./Footer";
import Scroll_divider from "./Scroll_divider";
import { Helmet } from "react-helmet";

const PrivacyPolicy = () => {

  useEffect(() => {
    window.scroll(0, 0)
  }, [])

  useEffect(() => {
    document.title = 'Privacy Policy';
    document.getElementsByTagName("META")[2].content = "Zaperon Zero Trust Privacy Policy";
  }, []);

  return (
    <div>
      <Helmet>
        <link rel="canonical" href={"https://www.zaperon.com/privacyPolicy"} />
      </Helmet>
      {/* <Header2 /> */}
      <Box
        sx={{
          height: { lg: "200px", md: "200px", sm: "200px", xs: "164px" },
          background: "#EEF3FF 0% 0% no-repeat padding-box",
          textAlign: "center",
        }}
      >
        <Typography
          gutterBottom
          variant="h1"
          textAlign={"center"}
          sx={{
            font: {
              lg: "normal normal bold 32px/48px Poppins",
              md: "normal normal bold 32px/48px Poppins",
              sm: " normal normal bold 24px/35px Poppins",
              xs: "normal normal bold 16px/25px Poppins",
            },
            color: "#003FB9",
            marginTop: { lg: "80px", md: "80px", sm: "80px", xs: "48px" },
            paddingTop: { lg: "78px", md: "78px", sm: "80px", xs: "65px" },
          }}
        >
          PRIVACY POLICY
        </Typography>
      </Box>
      <Box>
        <Typography
          sx={{
            textAlign: "left",
            alignItems: "left",
            font: {
              lg: "normal normal normal 16px/25px Poppins",
              sm: "normal normal normal 16px/25px Poppins",
              md: "normal normal normal 16px/25px Poppins",
              xs: "normal normal normal 12px/18px Poppins",
            },
            letterSpacing: "0px",
            color: "#0B3558",
            marginTop: "48px",
            paddingLeft: { lg: "111px", md: "111px", sm: "32px", xs: "20px" },
            paddingRight: { lg: "111px", md: "111px", sm: "32px", xs: "20px" },
          }}
        >
          This <b>Privacy Policy </b>(“Policy”) explains our policy regarding
          the collection, use, processing and disclosure of your information by
          FinLock Technologies Private Limited, Zaperon product and/or its
          subsidiary(ies) and/or affiliate(s) (hereinafter collectively referred
          to as “Company”) that operates the website (“Site”), mobile
          application (“App”) and desktop application (“Desktop App”) and other
          services that link to or reference this Privacy Statement (hereinafter
          collectively referred to as the "Zaperon Platform") via any mobile or
          internet connected device or otherwise.
        </Typography>
        <Typography
          sx={{
            textAlign: "left",
            alignItems: "left",
            font: {
              lg: "normal normal normal 16px/25px Poppins",
              sm: "normal normal normal 16px/25px Poppins",
              md: "normal normal normal 16px/25px Poppins",
              xs: "normal normal normal 12px/18px Poppins",
            },
            letterSpacing: "0px",
            color: "#0B3558",
            marginTop: "24px",
            paddingLeft: { lg: "111px", md: "111px", sm: "32px", xs: "20px" },
            paddingRight: { lg: "111px", md: "111px", sm: "32px", xs: "20px" },
          }}
        >
          This Policy forms part and parcel of the Terms & Conditions for the
          Zaperon Platform. As we update, improve and expand the services
          offered by the Zaperon Platform.
        </Typography>
        <Typography
          sx={{
            textAlign: "left",
            alignItems: "left",
            font: {
              lg: "normal normal normal 16px/25px Poppins",
              sm: "normal normal normal 16px/25px Poppins",
              md: "normal normal normal 16px/25px Poppins",
              xs: "normal normal normal 12px/18px Poppins",
            },
            letterSpacing: "0px",
            color: "#0B3558",
            marginTop: "24px",
            paddingLeft: { lg: "111px", md: "111px", sm: "32px", xs: "20px" },
            paddingRight: { lg: "111px", md: "111px", sm: "32px", xs: "20px" },
          }}
        >
          This Privacy Policy (“Policy”) explains our policy regarding the
          collection, use, processing and disclosure of your information by
          FinLock Technologies Private Limited, Zaperon product and/or its
          subsidiary(ies) and/or affiliate(s) (hereinafter collectively referred
          to as “Company”) that operates the website (“Site”), mobile
          application (“App”) and desktop application (“Desktop App”) and other
          services that link to or reference this Privacy Statement (hereinafter
          collectively referred to as the "Zaperon Platform") via any mobile or
          internet connected device or otherwise. This Policy forms part and
          parcel of the Terms & Conditions for the Zaperon Platform. As we
          update, improve and expand the services offered by the Zaperon
          Platform, this policy may change, so please refer to it periodically.
          By accessing the website, Zaperon product or otherwise using any
          service offered by the Zaperon platform, you consent to collection,
          storage, and use of the personal information you provide (including
          any changes thereto as provided by you) for any of the services that
          we offer. The company respects the privacy of the users of the Zaperon
          platform, understands the sensitivity of the information and is
          committed to protect it in all respects. The information about the
          user as collected by the company is: (a) information provided by users
          and (b) information automatically tracked while a user uses the
          Zaperon Platform (c) information generated while availing our services
          including but not limited to transactional information (collectively
          referred to as "User Information" or "Information"). The term
          “partner” is used in the popular sense as referring to a person or
          entity that has entered into a collaborative relationship with the
          Company and does not imply the existence of a legal partnership.
        </Typography>
      </Box>
      <Box marginTop="48px">
        <Typography
          sx={{
            textAlign: "left",
            font: {
              lg: "normal normal 600 32px/48px Poppins",
              sm: "normal normal 600 24px/35px Poppins",
              md: "normal normal 600 32px/48px Poppins",
              xs: "normal normal 600 16px/25px Poppins",
            },
            letterSpacing: "0px",
            color: "#0B3558",
            opacity: "1",
            marginBottom: { lg: "-30px", xs: "16px" },
            paddingLeft: { lg: "111px", md: "111px", sm: "32px", xs: "20px" },
            paddingRight: { lg: "111px", md: "111px", sm: "32px", xs: "20px" },
          }}
        >
          Information Provided by Users
        </Typography>
        <Typography
          sx={{
            textAlign: "left",
            alignItems: "left",
            font: {
              lg: "normal normal normal 16px/25px Poppins",
              sm: "normal normal normal 16px/25px Poppins",
              md: "normal normal normal 16px/25px Poppins",
              xs: "normal normal normal 12px/18px Poppins",
            },
            letterSpacing: "0px",
            color: "#0B3558",
            marginTop: { lg: "48px" },
            paddingLeft: { lg: "111px", md: "111px", sm: "32px", xs: "20px" },
            paddingRight: { lg: "111px", md: "111px", sm: "32px", xs: "20px" },
          }}
        >
          To avail certain services on the Zaperon Platform, users are required
          to provide certain personal information such as name, company, email
          address, phone number and any other such information as required. As
          is true of most websites, we gather certain information automatically.
          This information may include Internet protocol (IP) addresses, browser
          type, Internet service provider (ISP), referring/exit pages, the files
          viewed on our site (e.g., HTML pages, graphics, etc.), operating
          system, date/time stamp, and/or clickstream data to analyze trends in
          the aggregate and administer the site.
        </Typography>
        <Typography
          sx={{
            textAlign: "left",
            alignItems: "left",
            font: {
              lg: "normal normal normal 16px/25px Poppins",
              sm: "normal normal normal 16px/25px Poppins",
              md: "normal normal normal 16px/25px Poppins",
              xs: "normal normal normal 12px/18px Poppins",
            },
            letterSpacing: "0px",
            color: "#0B3558",
            marginTop: "24px",
            paddingLeft: { lg: "111px", md: "111px", sm: "32px", xs: "20px" },
            paddingRight: { lg: "111px", md: "111px", sm: "32px", xs: "20px" },
          }}
        >
          We may also use your phone number, email address or other personal
          information to send commercial or marketing messages with an option to
          subscribe/unsubscribe (where feasible). We may also, however, use your
          email address and phone number without further consent for
          non-marketing or administrative purposes (such as notifying you of
          major changes, for customer service purposes, providing information
          about updates to Zaperon platform, billing, etc.).
        </Typography>
        <Typography
          sx={{
            textAlign: "left",
            alignItems: "left",
            font: {
              lg: "normal normal normal 16px/25px Poppins",
              sm: "normal normal normal 16px/25px Poppins",
              md: "normal normal normal 16px/25px Poppins",
              xs: "normal normal normal 12px/18px Poppins",
            },
            letterSpacing: "0px",
            color: "#0B3558",
            marginTop: "24px",
            paddingLeft: { lg: "111px", md: "111px", sm: "32px", xs: "20px" },
            paddingRight: { lg: "111px", md: "111px", sm: "32px", xs: "20px" },
          }}
        >
          Any personally identifiable information provided by you will not be
          considered as sensitive if it is freely available and/or accessible in
          the public domain. Further, any reviews, comments, messages, blogs
          posted/uploaded/conveyed/communicated by users on the public sections
          of the site or an Application Store (like the App Store or Play Store)
          becomes published content and is not considered personally
          identifiable information subject to this Privacy Policy.
        </Typography>
        <Typography
          sx={{
            textAlign: "left",
            alignItems: "left",
            font: {
              lg: "normal normal normal 16px/25px Poppins",
              sm: "normal normal normal 16px/25px Poppins",
              md: "normal normal normal 16px/25px Poppins",
              xs: "normal normal normal 12px/18px Poppins",
            },
            letterSpacing: "0px",
            color: "#0B3558",
            marginTop: "24px",
            paddingLeft: { lg: "111px", md: "111px", sm: "32px", xs: "20px" },
            paddingRight: { lg: "111px", md: "111px", sm: "32px", xs: "20px" },
          }}
        >
          We may receive information about you from other sources, including
          third parties from whom we have obtained data such as name, title,
          company, email address, physical mailing address, phone number, and
          information about the advertising link clicked, information requested,
          or event registered for, etc. We may combine this data with
          information we already have about you. This helps us to update, expand
          and analyze our records, identify new customers, and provide products
          and services that may be of interest to you. If you provide us
          personal information about others, or if others give us your
          information, we will only use that information for the specific reason
          for which it was provided to us. If you believe that your personal
          information has been provided to us and would like to request that it
          be removed from our database, please contact us.
        </Typography>
        <Typography
          sx={{
            textAlign: "left",
            alignItems: "left",
            font: {
              lg: "normal normal normal 16px/25px Poppins",
              sm: "normal normal normal 16px/25px Poppins",
              md: "normal normal normal 16px/25px Poppins",
              xs: "normal normal normal 12px/18px Poppins",
            },
            letterSpacing: "0px",
            color: "#0B3558",
            marginTop: "24px",
            paddingLeft: { lg: "111px", md: "111px", sm: "32px", xs: "20px" },
            paddingRight: { lg: "111px", md: "111px", sm: "32px", xs: "20px" },
          }}
        >
          By proceeding to use the Site and/or the services on Zaperon Platform,
          you consent that we may process the personal data that we collect from
          you in accordance with this Privacy Statement. The use of information
          collected through our Platform shall be limited to the purpose of
          providing the service for which the Client has engaged Finlock. In
          case you choose to decline to submit personally identifiable
          information on the Zaperon platform, we may not be able to provide
          certain services on the Zaperon platform to you. We will make
          reasonable efforts to notify you of the same at the appropriate time.
          In any case, we will not be liable and or responsible for the denial
          of certain services to you for lack of you providing the necessary
          personal information.
        </Typography>
        <Typography
          sx={{
            textAlign: "left",
            alignItems: "left",
            font: {
              lg: "normal normal normal 16px/25px Poppins",
              sm: "normal normal normal 16px/25px Poppins",
              md: "normal normal normal 16px/25px Poppins",
              xs: "normal normal normal 12px/18px Poppins",
            },
            letterSpacing: "0px",
            color: "#0B3558",
            marginTop: "24px",
            paddingLeft: { lg: "111px", md: "111px", sm: "32px", xs: "20px" },
            paddingRight: { lg: "111px", md: "111px", sm: "32px", xs: "20px" },
          }}
        >
          Zaperon Platform is not directed at children. We do not knowingly
          collect Personal Data from children under the age of 16. If you are a
          parent or guardian and believe that your child has provided us with
          Personal Data without your consent, please contact us and we will take
          steps to delete such Personal Data from our systems.
        </Typography>
      </Box>
      <Box
        marginTop="48px"
      // paddingLeft={{ lg: "111px" }}
      // paddingRight={{ lg: "111px" }}
      >
        <Typography
          sx={{
            textAlign: "left",
            font: {
              lg: "normal normal 600 32px/48px Poppins",
              sm: "normal normal 600 24px/35px Poppins",
              md: "normal normal 600 32px/48px Poppins",
              xs: "normal normal 600 16px/25px Poppins",
            },
            letterSpacing: "0px",
            color: "#0B3558",
            opacity: "1",
            marginBottom: { lg: "-30px", xs: "16px" },
            paddingLeft: { lg: "111px", md: "111px", sm: "32px", xs: "20px" },
            paddingRight: { lg: "111px", md: "111px", sm: "32px", xs: "20px" },
          }}
        >
          Information Automatically Tracked While Using Zaperon Platform
        </Typography>
        <Typography
          sx={{
            textAlign: "left",
            font: {
              lg: "normal normal 600 24px/35px Poppins",
              sm: "normal normal 600 16px/25px Poppins",
              md: "normal normal 600 24px/35px Poppins",
              xs: "normal normal 600 16px/25px Poppins",
            },
            letterSpacing: "0px",
            color: "#0B3558",
            opacity: "1",
            marginBottom: { lg: "-30px", xs: "-30px" },
            paddingTop: "42px",
            paddingLeft: { lg: "111px", md: "111px", sm: "32px", xs: "20px" },
            paddingRight: { lg: "111px", md: "111px", sm: "32px", xs: "20px" },
          }}
        >
          (a) Log File Information
        </Typography>
        <Typography
          sx={{
            textAlign: "left",
            alignItems: "left",
            font: {
              lg: "normal normal normal 16px/25px Poppins",
              sm: "normal normal normal 16px/25px Poppins",
              md: "normal normal normal 16px/25px Poppins",
              xs: "normal normal normal 12px/18px Poppins",
            },
            letterSpacing: "0px",
            color: "#0B3558",
            marginTop: "48px",
            paddingLeft: { lg: "111px", md: "111px", sm: "32px", xs: "20px" },
            paddingRight: { lg: "111px", md: "111px", sm: "32px", xs: "20px" },
          }}
        >
          When you download and use our Services, we automatically collect
          information on the type of device you use and security parameters
          related to your device such as device name, operating system version,
          device location, etc. Our Servers automatically collect limited
          information about users’ device’s connection to the Internet,
          including IP address. These are essential to ensure only genuine and
          secure devices are accessing your enterprise applications. You may
          also share your information while registering as a user, partner,
          registering for information requests such as reports, whitepapers or
          datasheets, registering for events, online webinar, conferences,
          promotions, etc., participating in demos, surveys, questionnaires, or
          POC(proof of concept), trials, sending marketing communication,
          newsletters. You may also submit information while managing job
          application.
        </Typography>
        <Typography
          sx={{
            textAlign: "left",
            font: {
              lg: "normal normal 600 24px/35px Poppins",
              sm: "normal normal 600 16px/25px Poppins",
              md: "normal normal 600 24px/35px Poppins",
              xs: "normal normal 600 16px/25px Poppins",
            },
            letterSpacing: "0px",
            color: "#0B3558",
            opacity: "1",
            marginBottom: { lg: "-30px", xs: "-30px" },
            paddingTop: "16px",
            // marginBottom: { lg: "-30px" },
            paddingLeft: { lg: "111px", md: "111px", sm: "32px", xs: "20px" },
            paddingRight: { lg: "111px", md: "111px", sm: "32px", xs: "20px" },
          }}
        >
          (b) Cookies
        </Typography>
        <Typography
          sx={{
            textAlign: "left",
            alignItems: "left",
            font: {
              lg: "normal normal normal 16px/25px Poppins",
              sm: "normal normal normal 16px/25px Poppins",
              md: "normal normal normal 16px/25px Poppins",
              xs: "normal normal normal 12px/18px Poppins",
            },
            letterSpacing: "0px",
            color: "#0B3558",
            marginTop: "48px",
            paddingLeft: { lg: "111px", md: "111px", sm: "32px", xs: "20px" },
            paddingRight: { lg: "111px", md: "111px", sm: "32px", xs: "20px" },
          }}
        >
          To improve the responsiveness of the Zaperon Platform for our users,
          we may use “cookies”, or similar electronic tools to collect
          information to assign each visitor a unique, random number as a User
          Identification (User ID) to understand the user’s individual interests
          using the identified computer or device. Our partners may also assign
          their own cookies to your browser, a process that we do not control.
        </Typography>
        <Typography
          sx={{
            textAlign: "left",
            alignItems: "left",
            font: {
              lg: "normal normal normal 16px/25px Poppins",
              sm: "normal normal normal 16px/25px Poppins",
              md: "normal normal normal 16px/25px Poppins",
              xs: "normal normal normal 12px/18px Poppins",
            },
            letterSpacing: "0px",
            color: "#0B3558",
            marginTop: "24px",
            paddingLeft: { lg: "111px", md: "111px", sm: "32px", xs: "20px" },
            paddingRight: { lg: "111px", md: "111px", sm: "32px", xs: "20px" },
          }}
        >
          These tools help make your visit to our website easier, more
          efficient, and more valuable by providing you with a customized
          experience and recognizing you when you return. If you prefer not to
          receive cookies while browsing our website or via HTML-formatted
          emails, you can set your browser to warn you before accepting cookies
          and refuse the cookie when your browser alerts you to its presence.
          You can also refuse all cookies by turning them off in your browser,
          although you may not be able to take full advantage of Zaperon website
          if you do so. In particular, you may be required to accept cookies in
          order to complete certain actions on our website. To use/navigate
          through many parts of our website, you do not need to turn on cookies.
        </Typography>
        <Typography
          sx={{
            textAlign: "left",
            alignItems: "left",
            font: {
              lg: "normal normal normal 16px/25px Poppins",
              sm: "normal normal normal 16px/25px Poppins",
              md: "normal normal normal 16px/25px Poppins",
              xs: "normal normal normal 12px/18px Poppins",
            },
            letterSpacing: "0px",
            color: "#0B3558",
            marginTop: "24px",
            paddingLeft: { lg: "111px", md: "111px", sm: "32px", xs: "20px" },
            paddingRight: { lg: "111px", md: "111px", sm: "32px", xs: "20px" },
          }}
        >
          Our website also include widgets, which are interactive mini-programs
          that run on our site to provide specific services from another company
          (such as displaying the news, opinions, music, and more). Personal
          information, such as your email address, may be collected through such
          widgets. Cookies may also be set by widgets to enable it to function
          properly. Information collected by widgets is governed by the privacy
          policy of the company that created the widget
        </Typography>
        <Typography
          sx={{
            textAlign: "left",
            font: {
              lg: "normal normal 600 24px/35px Poppins",
              sm: "normal normal 600 16px/25px Poppins",
              md: "normal normal 600 24px/35px Poppins",
              xs: "normal normal 600 16px/25px Poppins",
            },
            letterSpacing: "0px",
            color: "#0B3558",
            opacity: "1",
            marginBottom: { lg: "-30px", xs: "-25px" },
            paddingTop: "16px",
            // marginBottom: { lg: "-30px" },
            paddingLeft: { lg: "111px", md: "111px", sm: "32px", xs: "20px" },
            paddingRight: { lg: "111px", md: "111px", sm: "32px", xs: "20px" },
          }}
        >
          (c) Zaperon Usage Data
        </Typography>
        <Typography
          sx={{
            textAlign: "left",
            alignItems: "left",
            font: {
              lg: "normal normal normal 16px/25px Poppins",
              sm: "normal normal normal 16px/25px Poppins",
              md: "normal normal normal 16px/25px Poppins",
              xs: "normal normal normal 12px/18px Poppins",
            },
            letterSpacing: "0px",
            color: "#0B3558",
            marginTop: "48px",
            paddingLeft: { lg: "111px", md: "111px", sm: "32px", xs: "20px" },
            paddingRight: { lg: "111px", md: "111px", sm: "32px", xs: "20px" },
          }}
        >
          Zaperon Platform collects customer and usage data that enables us to
          innovate while acting as a service provider to our customers and to
          continuously improve upon the services. In conjunction with the
          products we make available to our customers, we may collect additional
          data, such as user-agent and browser version, IP address, the URLs you
          visit (such as to determine whether we can help you manage your
          credentials for such URLs), logs of your usage and click activities,
          logs about your login history, identity confirmation, and device data
          (such as whether your device is managed by an administrator, the
          operating system installed on the device, certain device
          configurations, and similar device or version information). Zaperon
          uses this data to improve security and to provide and improve its
          products to customers, including to better understand customer
          behavior in order to create new features and provide threat-related
          insights for our customers.
        </Typography>
      </Box>
      <Box
        marginTop="48px"
      // paddingLeft={{ lg: "111px" }}
      // paddingRight={{ lg: "111px" }}
      >
        <Typography
          sx={{
            textAlign: "left",
            font: {
              lg: "normal normal 600 32px/48px Poppins",
              sm: "normal normal 600 24px/35px Poppins",
              md: "normal normal 600 32px/48px Poppins",
              xs: "normal normal 600 16px/25px Poppins",
            },
            letterSpacing: "0px",
            color: "#0B3558",
            opacity: "1",
            marginBottom: { lg: "-30px", xs: "-26px" },
            // marginBottom: { lg: "-30px" },
            paddingLeft: { lg: "111px", md: "111px", sm: "32px", xs: "20px" },
            paddingRight: { lg: "111px", md: "111px", sm: "32px", xs: "20px" },
          }}
        >
          Links to Third Party Sites
        </Typography>
        <Typography
          sx={{
            textAlign: "left",
            alignItems: "left",
            font: {
              lg: "normal normal normal 16px/25px Poppins",
              sm: "normal normal normal 16px/25px Poppins",
              md: "normal normal normal 16px/25px Poppins",
              xs: "normal normal normal 12px/18px Poppins",
            },
            letterSpacing: "0px",
            color: "#0B3558",
            marginTop: "48px",
            paddingLeft: { lg: "111px", md: "111px", sm: "32px", xs: "20px" },
            paddingRight: { lg: "111px", md: "111px", sm: "32px", xs: "20px" },
          }}
        >
          The Zaperon Platform may include links to other websites or
          applications. Such websites or applications are governed by their
          respective privacy policies, which are beyond our control. Once you
          leave our servers (you can tell where you are by checking the URL in
          the location bar on your browser), use of any information you provide
          is governed by the privacy policy of the operator of the site you are
          visiting. That policy may differ from ours. If you can’t find the
          privacy policy of any of these sites via a link from the site’s
          homepage, you should contact the site directly for more information.
        </Typography>
      </Box>
      <Box
        marginTop="48px"
      // paddingLeft={{ lg: "111px" }}
      // paddingRight={{ lg: "111px" }}
      >
        <Typography
          sx={{
            textAlign: "left",
            font: {
              lg: "normal normal 600 32px/48px Poppins",
              sm: "normal normal 600 24px/35px Poppins",
              md: "normal normal 600 32px/48px Poppins",
              xs: "normal normal 600 16px/25px Poppins",
            },
            letterSpacing: "0px",
            color: "#0B3558",
            opacity: "1",
            marginBottom: { lg: "-30px", xs: "-25px" },
            paddingLeft: { lg: "111px", md: "111px", sm: "32px", xs: "20px" },
            paddingRight: { lg: "111px", md: "111px", sm: "32px", xs: "20px" },
          }}
        >
          Sharing and disclosure of data with third parties
        </Typography>
        <Typography
          sx={{
            textAlign: "left",
            alignItems: "left",
            font: {
              lg: "normal normal normal 16px/25px Poppins",
              sm: "normal normal normal 16px/25px Poppins",
              md: "normal normal normal 16px/25px Poppins",
              xs: "normal normal normal 12px/18px Poppins",
            },
            letterSpacing: "0px",
            color: "#0B3558",
            marginTop: "48px",
            paddingLeft: { lg: "111px", md: "111px", sm: "32px", xs: "20px" },
            paddingRight: { lg: "111px", md: "111px", sm: "32px", xs: "20px" },
          }}
        >
          We neither rent nor sell your personal information to anyone. However,
          we may share personal information with third parties or with worldwide
          offices of Finlock or its subsidiaries without obtaining the prior
          consent of the user in the following limited circumstances:
        </Typography>
        <List sx={{ listStyleType: "disc", pl: {
                lg: 22,
                sm: 8,
                md: 16,
                xs: 8,
              } }}>
          <ListItem
            sx={{
              display: "list-item",
              font: {
                lg: "normal normal normal 16px/25px Poppins",
                sm: "normal normal normal 16px/25px Poppins",
                md: "normal normal normal 16px/25px Poppins",
                xs: "normal normal normal 12px/18px Poppins",
              },
              letterSpacing: "0px",
              color: "#0B3558",
              //   paddingLeft: { lg: "111px", md: "111px", sm: "111px", xs: "20px" },
              // paddingRight: { lg: "111px", md: "111px", sm: "111px", xs: "20px" },
            }}
          >
            When it is requested or required by law or by any court or
            governmental agency or authority to disclose, for the purpose of
            verification of identity, or for the prevention, detection,
            investigation including cyber incidents, or for prosecution and
            punishment of offences. These disclosures are made in good faith and
            belief that such disclosure is reasonably necessary for enforcing
            these Terms or for complying with the applicable laws and
            regulations.
          </ListItem>
          <ListItem
            sx={{
              display: "list-item",
              font: {
                lg: "normal normal normal 16px/25px Poppins",
                sm: "normal normal normal 16px/25px Poppins",
                md: "normal normal normal 16px/25px Poppins",
                xs: "normal normal normal 12px/18px Poppins",
              },
              letterSpacing: "0px",
              color: "#0B3558",
            }}
          >
            If sharing your information is necessary to provide a product,
            service or information you have requested. Examples include, but are
            not limited to: processing of orders, payment transactions, hosting
            websites, seminar registration, assisting with sales-related efforts
            or post-sales customer support.
          </ListItem>
          <ListItem
            sx={{
              display: "list-item",
              font: {
                lg: "normal normal normal 16px/25px Poppins",
                sm: "normal normal normal 16px/25px Poppins",
                md: "normal normal normal 16px/25px Poppins",
                xs: "normal normal normal 12px/18px Poppins",
              },
              letterSpacing: "0px",
              color: "#0B3558",
            }}
          >
            To keep you up to date on the latest product announcements, software
            updates, special offers or other information that you would like to
            hear about either from us, through our marketing agencies or from
            our business partners.
          </ListItem>
          <ListItem
            sx={{
              display: "list-item",
              font: "normal normal normal 16px/25px Poppins",
              letterSpacing: "0px",
              color: "#0B3558",
            }}
          >
            For the purpose of conducting US Export law compliance checks,
            passing your information to our contracted vendors.
          </ListItem>
          <ListItem
            sx={{
              display: "list-item",
              font: {
                lg: "normal normal normal 16px/25px Poppins",
                sm: "normal normal normal 16px/25px Poppins",
                md: "normal normal normal 16px/25px Poppins",
                xs: "normal normal normal 12px/18px Poppins",
              },
              letterSpacing: "0px",
              color: "#0B3558",
            }}
          >
            No personally identifiable data of an individual user such as name,
            phone number, email address etc. would be shared with any other user
            and/or third party – unless explicitly approved by the concerned
            individual user in order to avail of certain services. However, if
            this conflicts with conditions mentioned in the points above, this
            condition would become null and void.
          </ListItem>
        </List>
      </Box>
      <Box
        marginTop="28px"
      // paddingLeft={{ lg: "111px" }}
      // paddingRight={{ lg: "111px" }}
      >
        <Typography
          sx={{
            textAlign: "left",
            font: {
              lg: "normal normal 600 32px/48px Poppins",
              sm: "normal normal 600 24px/35px Poppins",
              md: "normal normal 600 32px/48px Poppins",
              xs: "normal normal 600 16px/25px Poppins",
            },
            letterSpacing: "0px",
            color: "#0B3558",
            opacity: "1",
            marginBottom: { lg: "-30px", xs: "16px" },
            paddingLeft: { lg: "111px", md: "111px", sm: "32px", xs: "20px" },
            paddingRight: { lg: "111px", md: "111px", sm: "32px", xs: "20px" },
          }}
        >
          Accessing and updating personal information
        </Typography>
        <Typography
          sx={{
            textAlign: "left",
            alignItems: "left",
            font: {
              lg: "normal normal normal 16px/25px Poppins",
              sm: "normal normal normal 16px/25px Poppins",
              md: "normal normal normal 16px/25px Poppins",
              xs: "normal normal normal 12px/18px Poppins",
            },
            letterSpacing: "0px",
            color: "#0B3558",
            marginTop: "48px",
            paddingLeft: { lg: "111px", md: "111px", sm: "32px", xs: "20px" },
            paddingRight: { lg: "111px", md: "111px", sm: "32px", xs: "20px" },
          }}
        >
          You may review, update, and correct your personal information posted
          on or submitted through our Site or that we provide you with
          information about whether we hold or process any of your personal
          information by contacting support@finlock.in.
        </Typography>
        <Typography
          sx={{
            textAlign: "left",
            alignItems: "left",
            font: {
              lg: "normal normal normal 16px/25px Poppins",
              sm: "normal normal normal 16px/25px Poppins",
              md: "normal normal normal 16px/25px Poppins",
              xs: "normal normal normal 12px/18px Poppins",
            },
            letterSpacing: "0px",
            color: "#0B3558",
            marginTop: "22px",
            paddingLeft: { lg: "111px", md: "111px", sm: "32px", xs: "20px" },
            paddingRight: { lg: "111px", md: "111px", sm: "32px", xs: "20px" },
          }}
        >
          We will respond to these requests within a reasonable timeframe.
          Copies of information that you have updated, modified or deleted may
          remain viewable in cached and archived pages of the Site for a period
          of time. We may also retain your information in our business records
          for as long as your account is active or as needed to provide you
          services, comply with our legal obligations, resolve disputes and
          enforce our agreements.
        </Typography>
        <Typography
          sx={{
            textAlign: "left",
            alignItems: "left",
            font: {
              lg: "normal normal normal 16px/25px Poppins",
              sm: "normal normal normal 16px/25px Poppins",
              md: "normal normal normal 16px/25px Poppins",
              xs: "normal normal normal 12px/18px Poppins",
            },
            letterSpacing: "0px",
            color: "#0B3558",
            marginTop: "22px",
            paddingLeft: { lg: "111px", md: "111px", sm: "32px", xs: "20px" },
            paddingRight: { lg: "111px", md: "111px", sm: "32px", xs: "20px" },
          }}
        >
          You may sign-up to receive our newsletter or other communications and
          information from us related to our Services. If you would like to
          discontinue receiving this information, you may update your email
          preferences by using the “Unsubscribe” link found in each promotional
          email we send to you or by sending us an email at support@finlock.in.
          Please be sure to include your name, email address, and specific,
          relevant information about the material you no longer wish to receive.
        </Typography>
        <Typography
          sx={{
            textAlign: "left",
            alignItems: "left",
            font: {
              lg: "normal normal normal 16px/25px Poppins",
              sm: "normal normal normal 16px/25px Poppins",
              md: "normal normal normal 16px/25px Poppins",
              xs: "normal normal normal 12px/18px Poppins",
            },
            letterSpacing: "0px",
            color: "#0B3558",
            marginTop: "22px",
            paddingLeft: { lg: "111px", md: "111px", sm: "32px", xs: "20px" },
            paddingRight: { lg: "111px", md: "111px", sm: "32px", xs: "20px" },
          }}
        >
          These choices do not apply to service notifications or other required
          communications that are considered part of certain Services, which you
          may receive periodically unless you stop using or cancel the Service
          in accordance with its terms and conditions.
        </Typography>
      </Box>
      <Box
        marginTop="48px"
      // paddingLeft={{ lg: "111px" }}
      // paddingRight={{ lg: "111px" }}
      >
        <Typography
          sx={{
            textAlign: "left",
            font: {
              lg: "normal normal 600 32px/48px Poppins",
              sm: "normal normal 600 24px/35px Poppins",
              md: "normal normal 600 32px/48px Poppins",
              xs: "normal normal 600 16px/25px Poppins",
            },
            letterSpacing: "0px",
            color: "#0B3558",
            opacity: "1",
            marginBottom: { lg: "-30px", xs: "-25px" },
            paddingLeft: { lg: "111px", md: "111px", sm: "32px", xs: "20px" },
            paddingRight: { lg: "111px", md: "111px", sm: "32px", xs: "20px" },
          }}
        >
          Information Retention
        </Typography>
        <Typography
          sx={{
            textAlign: "left",
            alignItems: "left",
            font: {
              lg: "normal normal normal 16px/25px Poppins",
              sm: "normal normal normal 16px/25px Poppins",
              md: "normal normal normal 16px/25px Poppins",
              xs: "normal normal normal 12px/18px Poppins",
            },
            letterSpacing: "0px",
            color: "#0B3558",
            marginTop: "48px",
            paddingLeft: { lg: "111px", md: "111px", sm: "32px", xs: "20px" },
            paddingRight: { lg: "111px", md: "111px", sm: "32px", xs: "20px" },
          }}
        >
          We will retain your personal information as needed to fulfill the
          purposes for which it was collected. We will retain and use your
          personal information as necessary to comply with our business
          requirements, legal obligations, resolve disputes, protect our assets,
          and enforce our agreements.
        </Typography>
      </Box>
      <Box
        marginTop="48px"
      // paddingLeft={{ lg: "111px" }}
      // paddingRight={{ lg: "111px" }}
      >
        <Typography
          sx={{
            textAlign: "left",
            font: {
              lg: "normal normal 600 32px/48px Poppins",
              sm: "normal normal 600 24px/35px Poppins",
              md: "normal normal 600 32px/48px Poppins",
              xs: "normal normal 600 16px/25px Poppins",
            },
            letterSpacing: "0px",
            color: "#0B3558",
            opacity: "1",
            marginBottom: { lg: "-30px", xs: "-25px" },
            paddingLeft: { lg: "111px", md: "111px", sm: "32px", xs: "20px" },
            paddingRight: { lg: "111px", md: "111px", sm: "32px", xs: "20px" },
          }}
        >
          Information Security
        </Typography>
        <Typography
          sx={{
            textAlign: "left",
            alignItems: "left",
            font: {
              lg: "normal normal normal 16px/25px Poppins",
              sm: "normal normal normal 16px/25px Poppins",
              md: "normal normal normal 16px/25px Poppins",
              xs: "normal normal normal 12px/18px Poppins",
            },
            letterSpacing: "0px",
            color: "#0B3558",
            marginTop: "48px",
            paddingLeft: { lg: "111px", md: "111px", sm: "32px", xs: "20px" },
            paddingRight: { lg: "111px", md: "111px", sm: "32px", xs: "20px" },
          }}
        >
          We take appropriate security measures in accordance with the
          commercial best industry practices to protect against unauthorised
          access to or unauthorised alteration, disclosure or destruction of
          data. These include internal reviews of our data collection, storage
          and processing practices and security measures, including appropriate
          encryption and physical security measures to guard against
          unauthorised access to systems where we store personal data. All
          information gathered on the Zaperon Platform is securely stored within
          the controlled database. Access to the servers is password-protected
          and is strictly limited.
        </Typography>
        <Typography
          sx={{
            textAlign: "left",
            alignItems: "left",
            font: {
              lg: "normal normal normal 16px/25px Poppins",
              sm: "normal normal normal 16px/25px Poppins",
              md: "normal normal normal 16px/25px Poppins",
              xs: "normal normal normal 12px/18px Poppins",
            },
            letterSpacing: "0px",
            color: "#0B3558",
            marginTop: "22px",
            paddingLeft: { lg: "111px", md: "111px", sm: "32px", xs: "20px" },
            paddingRight: { lg: "111px", md: "111px", sm: "32px", xs: "20px" },
          }}
        >
          Security is a critical priority for Zaperon. We maintain a
          comprehensive information security program that contains
          industry-standard administrative, technical, and physical safeguards
          designed to prevent unauthorized access to Personal Data. However, no
          security system is perfect, and due to the inherent nature of the
          Internet, we cannot guarantee that data, including Personal Data, is
          absolutely safe from intrusion or other unauthorized access by others.
          You are responsible for protecting your password(s) and other
          authentication factors, as well as maintaining the security of your
          devices.
        </Typography>
      </Box>
      <Box
        marginTop="48px"
      // paddingLeft={{ lg: "111px" }}
      // paddingRight={{ lg: "111px" }}
      >
        <Typography
          sx={{
            textAlign: "left",
            font: {
              lg: "normal normal 600 32px/48px Poppins",
              sm: "normal normal 600 24px/35px Poppins",
              md: "normal normal 600 32px/48px Poppins",
              xs: "normal normal 600 16px/25px Poppins",
            },
            letterSpacing: "0px",
            color: "#0B3558",
            opacity: "1",
            marginBottom: { lg: "-30px", xs: "-25px" },
            paddingLeft: { lg: "111px", md: "111px", sm: "32px", xs: "20px" },
            paddingRight: { lg: "111px", md: "111px", sm: "32px", xs: "20px" },
          }}
        >
          What law governs this Privacy Policy?
        </Typography>
        <Typography
          sx={{
            textAlign: "left",
            alignItems: "left",
            font: {
              lg: "normal normal normal 16px/25px Poppins",
              sm: "normal normal normal 16px/25px Poppins",
              md: "normal normal normal 16px/25px Poppins",
              xs: "normal normal normal 12px/18px Poppins",
            },
            letterSpacing: "0px",
            color: "#0B3558",
            marginTop: "48px",
            paddingLeft: { lg: "111px", md: "111px", sm: "32px", xs: "20px" },
            paddingRight: { lg: "111px", md: "111px", sm: "32px", xs: "20px" },
          }}
        >
          This Site is controlled and operated by FinLock Technologies from
          India. The Company strives to comply with all applicable laws
          worldwide that are designed to protect your privacy. Although legal
          requirements may vary by jurisdictions, Company intends to adhere to
          the principles set forth in this Privacy Statement even if we process
          your personal Information from your country to countries that may not
          require an “adequate” level of protection for your personal
          Information.
        </Typography>
      </Box>
      <Box
        marginTop="48px"
      // paddingLeft={{ lg: "111px" }}
      // paddingRight={{ lg: "111px" }}
      >
        <Typography
          sx={{
            textAlign: "left",
            font: {
              lg: "normal normal 600 32px/48px Poppins",
              sm: "normal normal 600 24px/35px Poppins",
              md: "normal normal 600 32px/48px Poppins",
              xs: "normal normal 600 16px/25px Poppins",
            },
            letterSpacing: "0px",
            color: "#0B3558",
            opacity: "1",
            marginBottom: { lg: "-30px", xs: "-25px" },
            paddingLeft: { lg: "111px", md: "111px", sm: "32px", xs: "20px" },
            paddingRight: { lg: "111px", md: "111px", sm: "32px", xs: "20px" },
          }}
        >
          Changes to Privacy Policy
        </Typography>
        <Typography
          sx={{
            textAlign: "left",
            alignItems: "left",
            font: "normal normal normal 16px/25px Poppins",
            font: {
              lg: "normal normal normal 16px/25px Poppins",
              sm: "normal normal normal 16px/25px Poppins",
              md: "normal normal normal 16px/25px Poppins",
              xs: "normal normal normal 12px/18px Poppins",
            },
            letterSpacing: "0px",
            color: "#0B3558",
            marginTop: "48px",
            paddingLeft: { lg: "111px", md: "111px", sm: "32px", xs: "20px" },
            paddingRight: { lg: "111px", md: "111px", sm: "32px", xs: "20px" },
          }}
        >
          We may update and/ or modify this Policy from time to time to
          incorporate necessary changes in technology, applicable law, or any
          other variant. In any case, we reserve the right to change (at any
          point of time) the terms of this Policy or the Terms of Use. Any
          changes We make will be effective immediately, upon posting the new
          policy on our Zaperon Platform. While we will make reasonable efforts
          to keep you posted on any updates to this privacy policy, to make sure
          that you are aware of any changes, we advise you to review this policy
          periodically for the latest version, continued use of the sites or
          services, after such notice, will be deemed acceptance of such
          changes.
        </Typography>
      </Box>
      <Box
        marginTop="48px"
      // paddingLeft={{ lg: "111px" }}
      // paddingRight={{ lg: "111px" }}
      >
        <Typography
          sx={{
            textAlign: "left",
            font: {
              lg: "normal normal 600 32px/48px Poppins",
              sm: "normal normal 600 24px/35px Poppins",
              md: "normal normal 600 32px/48px Poppins",
              xs: "normal normal 600 16px/25px Poppins",
            },
            letterSpacing: "0px",
            color: "#0B3558",
            opacity: "1",
            marginBottom: { lg: "-30px", xs: "-25px" },
            paddingLeft: { lg: "111px", md: "111px", sm: "32px", xs: "20px" },
            paddingRight: { lg: "111px", md: "111px", sm: "32px", xs: "20px" },
          }}
        >
          Questions / Grievance Redressal
        </Typography>
        <Typography
          sx={{
            textAlign: "left",
            alignItems: "left",
            font: {
              lg: "normal normal normal 16px/25px Poppins",
              sm: "normal normal normal 16px/25px Poppins",
              md: "normal normal normal 16px/25px Poppins",
              xs: "normal normal normal 12px/18px Poppins",
            },
            letterSpacing: "0px",
            color: "#0B3558",
            marginTop: "48px",
            marginBottom: "48px",
            paddingLeft: { lg: "111px", md: "111px", sm: "32px", xs: "20px" },
            paddingRight: { lg: "111px", md: "111px", sm: "32px", xs: "20px" },
          }}
        >
          In the event you have any grievance relating to the processing of
          information provided by you, you may contact our Grievance Department,
          at <a href="#">support@finlock.in</a> or call us at +91 8800170387.
        </Typography>
      </Box>
      <Scroll_divider />
      <Footer />
    </div>
  );
};

export default PrivacyPolicy;
