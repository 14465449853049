import React from 'react';

import LinkedInIcon from "@mui/icons-material/LinkedIn";

const LinkedInShareButton = ({ url, title }) => {
    const linkedinUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(url)}`;

    return (
        <a
            href={linkedinUrl}
            target="_blank"
            rel="noopener noreferrer"
        >
            <p style={{ display: "flex", marginLeft: "10px" }}>
                <LinkedInIcon className="hovers" sx={{height:"23px", width:"23px", color:"#003FB9"}} color='#003FB9' />
            </p>
        </a>
    );
};

export default LinkedInShareButton;
