import { Box } from '@mui/material';
import React, { useEffect } from 'react'
import { Link } from 'react-router-dom';
import "./HeaderPages.css";
import banking from "../img/webp/img_industry_banking_finance.webp"
import manufactoring from "../img/webp/img_industry_manufacturing.webp"
import tech from "../img/webp/img_industry_technology.webp"
import health from "../img/webp/img_industry_healthcare.webp"
import retail from "../img/webp/img_industry_retail.webp"
import gov from "../img/webp/img_industry_government.webp"
import usPhishing from "../img/webp/img_usecase_phishing.webp"
import usSra from "../img/webp/img_usecase_secure_remote_access.webp"
import usDlp from "../img/webp/img_usecase_data_loss_prevention.webp"
import usTam from "../img/webp/img_usecase_threat_mitigation.webp"
import usSsa from "../img/webp/img_usecase_secure_server_access.webp"
import usComp from "../img/webp/img_usecase_compliance.webp"
import Scroll_divider from '../sections/Scroll_divider';
import Footer from '../sections/Footer';

const Usecases = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])


    const Card = ({ img, h, p }) => {
        return (
            <Box
                style={{
                    width: "348px",
                    height: "354px",
                    backgroundColor: "#FFFFFF",
                    borderRadius: "8px",
                    marginBottom: "48px",
                    cursor: "pointer",
                    border: "1px solid #CEDCFF",
                }}
            >
                <img
                    src={img}
                    alt="logo"
                    style={{ width: "347px", height: "170px" }}
                />
                <p
                    className=""
                    style={{
                        font: "normal normal 600 20px/30px Poppins",
                        color: "#0B3558",
                        marginTop: "24px",
                        width: "300px",
                        marginLeft: "24px",
                    }}
                >
                    {h}
                </p>
                <p
                    className=""
                    style={{
                        font: "normal normal normal 16px/25px Poppins",
                        color: "#0B3558",
                        marginLeft: "24px",
                    }}
                >
                    {p}
                </p>
            </Box>
        );
    };

    const Card2 = ({ img, h, p, link, linkPara }) => {
        return (
            <Box
                sx={{
                    width: "348px",
                    height: "450px",
                    backgroundColor: "transparent",
                    marginBottom: "48px",
                    textAlign: {
                        md: "left",
                        sm: "center",
                        xs: "center"
                    }
                }}
            >
                <img
                    src={img}
                    alt="logo"
                    style={{ width: "250px", height: "250px" }}
                />
                <p
                    className=""
                    style={{
                        font: "normal normal 600 20px/30px Poppins",
                        color: "#0B3558",
                        marginTop: "24px",
                        width: "300px",
                        // marginLeft: "24px",
                    }}
                >
                    {h}
                </p>
                <p
                    className=""
                    style={{
                        font: "normal normal normal 16px/25px Poppins",
                        color: "#0B3558",
                        // marginLeft: "24px",
                    }}
                >
                    {p}
                </p>
                <Link to={link} style={{ color: "#003FB9", font: "normal normal 600 16px/25px Poppins", marginTop: "16px" }}>
                    {linkPara}
                </Link>
            </Box>
        );
    };

    return (
        <Box style={{ marginTop: "80px" }}>
            <Box
                className="ucHero"
                sx={{
                    height: "581px",
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    textAlign: "center",
                }}
            >
                <Box
                    sx={{
                        font: {
                            lg: "normal normal bold 48px/72px Poppins",
                            md: "normal normal bold 20px/30px Poppins",
                            sm: "normal normal bold 16px/25px Poppins",
                            xs: "normal normal bold 16px/25px Poppins"
                        },
                        maxWidth: "1036px",
                        color: "#FFFFFF",
                    }}
                >
                    Secure Remote Access for your workforce from any device, anywhere
                </Box>
                <Box
                    sx={{
                        font: {
                            lg: "normal normal normal 20px/30px Poppins",
                            md: "normal normal normal 14px/21px Poppins",
                            sm: "normal normal normal 14px/21px Poppins"
                        },
                        // font: "normal normal normal 20px/30px Poppins",
                        color: "#FFFFFF",
                        width: {
                            lg: "722px",
                            md: "400px",
                            sm: "328px",
                            xs: "328px"
                        },
                        marginTop: "24px",
                        marginBottom: "48px",
                    }}
                >
                    Zero Trust security that makes access to your applications, data and cloud secure and convenient for your employees and contractors
                </Box>
                <Box
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        color: "#FFFFFF",
                        borderRadius: "4px",
                    }}
                    sx={{
                        font: {
                            md: "normal normal 600 20px/30px Poppins",
                            sm: "normal normal 600 20px/30px Poppins",
                            xs: "normal normal 600 16px/25px Poppins"
                        },
                        width: {
                            md: "277px",
                            sm: "277px",
                            xs: "191px"
                        },
                        height: {
                            md: "52px",
                            sm: "52px",
                            xs: "48px"
                        },
                        background: "#003FB9",
                        cursor: "pointer",
                        ":hover": {
                            background: "#35A3DA",
                        },
                    }}
                >
                    Implement Today
                </Box>
            </Box>

            <div className='ucUses'>
                <Box sx={{
                    paddingLeft: {
                        md: "113px",
                        sm: "16",
                        xs: "16"
                    },
                    paddingRight: {
                        md: "113px",
                        sm: "16",
                        xs: "16"
                    }
                }}>
                    <Box sx={{
                        display: "flex",
                        flexDirection: {
                            md: "row",
                            sm: "column",
                            xs: "column"
                        },
                        justifyContent: "center",
                        marginTop: "100px",
                        marginBottom: "85px",
                        gap: {
                            md: "165px",
                            sm: "18px",
                            xs: "18px"
                        },
                        textAlign: {
                            md: "left",
                            sm: "center",
                            xs: "center"
                        }
                    }}>
                        <Box sx={{
                            maxWidth: "471px", font: {
                                md: "normal normal bold 32px/48px Poppins",
                                sm: "normal normal bold 16px/25px Poppins",
                                xs: "normal normal bold 16px/25px Poppins"
                            }, color: "#003FB9"
                        }}>
                            Security that Meets Every Industry Needs
                        </Box>
                        <Box sx={{
                            maxWidth: "580px", font: {
                                md: "normal normal normal 20px/30px Poppins",
                                sm: "normal normal normal 15px/21px Poppins",
                                xs: "normal normal normal 15px/21px Poppins"
                            }, color: "#0B3558", paddingX: "10px"
                        }}>
                            Tailored for different industries, Zaperon Zero Trust Security seamlessly integrates with all your applications and secures from unauthorized access
                        </Box>
                    </Box>

                    <Box>
                        <Box
                            sx={{
                                width: "100%",
                                display: "grid",
                                gridTemplateColumns: {
                                    md: "auto auto auto",
                                    sm: "auto auto",
                                    xs: "auto"
                                },
                                justifyItems: "center",
                                alignItems: "center",
                            }}
                        >
                            <Card
                                img={banking}
                                h={"Banking & Financial Services"}
                                p={
                                    "Comply to regulations mandating secure access to sensitive data from employees, contractors and third-party vendors"
                                }
                            />

                            <Card
                                img={manufactoring}
                                h={"Manufacturing"}
                                p={
                                    "Enable secure remote access to on-premise and cloud application from any location"
                                }
                            />

                            <Card
                                img={tech}
                                h={"Technology"}
                                p={
                                    "Prevent data leaks and improve workforce productivity at cloud-first tech firms"
                                }
                            />

                            <Card
                                img={health}
                                h={"Healthcare"}
                                p={
                                    "Secure confidential patient data access from unauthorized users and devices"
                                }
                            />

                            <Card
                                img={retail}
                                h={"Retail"}
                                p={
                                    "Improve security and streamline IT operations at online or offline retail chains"
                                }
                            />

                            <Card
                                img={gov}
                                h={"Government"}
                                p={
                                    "Protect national critical data on private network at public sector organizations"
                                }
                            />
                        </Box>
                    </Box>
                </Box>
            </div>

            <div style={{ background: "#EEF3FF" }}>
                <Box sx={{
                    paddingLeft: {
                        md: "113px",
                        sm: "16px",
                        xs: "16px"
                    },
                    paddingRight: {
                        md: "113px",
                        sm: "16px",
                        xs: "16px"
                    },
                    paddingTop: "100px"
                }}>
                    <Box sx={{
                        maxWidth: "884px", font: {
                            md: "normal normal bold 32px/48px Poppins",
                            sm: "normal normal bold 16px/25px Poppins",
                            xs: "normal normal bold 16px/25px Poppins"
                        }, color: "#003FB9",
                        textAlign: {
                            md: "left",
                            sm: "center",
                            xs: "center"
                        }
                    }}>
                        Enable Zero Trust Security for your Business
                    </Box>
                    <Box sx={{
                        maxWidth: "861px", font: {
                            md: "normal normal normal 20px/30px Poppins",
                            sm: "normal normal normal 15px/21px Poppins",
                            xs: "normal normal normal 15px/21px Poppins"
                        }, color: "#0B3558", paddingX: "0px",
                        textAlign: {
                            md: "left",
                            sm: "center",
                            xs: "center"
                        }
                    }}>
                        Explore security use cases that are relevant for your organization
                    </Box>
                    {/* <div style={{ width: "884px", font: "normal normal bold 32px/48px Poppins", color: "#003FB9" }}>
                    </div>
                    <div style={{ width: "861px", font: "normal normal normal 20px/30px Poppins", color: "#0B3558", marginTop: "16px" }}>
                    </div> */}

                    <Box style={{ marginTop: "48px" }}>
                        <Box
                            sx={{
                                width: "100%",
                                display: "grid",
                                gridTemplateColumns: {
                                    md: "auto auto auto",
                                    sm: "auto auto",
                                    xs: "auto"
                                },
                                justifyItems: "center",
                                alignItems: "center",
                            }}
                        >
                            <Card2
                                img={usPhishing}
                                h={'Phishing Prevention'}
                                p={'Defend against cyber traps with Zaperons robust measures, shielding your organization from phishing threats.'}
                                link={''}
                                linkPara={'Phishing Prevention by Zaperon'}
                            />

                            <Card2
                                img={usSra}
                                h={'Secure Remote Access'}
                                p={'Zaperon ensures secure, seamless remote connections, eliminating risks associated with traditional VPNs.'}
                                link={''}
                                linkPara={'Explore Zaperon Secure Access'}
                            />

                            <Card2
                                img={usDlp}
                                h={'Data Loss Prevention'}
                                p={'Safeguard your critical data with Zaperons comprehensive measures to prevent unauthorized data leaks.'}
                                link={''}
                                linkPara={'Start Securing Your Data'}
                            />

                            <Card2
                                img={usTam}
                                h={'Insider Threat Mitigation'}
                                p={'Identify and neutralize insider threats, bolstering your defense against internal security risks.'}
                                link={''}
                                linkPara={'Zaperon Advance Threat Protection'}
                            />

                            <Card2
                                img={usSsa}
                                h={'Secure Server Access'}
                                p={'Zaperon fortifies server access, ensuring ironclad security and control over critical infrastructure.'}
                                link={''}
                                linkPara={'Learn Server Access Security'}
                            />

                            <Card2
                                img={usComp}
                                h={'Regulatory Compliance'}
                                p={'Navigate regulatory landscapes confidently, ensuring your security measures align with industry standards.'}
                                link={''}
                                linkPara={'Advance Monitoring and checks'}
                            />
                        </Box>
                    </Box>
                </Box>
            </div>

            <div>
                <div
                    className="tile"
                    style={{
                        width: "1140px",
                        height: "173px",
                        background:
                            "transparent linear-gradient(97deg, #804A9C 0%, #003FB9 100%) 0% 0% no-repeat padding-box",
                        borderRadius: "16px",
                        margin: "auto",
                        marginTop: "64px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <Box sx={{
                        paddingX:{
                            md:"0px",
                            sm:"8px",
                            xs:"8px"
                          }
                    }}>
                        <h2
                            style={{
                                font: "normal normal bold 32px/48px Poppins",
                                color: "#FFFFFF",
                            }}
                        >
                            Boost Your Organization Security Today
                        </h2>
                        <p
                            style={{
                                font: "normal normal normal 20px/30px Poppins",
                                color: "#FFFFFF",
                            }}
                        >
                            We have said enough. Ready to see it in action?
                        </p>
                    </Box>
                    <Box sx={{
                        marginLeft: {
                            md: "150px",
                            sm: "0px",
                            xs: "0px"
                        },
                    }}>
                        <Link to={"/contact"} style={{ textDecoration: "none" }}>
                            <Box
                                style={{}}
                                sx={{
                                    backgroundColor: "#FFFFFF",
                                    borderRadius: "4px",
                                    height: "52px",
                                    width: "234px",
                                    color: "#003FB9",
                                    font: "normal normal 600 20px/30px Poppins",
                                    border: "none",
                                    textDecoration: "none",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    ":hover": {
                                        textDecoration: "none",
                                        background: "#35A3DA",
                                        color: "#FFFFFF",
                                    },
                                }}
                            >
                                Book a Demo
                            </Box>
                        </Link>
                    </Box>
                </div>

                <div style={{ marginTop: "32px" }}>
                {/* eslint-disable-next-line */}
                    <Scroll_divider className="scroll" />
                </div>
                <div style={{}}>
                    <Footer />
                </div>
            </div>
        </Box>
    )
}
export default Usecases;