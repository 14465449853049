import React, { useState } from "react";
import "./App.css";
import Home from "./Home";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import PageNotFound from "./PageNotFound";
import ThankYou from "./ThankYou";
import Contact from "./sections/Contact";
import Header from "./ui/Header";
import PrivacyPolicy from "./sections/PrivacyPolicy";
import TermsCondition from "./sections/TermsCondition";
import Blog from "./Blogpost";
// eslint-disable-next-line
import Postdetail from "./Postdetail";
// eslint-disable-next-line
import Post2detail from "./Post2detail";
// eslint-disable-next-line
import Blog3Detail from "./Blog3Detail";
// eslint-disable-next-line
import Blog4Detail from "./Blog4Detail";
import BlogList from "./BlogList";
import BlogDetail from "./BlogDetail";
import PartnerPage from "./Partner/PartnerPage";
import DownloadWindows from "./DownloadPages/DownloadWindows";
import ReactGA from "react-ga";
import { useEffect } from "react";
import AccountVerified from "./AccountVerified";
import LinkExpired from "./LinkExpired";
import DownloadPage from "./DownloadPages/DownloadPage";
import VerifyStatus from "./VerifyStatus";
import CheckUserStatus from "./DownloadPages/CheckUserStatus";
import KeyCapabilities from "./HeaderPages/KeyCapabilities";
import Usecases from "./HeaderPages/Usecases";
import { ThemeProvider, createTheme } from "@mui/material";

const TRACKING_ID = process.env.REACT_APP_TRACKING_ID;

ReactGA.initialize(TRACKING_ID);

export default function App() {
  const [showHeader, setShowHeader] = useState(true);
  const [scrollY] = useState(0);

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);
  const theme = createTheme({
   typography: {
    fontFamily: "Poppins",
   },
   palette: {
    primary: {
      main: "#003FB9",
    },
    secondary: {
      main: "#0B3558",
    },
    text: {
      primary: "#0B3558",
      secondary: "#6E6F70"
    }
   }
  });
  return (


    <BrowserRouter>
<ThemeProvider theme={theme}>
      {showHeader && <Header scrollY={scrollY} />}
      <div id="my-scrollbar">
        <Routes>
          <Route path="/" element={<Home headerToggler={() => setShowHeader(!showHeader)} />} />
          <Route path="thanks" element={<ThankYou />} />
          <Route path="contact" element={<Contact />} />
          <Route path="privacyPolicy" element={<PrivacyPolicy />} />
          <Route path="terms-conditions" element={<TermsCondition />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/blogpost" element={<Navigate to="/blog" replace />} />
          <Route path="/postdetails" element={<Navigate to="/blog" replace />} />
          <Route path="/getBlogDetails" element={<BlogDetail />} />
          <Route path="/blog/*" element={<BlogDetail />} />
          <Route path="/keyCapabilities" element={<KeyCapabilities />}/>
          <Route path="/useCases" element={<Usecases />}/>
          {/* <Route path="/blog/what-is-zero-trust-security" element={<Postdetail />} />
          <Route path="/blog/Why-companies-should-adopt-zero-trust-security?" element={<Post2detail />} />
          <Route path="/blog/use-of-passwords-make-companies-vulnerable-to-cyberattacks" element={<Blog3Detail />} />
          <Route path="/blog/cybersecurity-solutions-working-in-silos" element={<Blog4Detail />} /> */}

          {/* Partner Page */}

          <Route path="/partners" element={<PartnerPage />} />

          {/* Account Verified */}

          <Route path="/activation/confirmed" element={<AccountVerified />} />

          <Route path="/activation/confirmed/*" element={<CheckUserStatus />} />

          {/* Activation Status */}

          <Route path="/activation/*" element={<VerifyStatus />} />


          {/* Link Expired */}

          <Route path="/linkExpired" element={<LinkExpired />} />

          {/* Download Pages */}

          <Route path="/downloadClientApp" element={<DownloadPage />} />

          <Route path="/download-app-windows" element={<DownloadWindows />} />

          {/* Testing Route */}
          <Route path="/blogList" element={<BlogList />} />

          {/* 404 Page */}

          <Route path="*" element={<PageNotFound />} />
        </Routes>

      </div>
      </ThemeProvider>
    </BrowserRouter>
  );
}
