import React from 'react'
// import Header from '../ui/Header2';
import AppLogo from '../img/ic_client_app_logo.png'
import AppVerifyLogo from '../img/ic_verify_app_logo.png'
import { Link } from 'react-router-dom';
import Footer from '../sections/Footer';

import winLogo from "../img/ic_windows.png"
import appStoreLogo from "../img/ic_appstore.png"
import playStoreLogo from "../img/ic_playstore.png"

const DownloadWindows = () => {
    return (
        <div>
            {/* <Header /> */}
            <div style={{ marginTop: "80px", marginLeft: "auto", marginRight: "auto" }}>
                <div style={{ height: "760px", width: "100vw", backgroundColor: "#EEF3FF", display: "flex", flexDirection: "column", alignItems: "center" }}>
                    <h1 style={{ font: "normal normal bold 48px/72px Poppins", color: "#003FB9", marginTop: "48px" }}>Best Zero Trust Security For All!</h1>
                    <p style={{ font: "normal normal normal 20px/30px Poppins", maxWidth: "650px", textAlign: "center", color: "#0B3558" }}>Zaperon Zero Trust client connector app use to register your device for access to resources within your organization.</p>
                    <div style={{ display: "flex", marginTop:"24px" }}>
                        <div style={{ width: "495px", height: "376px", backgroundColor: "#FFFFFF", marginRight: "48px", borderRadius:"16px" }}>
                            <img src={AppLogo} alt='app logo' style={{ display: "flex", margin: "auto", marginTop: "48px" }} />
                            <p style={{ font: "normal normal 600 20px/30px Poppins", color: "#0B3558", textAlign: "center", marginTop: "16px" }}>Zaperon Client Connector</p>
                            <div style={{ height: "60px", width: "271px", backgroundColor: "#003FB9", borderRadius: "4px", margin: "auto", display: "flex", cursor: "pointer", alignItems: "center" }}>
                                <div style={{ borderRight: "0.1px solid #4075DC", paddingRight: "12px", marginLeft: "24px" }}>
                                    <img src={winLogo} alt='Windows Logo' />
                                </div>
                                <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", marginLeft: "12px" }}>
                                    <p style={{ font: "normal normal normal 8px/12px Poppins", color: "#FFFFFF", position: "relative", top: "17px" }}>DOWNLOAD</p>
                                    <p style={{ font: "normal normal 600 16px/25px Poppins", color: "#FFFFFF" }}>Client Connector App</p>
                                </div>
                            </div>
                            <p style={{ width: "182px", font: "normal normal normal 16px/25px Poppins", color: "#0B3558", textAlign: "center", margin: "auto", marginTop: "12px" }}>Supports Windows 10+ [X64/X86]</p>
                        </div>
                        <div style={{ width: "495px", height: "376px", backgroundColor: "#FFFFFF", borderRadius:"16px" }}>
                            <img src={AppVerifyLogo} alt='Verify logo' style={{ display: "flex", margin: "auto", marginTop: "48px" }} />
                            <p style={{ font: "normal normal 600 20px/30px Poppins", color: "#0B3558", textAlign: "center", marginTop: "16px" }}>Zaperon Verify App</p>

                            <div style={{ display: "flex", justifyContent: "center" }}>

                                <div style={{ height: "60px", width: "196px", backgroundColor: "#0B3558", borderRadius: "4px", marginRight: "7px", display: "flex", cursor: "pointer", alignItems: "center" }}>
                                    <div style={{ borderRight: "0.1px solid #2E516E", paddingRight: "12px", marginLeft: "24px" }}>
                                        <img src={appStoreLogo} alt='Windows Logo' />
                                    </div>
                                    <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", marginLeft: "12px" }}>
                                        <p style={{ font: "normal normal normal 8px/12px Poppins", color: "#FFFFFF", position: "relative", top: "17px" }}>DOWNLOAD ON THE</p>
                                        <p style={{ font: "normal normal 600 16px/25px Poppins", color: "#FFFFFF" }}>App Store</p>
                                    </div>
                                </div>

                                <div style={{ height: "60px", width: "196px", backgroundColor: "#0B3558", borderRadius: "4px", display: "flex", cursor: "pointer", alignItems:"center" }}>
                                    <div style={{ borderRight: "0.1px solid #2E516E", paddingRight: "12px", marginLeft: "24px" }}>
                                        <img src={playStoreLogo} alt='Windows Logo' />
                                    </div>
                                    <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", marginLeft: "12px" }}>
                                        <p style={{ font: "normal normal normal 8px/12px Poppins", color: "#FFFFFF", position: "relative", top: "17px" }}>GET IT ON</p>
                                        <p style={{ font: "normal normal 600 16px/25px Poppins", color: "#FFFFFF" }}>Google Play</p>
                                    </div>
                                </div>
                            </div>
                            <p style={{ width: "182px", font: "normal normal normal 16px/25px Poppins", color: "#0B3558", textAlign: "center", margin: "auto", marginTop: "12px" }}>Supports Windows 10+ [X64/X86]</p>
                        </div>
                    </div>
                    <p style={{ marginTop: "48px" }}>By downloading and using Zaperon Apps, you agree to the <span style={{ font: " normal normal 600 16px/25px Poppins", color: "#003FB9" }}>
                        <Link to="/terms-conditions">
                            Terms
                        </Link>
                    </span>
                        &
                        <span style={{ font: " normal normal 600 16px/25px Poppins", color: "#003FB9" }}>
                            <Link to="/privacyPolicy">
                                Privacy Policy
                            </Link>
                        </span>..</p>
                </div>


            </div>
            <div style={{ marginTop: "80px" }}>
                <Footer />
            </div>
        </div>
    )
}

export default DownloadWindows;