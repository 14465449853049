/* eslint-disable */
import React, { useEffect } from "react";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import "./css/Hero.css";
import { Box, Container, Grid, Stack } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import { useCallback } from "react";
import TextTransition, { presets } from 'react-text-transition';
function Hero(props) {

  const modal = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "80%",
    height: "70%",
    bgcolor: "background.paper",
    boxShadow: 24,
    // p: 4,
    borderRadius: 3,
    background: "transparent"
  };
  const navigate = useNavigate()
  const [playVideo, setPlayVideo] = useState(false);
  const [playBtnHover, setPlayBtnHover] = useState(false);
  const [showParticles, setShowParticles] = useState(true);
  const [scrolly, setscrolly] = useState(0);
  const TEXTS = ['Humans', 'Machines', 'Applications', 'Cloud Infrastructure']
  const toggleVideo = () => {
    setPlayVideo(!playVideo)
  }

  const particlesInit = useCallback(async engine => {
    // console.log(engine);
    // you can initiate the tsParticles instance (engine) here, adding custom shapes or presets
    // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
    // starting from v2 you can add only the features you need reducing the bundle size
    //await loadFull(engine);
    // await loadSlim(engine);
  }, []);

  const particlesLoaded = useCallback(async container => {
    // console.log(container);
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      // console.log(window.scrollY)
      setscrolly(window.scrollY);
      if (window.scrollY > 100) {
        setShowParticles(false);
      } else {
        setShowParticles(true);
      }
    }
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [])
  // console.log(scrolly)
  // let player;

  // const toggleVideo = () => {
  //   setPlayVideo(!playVideo);
  //   if (!playVideo) {
  //     player = new window.YT.Player('youtube-player', {
  //       videoId: 'DWMG3rG7AGY',
  //       events: {
  //         onReady: onPlayerReady,
  //       },
  //     });
  //   } else {
  //     // Pause the video when the content is shown
  //     player.pauseVideo();
  //   }
  // };

  // const onPlayerReady = (event) => {
  //   event.target.playVideo();
  // };

  // const toggleVideo = () => {
  //   setPlayVideo(!playVideo)
  //   props.headerToggler();
  // }
  const [index, setIndex] = React.useState(0);

  React.useEffect(() => {
    const intervalId = setInterval(
      () => setIndex((index) => index + 1),
      2000,
    );
    return () => clearTimeout(intervalId);
  }, []);
  return (
    <div className="hero-full">
      <Container>
        <Box
          minHeight={{md: "100vh", sm: "1000px", xs: "557px"}}
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >

          <Grid container sx={{flex: 1, display: "flex", flexDirection: "column", justifyContent: {xs: "end", xl: "center"}, marginBottom: "80px"}}>

            <Grid item lg={8} md={12} xs={12}>
              <Stack spacing={"24px"}>
                <Typography
                  variant="h1"
                  sx={{
                    textAlign: 'left',
                    width: {md:"500px", sm: "700px"},
                    paddingRight: "16px",
                    font: {
                      lg: "normal normal 600 56px/120% Poppins",
                      md: "normal normal 600 56px/120% Poppins",
                      sm: "normal normal 600 48px/120% Poppins",
                      xs: "normal normal 600 32px/120% Poppins",
                    },
                    color: "#FFFFFF",
                    mt: { lg: "164px", md: "164px", sm: "164px", xs: "10" },
                  }}
                >
                Most Secure Defense Against Identity Attacks
                </Typography>
                <Typography
                  className="desktop-view"
                  variant="h4"
                  sx={{
                    // fontSize:{xs:"18px",sm:"20px",md:"22px",lg:"30px"},
                    font: {
                      lg: "normal normal normal 24px/120% Poppins",
                      md: "normal normal normal 24px/120% Poppins",
                      sm: "normal normal normal 24px/120% Poppins",
                      xs: "normal normal normal 14px/120% Poppins",
                    },
                    letterSpacing: "0px",
                    width:{lg: "500px", sm: "650px",xs: '250px'},
                    textAlign: { xs: "left", md: "left", lg: "left" },
                    color: "#FFFFFF",
                    opacity: 0.9,
                  }}
                >
                <Box component={'span'} sx={{ font: {
                      lg: "normal normal normal 24px/120% Poppins",
                      md: "normal normal normal 24px/120% Poppins",
                      sm: "normal normal normal 24px/120% Poppins",
                      xs: "normal normal normal 14px/120% Poppins",
                    }}} style={{display: 'flex', margin: 0, flexDirection: "row", fontFamily: "Poppins", overflow:'hidden'}} >{'Secure '} {' '}<TextTransition style={{fontFamily: "Poppins", display: "flex", fontWeight: "600", margin: 0, padding: 0, paddingLeft: "8px"}} delay={"0"} springConfig={presets.wobbly}>{TEXTS[index % TEXTS.length]}</TextTransition><br /></Box>
                with cryptographic identity protection and zero trust
                
                </Typography>
              </Stack>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: { xs: "left", lg: "left" },
                }}
              >
                <Stack
                  direction={{ lg: "row", md: "column" }}
                  alignItems="baseline"
                  justifyContent={{md: "center", xs: "left"}}
                  gap={{ lg: "16px", md: "0px", sm: "0px", xs: "0px" }}
                >
                  <Box sx={{
                    display: "flex",
                    flexDirection: {
                      sm: "row",
                      xs: "column"
                    }
                  }}
                  >
                      <Button
                        key={"get"}
                        onClick={() => {navigate('/contact')}}
                        sx={{
                          height: {
                            lg: "48px",
                            md: "48px",
                            sm: "48px",
                            xs: "48px",
                          },
                          width: 'fit-content',
                          // use this margin value when use watch now button
                          // m: {lg:"48px 1px 0px 0px",md:"48px 1px 0px 0px",sm:"48px 1px 0px 0px",xs:"16px 1px 0px 0px" },
                          m: "32px 1px 0px 0px",
                          marginLeft: { lg: "0px", sm: "0px" },
                          color: "#003FB9",
                          borderRadius: "4px",
                          opacity: 0.9,
                          backgroundColor: "#FFFFFF",
                          display: "block",
                          font: {
                            lg: "normal normal 600 20px/120% Poppins",
                            md: "normal normal 600 20px/120% Poppins",
                            sm: "normal normal 600 20px/120% Poppins",
                            xs: "normal normal 600 16px/120% Poppins",
                          },
                          letterSpacing: 0,
                          padding: "0px 48px",
                          textTransform: "none",
                          boxShadow: "0px 3px 6px #0B35583D",
                          ":hover": {
                            background:"linear-gradient(90deg, #003FB9 0%, #6943FF 100%)",
                            borderRadius: "4px",
                            border: "2px solid #CEDCFF",
                            color: "#FFFFFF",
                          },
                        }}
                      >
                        Get Started
                      </Button>
                  </Box>
                </Stack>
              </Box>
            </Grid>
            
          </Grid>
        </Box>
      </Container>
      )
    </div>
  );
}

export default Hero;
