import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Scrollama, Step } from "react-scrollama";
import assets from "../img/ic_define_assets.svg";
import auth from "../img/ic_authenticate.svg";
import ac from "../img/ic_access_control.svg";
import policies from "../img/ic_policies_1.svg";
import monitor from "../img/ic_monitor_1.svg";
import pass from "../img/ic_passwordless_access.svg";
import mfa from "../img/ic_mfa_1.svg";
import sso from "../img/ic_sso_1.svg";
import cdp from "../img/ic_cdp_1.svg";
import trust from "../img/ic_device_trust_1.svg";
import zeroTrust from "../img/img_implement_zero_trust.svg";
import "./HeaderPages.css";
import { Link } from "react-router-dom";
import Scroll_divider from "../sections/Scroll_divider";
import Footer from "../sections/Footer";

const steps = [
  {
    key: 1,
    img: assets,
    h: "1. Discover Assets & Prioritize",
    p: "Identify network assets and prioritize based on criticality for strategic and effective security management.",
  },
  {
    key: 2,
    img: auth,
    h: "2. Authenticate Users & Devices",
    p: "Ensure secure access with user and device verification through multi-factor authentication.",
  },
  {
    key: 3,
    img: ac,
    h: "3. Define Access Controls",
    p: "Efficiently manage access by mapping users and groups to specific applications for streamlined security.",
  },
  {
    key: 4,
    img: policies,
    h: "4. Define & Enforce Security Policies",
    p: "Enhance security with tailored access policies, aligning user roles and device posture to safeguard against threats.",
  },
  {
    key: 5,
    img: monitor,
    h: "5. Real-time Monitoring & Reports",
    p: "Boost security with real-time alerts and adaptive policies, proactively countering emerging threats for a resilient defense.",
  },
];

const KeyCapabilities = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  // eslint-disable-next-line
  const [currentStepIndex, setCurrentStepIndex] = useState(null);

  const onStepEnter = ({ data }) => {
    setCurrentStepIndex(data);
  };

  const Card = ({ link, img, h, p }) => {
    return (
      <Box
        style={{
          width: "348px",
          height: "272px",
          backgroundColor: "#FFFFFF",
          borderRadius: "8px",
          marginBottom: "48px",
          cursor: "pointer",
          border: "1px solid #CEDCFF",
        }}
        sx={{
          ":hover": {
            transition: "0.5s",
            boxShadow: "0px 48px 48px #0000000F",
            transform: "translateY(-8px)",
          },
        }}
      >
        <Link to={link} style={{ textDecoration: "none", marginLeft: "24px" }}>
          <img
            src={img}
            alt="logo"
            style={{ marginTop: "32px", width: "64px" }}
          />
          <p
            className=""
            style={{
              font: "normal normal 600 20px/30px Poppins",
              color: "#0B3558",
              marginTop: "24px",
              width: "300px",
              marginLeft: "24px",
            }}
          >
            {h}
          </p>
          <p
            className=""
            style={{
              font: "normal normal normal 16px/25px Poppins",
              color: "#0B3558",
              marginLeft: "24px",
            }}
          >
            {p}
          </p>
        </Link>
      </Box>
    );
  };

  return (
    <Box style={{ marginTop: "80px" }}>
      <Box
        className="kcHero"
        sx={{
          height: "581px",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
        }}
      >
        <Box
          sx={{
            font: {
              lg: "normal normal bold 48px/72px Poppins",
              md: "normal normal bold 20px/30px Poppins",
              sm: "normal normal bold 16px/25px Poppins",
              xs: "normal normal bold 16px/25px Poppins"
            },
            maxWidth: "814px",
            color: "#FFFFFF",
          }}
        >
          A Unified Platform to Secure Modern Workplaces
        </Box>
        <Box
          sx={{
            font: {
              lg: "normal normal normal 20px/30px Poppins",
              md: "normal normal normal 14px/21px Poppins",
              sm: "normal normal normal 14px/21px Poppins"
            },
            color: "#FFFFFF",
            width: {
              lg: "722px",
              md: "400px",
              sm: "328px",
              xs: "328px"
            },
            marginTop: "24px",
            marginBottom: "48px",
          }}
        >
          Elevate your organization's security with an award winning solution
          that protects your hybrid workforce and resources.
        </Box>
        <Box
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "#FFFFFF",
            borderRadius: "4px",
          }}
          sx={{
            font: {
              md: "normal normal 600 20px/30px Poppins",
              sm: "normal normal 600 20px/30px Poppins",
              xs: "normal normal 600 16px/25px Poppins"
            },
            width: {
              md: "211px",
              sm: "211px",
              xs: "191px"
            },
            height: {
              md: "52px",
              sm: "52px",
              xs: "48px"
            },
            background: "#003FB9",
            cursor: "pointer",
            ":hover": {
              background: "#35A3DA",
            },
          }}
        >
          Get Started
        </Box>
      </Box>

      <Box sx={{
        background: "white", display: {
          md: "block",
          sm: "none",
          xs: "none"
        }
      }}>
        <div style={{}}>
          <Box
            sx={{
              width: "584px",
              position: "sticky",
              top: "90px",
              marginLeft: "113px",
              marginTop: "169px",
            }}
          >
            <Box
              sx={{
                color: "#0B3558",
                font: "normal normal bold 48px/72px Poppins",
                marginBottom: "32px"
              }}
            >
              Implement <br /> <span style={{ color: "#003FB9" }}>ZERO TRUST SECURITY</span> <br /> in just 5 simple steps
            </Box>
            <Box>
              <img src={zeroTrust} alt="zeroT" />
            </Box>
          </Box>
          <div
            style={{ width: "608px", marginLeft: "auto", marginTop: "-420px" }}
          >
            <Scrollama offset={0.5} onStepEnter={onStepEnter}>
              {steps.map((data, stepIndex) => (
                <Step data={stepIndex} key={stepIndex}>
                  <div
                    style={{
                      margin: "64px 0",
                      width: "100%",
                    }}
                  >
                    <Box style={{ display: "flex", height: "109px" }}>
                      <div style={{}}>
                        <img src={data.img} alt="imh" />
                      </div>
                      <div
                        style={{
                          marginRight: "108px",
                          marginLeft: "48px",
                          width: "380px",
                        }}
                      >
                        <div
                          style={{
                            color: "#0B3558",
                            font: "normal normal 600 20px/30px Poppins",
                          }}
                        >
                          {data.h}
                        </div>
                        <div
                          style={{
                            font: "normal normal normal 16px/25px Poppins",
                            color: "#0B3558",
                          }}
                        >
                          {data.p}
                        </div>
                      </div>
                    </Box>
                  </div>
                </Step>
              ))}
            </Scrollama>
          </div>
        </div>
      </Box>

      <Box sx={{
        display: {
          md: "none",
          sm: "block",
          xs: "block"
        }
      }}>
        <Box
          sx={{
            // width: "584px",
            // position: "sticky",
            // top: "90px",
            // marginLeft: "113px",
            // marginTop: "169px",
          }}
        >
          <Box
            sx={{
              color: "#0B3558",
              font: {
                sm: "normal normal bold 16px/25px Poppins",
                xs: "normal normal bold 16px/25px Poppins"
              },
              marginBottom: "32px",
              textAlign: "center",
              marginTop: "79px"
            }}
          >
            Implement <br /> <span style={{ color: "#003FB9" }}>ZERO TRUST SECURITY</span> <br /> in just 5 simple steps
          </Box>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <img src={zeroTrust} alt="zero" style={{ width: "328px" }} />
          </Box>
          <Box>
            {steps.map((data, stepIndex) => (
              // <Step data={stepIndex} key={stepIndex}>
              <div
                style={{
                  margin: "48px 0",
                  width: "100%",
                }}
              >
                <Box style={{ display: "flex", marginLeft: "32px" }}>
                  <div style={{}}>
                    <img alt="qwe" src={data.img} style={{ width: "64px" }} />
                  </div>
                  <div
                    style={{
                      marginRight: "24px",
                      marginLeft: "24px",
                      width: "380px",
                    }}
                  >
                    <div
                      style={{
                        color: "#0B3558",
                        font: "normal normal 600 14px/21px Poppins",
                      }}
                    >
                      {data.h}
                    </div>
                    <div
                      style={{
                        font: "normal normal normal 12px/18px Poppins",
                        color: "#0B3558",
                      }}
                    >
                      {data.p}
                    </div>
                  </div>
                </Box>
              </div>
              // </Step>
            ))}
          </Box>
        </Box>
      </Box>

      <Box
        style={{
          width: "100%",
          background: "#EEF3FF",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            font: {
              md: "normal normal bold 32px/48px Poppins",
              sm: "normal normal bold 16px/25px Poppins",
              xs: "normal normal bold 16px/25px Poppins"
            },
            color: "#003FB9",
            marginTop: "100px",
            marginBottom: "64px",
          }}
        >
          Remote Access Made Secure and Simple
        </Box>
        <Box
          sx={{
            width: "80%",
            display: "grid",
            gridTemplateColumns: {
              md: "auto auto auto",
              sm: "auto",
              xs: "auto"
            },
            justifyItems: "center",
            alignItems: "center",
          }}
        >
          <Card
            img={pass}
            h={"Passwordless Access"}
            p={
              "Eliminate passwords to access enterprise applications and enhance security and user experience."
            }
          />
          <Card
            img={mfa}
            h={"Multi-factor Authentication"}
            p={
              "Add a layer of security in the authentication process that prevents account takeover."
            }
          />
          <Card
            img={sso}
            h={"Single Sign On (SSO)"}
            p={
              "Access to all your applications (Cloud, SaaS, On-premise) on one-click."
            }
          />
          <Card
            img={policies}
            h={"Zero Trust Access Policies"}
            p={
              "Enforce access policies based on user role and device context to prevent unauthorized access and lateral movement."
            }
          />
          <Card
            img={cdp}
            h={"Cloud Data Protection"}
            p={"Prevent data leak from your cloud applications and emails."}
          />
          <Card
            img={trust}
            h={"Continuous Device Trust"}
            p={
              "Verify identity of the device and continuous security posture check"
            }
          />
        </Box>
      </Box>
      <div>
        <div
          className="tile"
          style={{
            width: "1140px",
            height: "173px",
            background:
              "transparent linear-gradient(97deg, #804A9C 0%, #003FB9 100%) 0% 0% no-repeat padding-box",
            borderRadius: "16px",
            margin: "auto",
            marginTop: "64px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box sx={{
            paddingX: {
              md: "0px",
              sm: "8px",
              xs: "8px"
            }
          }}>
            <h2
              style={{
                font: "normal normal bold 32px/48px Poppins",
                color: "#FFFFFF",
              }}
            >
              Boost Your Organization Security Today
            </h2>
            <p
              style={{
                font: "normal normal normal 20px/30px Poppins",
                color: "#FFFFFF",
              }}
            >
              We have said enough. Ready to see it in action?
            </p>
          </Box>
          <Box sx={{
            marginLeft: {
              md: "150px",
              sm: "0px",
              xs: "0px"
            },
          }}>
            <Link to={"/contact"} style={{ textDecoration: "none" }}>
              <Box
                style={{}}
                sx={{
                  backgroundColor: "#FFFFFF",
                  borderRadius: "4px",
                  height: "52px",
                  width: "234px",
                  color: "#003FB9",
                  font: "normal normal 600 20px/30px Poppins",
                  border: "none",
                  textDecoration: "none",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  ":hover": {
                    textDecoration: "none",
                    background: "#35A3DA",
                    color: "#FFFFFF",
                  },
                }}
              >
                Book a Demo
              </Box>
            </Link>
          </Box>
        </div>

        <div style={{ marginTop: "32px" }}>
        {/* eslint-disable-next-line */}
          <Scroll_divider className="scroll" />
        </div>
        <div style={{}}>
          <Footer />
        </div>
      </div>
    </Box>
  );
};

export default KeyCapabilities;
