import React from 'react'
import "./css/Acheivments.css"
import { Box, Typography } from '@mui/material'
import awardImg from "../img/webp/07-Awards Section/img-award-dsci-aiss-winner.webp";
import aissAward from "../img/ic_aiss_2023_award.svg"

const Acheivments = () => {
    return (
        <div className='ach-full' style={{}}>
          
                <Typography
                    variant="h6"
                    sx={{
                        fontSize: {sm: "48px", xs: "32px"},
                        fontWeight: "600",
                        lineHeight: "120%",
                        color: '#FFFFFF',
                        textAlign: 'center',
                        paddingTop: {sm: "80px", xs: "64px"}, 
                        paddingBottom: {sm: "80px", xs: "48px"}
                    }}
                >
                    Awards and Recognition
                </Typography>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: {
                        lg: "row",
                        md: "column",
                        sm: "column",
                        xs: "column",
                    },
                    justifyContent: "center",
                    alignItems: "center"
                }}
            >
                <Box
                sx={{
                    overflow:"hidden",
                    borderRadius: "16px",
                    display: "flex",
                justifyContent: "center",
                alignItems: "center" // Center the image vertically
                }}
                >

                    <Box
                        component={'img'}
                        src={awardImg}
                        sx={{
                            width: {sm: '560px', xs: "328px"},
                            height: {sm:'450px',xs: "263px"},
                            objectFit: "cover",
                            marginLeft: "auto",
                            marginRight: "auto",
                            borderRadius: "16px",
                            transition:"0.5s",
                            ":hover": {
                                transform: "scale(1.2)",
                            }
                        }}
                    />
                </Box>

                <Box display={'flex'} paddingBottom={{sm: "80px", xs: "0px"}} flexDirection={'column'} alignItems={'center'} sx={{transform: {lg: "translateX(50px)", xs: "translateX(0)"}}}>
                    <Box
                        component={'img'}
                        src={aissAward}
                        sx={{
                            width: {
                                lg: "250px",
                                md: "250px",
                                sm: "250px",
                                xs: "300px",
                            },
                            marginTop: {
                                lg: "0px",
                                md: "80px",
                                sm: "80px",
                                xs: "32px",
                            },

                        }}
                    />
                    <Box sx={{
                        marginLeft: {
                            lg: "45px",
                            md: "45px",
                            sm: "45px",
                            xs: "0px",
                        },
                    }}>
                        <Typography
                            variant="h6"
                            sx={{
                                // m: '0 0 12px 0',
                                font: {
                                    lg: "normal normal normal 20px/30px Poppins",
                                    md: "normal normal normal 20px/30px Poppins",
                                    sm: "normal normal normal 20px/30px Poppins",
                                    xs: "normal normal 400 16px/150% Poppins",
                                },
                                letterSpacing: { lg: '1.6px', md: "1.2px", sm: "1.2px" },
                                color: '#FFFFFF',
                                paddingLeft: "16px",
                                paddingRight: "16px",
                                textAlign: 'center',
                            }}
                        >
                            Annual Information Security Summit 2023
                        </Typography>
                        <Typography
                            variant="h6"
                            sx={{
                                // m: '0 0 12px 0',
                                font: {
                                    lg: "normal normal bold 20px/30px Poppins",
                                    md: "normal normal bold 20px/30px Poppins",
                                    sm: "normal normal bold 20px/30px Poppins",
                                    xs: "normal normal bold 16px/18px Poppins",
                                },
                                letterSpacing: { lg: '1.6px', md: "1.2px", sm: "1.2px" },
                                color: '#FFFFFF',
                                textAlign: 'center',
                                textTransform: 'uppercase'
                            }}
                        >
                            DATA SECURITY COUNCIL OF INDIA
                        </Typography>
                    </Box>
                </Box>

            </Box>
        </div>
    )
}

export default Acheivments