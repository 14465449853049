import React from 'react';
import './VideoBackground.css';
import videoSource from '../img/bg_waves_abtract_theme_trapcode.webm';

import bdImg from '../img/companyLogo/ic_bd_software_logo.png';
import btrackImg from '../img/companyLogo/ic_btrack_logo.png';
import miImg from '../img/companyLogo/ic_mieux_logo.png';
import persImg from '../img/companyLogo/ic_presistent.png';
import g2Img from '../img/companyLogo/ic_g2_logo.png';
import ofoImg from '../img/companyLogo/ic_ofofo_logo.png';
import capImg from '../img/companyLogo/ic_capterra_logo.png';
import techImg from '../img/companyLogo/ic_techjockey_logo.png';
import ssImg from '../img/companyLogo/ic_softwaresuggest_logo.png';
import Card from './Card';

const arr = [
    {
        logo: bdImg,
        name: "BD Software Distribution Pvt. Ltd.",
        country: "India",
        link: "https://bdsoft.in/"
    },
    {
        logo: btrackImg,
        name: "Btrack India Pvt. Ltd.",
        country: "India",
        link: "https://www.btrackindia.com/"
    },
    {
        logo: miImg,
        name: "Mieux Technologies Pvt. Ltd.",
        country: "India",
        link: "https://www.mieuxtechnologies.com/"
    },
    {
        logo: persImg,
        name: "Persistent Systems",
        country: "India",
        link: "https://www.persistent.com/"
    },
    {
        logo: g2Img,
        name: "G2",
        country: "U.S.A",
        link: "https://www.g2.com/"
    },
    {
        logo: ofoImg,
        name: "Ofofo",
        country: "India",
        link: "https://ofofo.io/"
    },
    {
        logo: capImg,
        name: "Capterra",
        country: "U.S.A",
        link: "https://www.capterra.com/"
    },
    {
        logo: techImg,
        name: "Techjockey Infotech Pvt. Ltd.",
        country: "India",
        link: "https://www.techjockey.com/"
    }
]


function VideoBackground() {
    return (
        <div className="video-background">
            <video autoPlay muted loop>
                <source src={videoSource} type="video/mp4" />
                {/* Add additional <source> elements for other formats */}
            </video>
            <div className="content-overlay" style={{ marginLeft: "auto" }}>
                <h2 style={{ font: "normal normal bold 48px/72px Poppins", color: "#003FB9", marginBottom: "48px" }}>Our Channel Partners</h2>
                <div>
                    <div className='vidBg' style={{ display: "grid", gridTemplateColumns: "auto auto auto auto", marginLeft:"30px" }}>
                        {arr.map((e) => (
                            <Card logo={e.logo} name={e.name} country={e.country} link={e.link} />
                        ))}
                    </div>
                </div>
                <div style={{ display: "flex", alignItems: "center", margin:"auto" }}>
                    <Card logo={ssImg} name={"SoftwareSuggest"} country={"U.S.A"} link={"https://www.softwaresuggest.com/"} />
                </div>
            </div>
        </div>
    );
}

export default VideoBackground;
