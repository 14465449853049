import { Box, Button, Container, Stack, Typography } from "@mui/material";
import React from "react";
import "./css/Bookdemo.css";
import requestDemoImg from "../img/webp/09-Request Demo Section/img-request-demo.webp"
import { useNavigate } from "react-router-dom";
function Bookdemo() {
  const navigate = useNavigate()
  return (
    <Box p={{sm: "80px 0px", xs: "64px 0px"}} width={'100%'}>
      <Container>
        <Box
          sx={{
            background:
              "linear-gradient(95deg, #003FB9 0%, #6943FF 98.89%)",
            borderRadius: "12px",
            width: {lg: 1200, xs: "fit-content"}, padding: {xs:"48px 24px", sm: "80px", lg: 0}, marginLeft: "auto", marginRight: "auto", transform: {lg: "translateX(-20px)", xs: "translateX(0)"}
          }}
        >
          <Stack flexDirection={{lg: 'row', xs: "column"}} alignItems={'center'} justifyContent={'center'} borderRadius={'12px'} style={{marginLeft: "auto", marginRight: "auto"}}>
          <Stack flexDirection={'column'} justifyContent={{lg: 'left', xs: "center"}} alignItems={{xs: "center", lg: "start"}} paddingLeft={{lg: '80px', xs: 0}}>
          <Box component={'img'} src={requestDemoImg} sx={{width: {sm: "544px", xs: "267px"}, borderBottomRightRadius: "12px", display:{xs: "block", lg: "none"}, marginLeft: "auto"}} alt="request demo" />
              <Typography
                component={"p"}
                sx={{
                  fontSize: {sm: "48px", xs: "32px"},
                  fontWeight: "600",
                  lineHeight: "120%",
                  color: "#FFF",
                  marginBottom: "32px",
                  width: {sm: "544px", xs: "280px"},
                  textAlign: {xs: "center",lg: "left"},
                  marginTop: {xs: '48px', sm: "80px", lg : 0}
                }}
              >
                Reduce your attack surface with Zaperon
              </Typography>
                <Button
                onClick={() => navigate('/contact')}
                  sx={{
                    backgroundColor: "#FFFFFF",
                    borderRadius: "4px",
                    border: "none",
                    font: {
                      lg: "normal normal 600 20px/30px Poppins;",
                      md: "normal normal 600 24px/35px Poppins",
                      sm: "normal normal 600 16px/25px Poppins",
                      xs: "normal normal 600 16px/25px Poppins",
                    },
                    letterSpacing: "0px",
                    color: "#003FB9",
                    width: {
                      lg: "263px",
                      xs: "100%",
                    },
                    height: { lg: "48px", md: "48px", sm: "48px", xs: "48px" },
                    "&:hover": {
                      background:"linear-gradient(90deg, #003FB9 0%, #6943FF 100%)",
                      borderRadius: "4px",
                      border: "2px solid #CEDCFF",
                      color: "#FFFFFF",
                    },
                    display: "block",
                    textTransform: "none",
                    opacity: 1,
                  }}
                >
                  Request a Demo
                </Button>
  
              </Stack>
          <Box component={'img'} src={requestDemoImg} sx={{width: "460px", height: "286px", display:{xs: "none", lg: "block"}, borderBottomRightRadius: "12px", marginLeft: "auto"}} alt="request demo" />
            </Stack>
        </Box>
      </Container>
    </Box>
  );
}

export default Bookdemo;
