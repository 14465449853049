/* eslint-disable */

import { Box, Container, Stack, Typography } from "@mui/material";
import React from "react";
import "./css/Footer.css";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import logo from "../img/ic_zaperon_logo.svg"
import {ReactComponent as USflag} from '../img/ic_usa.svg'
import {ReactComponent as YouTube} from '../img/youtube.svg'
import {ReactComponent as LinkedInIcon} from '../img/linkedin.svg'

import {ReactComponent as Indiaflag} from '../img/ic_india.svg'
const ProductArr = ["Passwordless Access", "Phishing-resistant MFA", "Identity Lifecycle Management", "Zero Trust Access Policies", "Identity Threat Detection", "Cloud Data Protection", "Continuous Device Trust"]
const UserCasesArr = ["Phishing Prevention", "Secure Remote Access", "Data Loss Prevention", "Insider Threat Mitigation", "Secure Server Access", "Regulatory Compliance"]
function Footer() {
  const navigate = useNavigate()
  var date = new Date();
  return (
    <Box p={"0px 0px"} >
    
      <Box sx={{width: {lg: "1264px", xs: "100%"},marginLeft: "auto", marginRight: "auto"}}>
        <Box
          component={"div"}
          display="flex"
          alignItems="start"
          width={{lg: "1242px", sm: "100%", xs: '90%'}}
          flexDirection={{ lg: "row", md: "row", sm: "column", xs: "column" }}
          gap={{sm: "16px", xs: "0px"}}
          marginBottom={'68px'}
            justifyContent={'center'}
            paddingLeft={{sm:"32px", xs: "16px"}}
        >
          <Box
            display={"flex"}
            flexDirection={"column"}
            flex={1.4}
            alignItems={{ lg: "start", md: "start", sm: "start", xs: "center" }}
          >
          <Box width={'320px'}>
            <Link to="/">
                <Box
                  component={"img"}
                  src={logo}
                  alt={"Z"} 
                  width={{sm: '250px', xs: "150px"}}
                  height={{sm: '125px', xs: "75px"}}
                />
            </Link>
            <Box  marginTop={'0'} >
            <Typography color={'secondary'} marginBottom={{sm: '48px', xs: "32px"}} fontWeight={'400'} fontSize={{sm: '20px', xs: "16px"}} >Prevent Identity-based Attacks with Identity Protection 2.0</Typography>
            <a href='https://www.linkedin.com/company/securewithzaperon/' target='_blank' style={{marginRight: "48px"}}>
              <LinkedInIcon className="svg-icon"  fill="#0B3558" />
            </a>
            <YouTube className="svg-icon" fill="#0B3558" />
          </Box>
          </Box>
          </Box>
          <Box flex={1} width={'fit-content'} display={{lg: 'block', sm: "none"}}>
            <Typography color={'secondary'} fontSize={'16px'} marginTop={{xs: "32px", sm: "0px"}} fontWeight={'600'}>PRODUCT</Typography>
            <Box marginTop={'24px'}>
            {ProductArr.map(item => <Typography color={'text.secondary'} fontSize={'16px'} fontWeight={'400'} marginBottom={'12px'}>{item}</Typography>)}
            </Box>
            <Typography color={'secondary'} fontSize={'16px'} fontWeight={'600'} marginTop={'48px'}>RESOURCES</Typography>
            <Box marginTop={'24px'}>
            {['Blog', 'Documentations'].map(item => <Typography onClick={() => item === 'Blog' ? navigate('/blog'): window.location.href = 'https://docs.zaperon.com'} color={'text.secondary'} fontSize={'16px'} fontWeight={'400'} sx={{cursor: "pointer","&:hover": {color: '#003FB9'}}} marginBottom={'12px'}>{item}</Typography>)}
            </Box>
          </Box>
          <Box flex={1} sx={{marginTop: {xs: "32px", sm: "0"}}}  display={{lg: 'block', sm: "none"}}>
            <Typography color={'secondary'} fontSize={'16px'} fontWeight={'600'}>USECASES</Typography>
            <Box marginTop={'24px'}>
            {UserCasesArr.map(item => <Typography color={'text.secondary'} fontSize={'16px'} fontWeight={'400'} marginBottom={'12px'}>{item}</Typography>)}
            </Box>
            <Typography color={'secondary'} fontSize={'16px'} fontWeight={'600'} marginTop={{sm: '84px', xs: "48px"}}>COMPANY</Typography>
            <Box marginTop={'24px'}>
            {['About', 'Partners'].map(item => <Typography onClick={() => item === 'Partners' ? navigate('/partners'): null} color={'text.secondary'} sx={{cursor: "pointer", "&:hover": {color: '#003FB9'}}} fontSize={'16px'} fontWeight={'400'} marginBottom={'12px'}>{item}</Typography>)}
            </Box>
          </Box>
          <Box flex={1} marginTop={'32px'} display={{sm: 'flex', xs: "none", lg: "none"}}>
          <Box flex={1} width={'fit-content'} minWidth={'max-content'} marginRight={'48px'}>
            <Typography color={'secondary'} fontSize={'16px'} marginTop={{xs: "32px", sm: "0px"}} fontWeight={'600'}>PRODUCT</Typography>
            <Box marginTop={'24px'}>
            {ProductArr.map(item => <Typography color={'text.secondary'} fontSize={'16px'} fontWeight={'400'} marginBottom={'12px'}>{item}</Typography>)}
            </Box>
            <Typography color={'secondary'} fontSize={'16px'} fontWeight={'600'} marginTop={'48px'}>RESOURCES</Typography>
            <Box marginTop={'24px'}>
            {['Blog', 'Documentations'].map(item => <Typography onClick={() => item === 'Blog' ? navigate('/blog'): window.location.href = 'https://docs.zaperon.com'} color={'text.secondary'} fontSize={'16px'} fontWeight={'400'} sx={{cursor: "pointer","&:hover": {color: '#003FB9'}}} marginBottom={'12px'}>{item}</Typography>)}
            </Box>
          </Box>
          <Box flex={1} sx={{marginTop: {xs: "48px", sm: 0}}}>
            <Typography color={'secondary'} fontSize={'16px'} fontWeight={'600'}>USECASES</Typography>
            <Box marginTop={'24px'}>
            {UserCasesArr.map(item => <Typography color={'text.secondary'} fontSize={'16px'} fontWeight={'400'} marginBottom={'12px'}>{item}</Typography>)}
            </Box>
            <Typography color={'secondary'} fontSize={'16px'} fontWeight={'600'} marginTop={{sm: '84px', xs: "48px"}}>COMPANY</Typography>
            <Box marginTop={'24px'}>
            {['About', 'Partners'].map(item => <Typography onClick={() => item === 'Partners' ? navigate('/partners'): null} color={'text.secondary'} sx={{cursor: "pointer", "&:hover": {color: '#003FB9'}}} fontSize={'16px'} fontWeight={'400'} marginBottom={'12px'}>{item}</Typography>)}
            </Box>
          </Box>
          </Box>
          <Box flex={1} sx={{marginTop :{xs: "48px", sm: "0px"}}}>
            <Typography color={'secondary'} fontSize={'16px'} fontWeight={'600'}>LOCATIONS</Typography>
            <Box marginTop={'24px'}>
            <USflag />
            <Typography color={'text.secondary'} fontSize={'16px'} marginBottom="24px" fontWeight={'400'}>31 Continental Dr, Suite <br/>305, Newark, Delaware <br/>19713, USA <br/>+1 202 600 5234</Typography>
            <Indiaflag />
            <Typography color={'text.secondary'} fontSize={'16px'} fontWeight={'400'} marginBottom={'24px'}>N-222, Greater Kailash 1, <br/>Delhi 110048, India <br/>+91 8800170387</Typography>
            <Typography color={'text.secondary'} fontSize={'16px'} fontWeight={'400'} marginBottom={'24px'}>sales@zaperon.com</Typography>
            
            </Box>
          </Box>
        </Box>
        <Box
          component={"div"}
          sx={{
            width: {lg: "1264px",xs: "100%"},
            overflowX: "hidden",
            height: "2px",
            backgroundColor: "#CEDCFF",
            padding: 0,
            margin: {
              lg: "10px 0px 0px 0px",
              md: "10px 0px 24px 0px",
              sm: "10px 0px 24px 0px",
              xs: "0px 0px 0px 0px",
            },
          }}
        />
        <Box
          component={"div"}
          display="flex"
          alignItems="center"
          justifyContent={{ lg: "center", xs: "left" }}
          marginBottom={'32px'}
          marginTop="32px"
          width={{lg: '1264px', xs: "fit-content"}}
          sx={{paddingLeft: {sm: "32px", xs: "16px"}, paddingRight: {sm: "32px", xs: "10px"}}}
          flexDirection={{ lg: "row", md: "column", sm: "column", xs: "column" }}
        >
          <Box component={"div"} paddingBottom={{ lg: "0px", md: "0px", sm: "0px", xs: "0px" }} display="flex" justifyContent={'left'} width={'fit-content'} marginRight={'auto'} alignItems={'baseline'} gap={1}>
            <Typography
              component={"span"}
              sx={{
                font: {
                  lg: "normal normal 400 16px/25px Poppins",
                  md: "normal normal 400 16px/21px Poppins",
                  sm: "normal normal 400 16px/21px Poppins",
                  xs: "normal normal 400 16px/18px Poppins",
                },
                color: "#878787",
              }}
            >
              © {date.getFullYear()} Zaperon, Inc.
            </Typography>
          <Box
            
            component={"span"}
            sx={{
              font: {
                lg: "normal normal 400 16px/25px Poppins",
                md: "normal normal 400 16px/25px Poppins",
                sm: "normal normal 400 16px/21px Poppins",
                xs: "normal normal 400 16px/18px Poppins",
              },
              color: "#878787"
            }}
          >
            {" "}All Rights Reserved.
          </Box>
          </Box>
         
          <Box flexGrow={1} />
          <Box component={"div"} display="flex" gap={2} alignItems={'center'} paddingTop={{lg: "0px",xs: "4px" }} marginRight={'auto'} p={{sm: "0px 0px 0px 0px", xs: "4px 0px 0px 0px"}}>
            <Link to="/privacyPolicy">
              <Typography
                className="p-tc"
                component={""}
                sx={{
                  color: "#0B3558",
                  font: {
                    lg: "normal normal 400 16px/25px Poppins",
                    md: "normal normal 400 16px/21px Poppins",
                    sm: "normal normal 400 16px/21px Poppins",
                    xs: "normal normal 400 16px/18px Poppins",
                  },
                  textDecoration: "none",
                  marginRight: { lg: "1px" },
                }}
              >
                Privacy Policy
              </Typography>
            </Link>
            
            <Typography
                className="p-tc"
                component={""}
                sx={{
                  color: "#6E6F70",
                  font: {
                    lg: "normal normal 400 16px/120% Poppins",
                    md: "normal normal 400 16px/120% Poppins",
                    sm: "normal normal 400 16px/120% Poppins",
                    xs: "normal normal 400 16px/120% Poppins",
                  },
                  textDecoration: "none",
                  marginRight: { lg: "1px" },
                }}
              >
                |
              </Typography>
            <Link to="/terms-conditions">
              <Typography
                // onClick={() => navigate("Terms&Condition")}
                className="p-tc"
                // paddingBottom={"32px"}
                component={""}
                sx={{
                  color: "#0B3558",
                  font: {
                    lg: "normal normal 400 16px/25px Poppins",
                    md: "normal normal 400 16px/21px Poppins",
                    sm: "normal normal 400 16px/21px Poppins",
                    xs: "normal normal 400 16px/18px Poppins",
                  },
                  textDecoration: "none",
                }}
              >
                Terms & Conditions
              </Typography>
            </Link>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default Footer;
