import React from "react";
import "./css/Organization.css";
import { Box, Card, CardContent, Stack, Typography} from "@mui/material";
import CountUp from 'react-countup';
import { useInView } from 'react-intersection-observer';
export const NumberCard = ({title, subtitle, suffix, endValue, prefix, decimals, width}) => {
  const { ref, inView } = useInView({
    triggerOnce: true, // Trigger animation only once
    threshold: 0.5,    // Animation triggers when 50% of the component is visible
  });
return <Card  ref={ref} variant="elevation" sx={{ minWidth: {lg: 357, xs: "fit-content"}, paddingTop: "10px", border: "none", minHeight: {lg: 214, xs: 'fit-content'}, maxHeight: {lg: 600, xs: 'fit-content'}, boxShadow: "none", background: "transparent", display: "flex", flexDirection: "column", alignItems: "center" }}>
 <Typography gutterBottom variant="h5" component="div" sx={{
    fontFamily: 'Poppins',
    fontWeight: 700,
    fontSize: {lg: '80px', xs: "48px"},
    lineHeight: {
      xs: '120%',
      sm: '120%',
      md: '120%',
      lg: '120%',
    },
    color: "#fff",
    margin: 0,
  }}>
      {inView && (
          <CountUp style={{fontWeight: "600"}} className="count-text" prefix={prefix} start={0} end={endValue || 86} duration={2.5} suffix={suffix || ""} decimals={decimals || 0} />
        )}
  </Typography>
<CardContent sx={{ position: "relative", zIndex: 1, background: "transparent", padding: 0, paddingTop: '24px' }}>
  <Typography gutterBottom variant="h5" component="div" sx={{
    color: "#fff",
    fontFamily: 'Poppins',
    textAlign: "center",
    fontWeight: 600,
    width: {md: '357px', xs: "fit-content"},
    minHeight: {md: "80px", xs: "fit-content"},
    marginBottom: "12px",
    fontSize: {
      xs: '20px',
      sm: '20px',
      md: '20px',
      lg: '20px',
    },
    lineHeight: {
      xs: '120%',
      sm: '120%',
      md: '120%',
      lg: '120%',
    },
  }}>
    {title || "of data breaches involve compromised identities"}
  </Typography>
  <Typography variant="body2" color="text.secondary" sx={{
    color: "#fff",
    fontFamily: 'Poppins',
    fontWeight: 400,
    textAlign: "center",
    fontSize: {
      xs: '16px',
      sm: '16px',
      md: '16px',
      lg: '16px',
    },
    lineHeight: {
      xs: '25px',
      sm: '24px',
      md: '24px',
      lg: '24px',
    },
  }}>
    {subtitle || "Verizon DBIR 2023"}
  </Typography>
</CardContent>
</Card>
}

function Organizations() {

  return (
    <Box sx={{overflowX: "hidden"}}
      className="texture-background"
    >
        <Box display={"flex"} flexDirection="column" justifyContent={"center"}>
          <Typography
            variant="h2"
            sx={{
              m: {
            
                sm: "80px 0px 80px 0px",
                xs: "64px 0px 48px 0px",

              },
              font: {
                lg: "normal normal 600 48px/120% Poppins",
                md: "normal normal 600 48px/120% Poppins",
                sm: "normal normal 600 48px/120% Poppins",
                xs: "normal normal 600 32px/120% Poppins",
              },
              color: "#FFFFFF",
              textAlign: "center"
            }}
          >
          Identity is now the #1 Attack Surface
          </Typography>
            <Stack flexDirection={{lg:'row', xs: "column"}} alignItems={'center'} justifyContent={'center'} width={'100%'} marginBottom={"48px"}>
            <Box style={{paddingLeft: "0px", paddingRight: '0'}}>
                <NumberCard suffix={"%"} />
            </Box>
            <Box style={{paddingLeft: '32px', paddingRight: '32px'}} className="blur-border">
                <NumberCard endValue={328} title={'Number of days it took to identify and contain data breaches resulting from stolen credentials'} subtitle={'IBM Data Breach 2023'} />
            </Box>
            <Box sx={{paddingLeft: {lg:'32px', xs: 0}, paddingRight: {xs: "0px", lg: "0px"}}}>
              <NumberCard decimals={2} suffix={'M'} prefix={'$'} endValue={4.62} title={'Average cost of an identity-based attack on enterprise'} subtitle={'IBM Data Breach 2023'} />
            </Box>
            </Stack>
        </Box>
    </Box>
  );
}

export default Organizations;
