import React from 'react'

const FieldError = (props) => {
  return (<>
    {props.error && <div style={{
        position: "absolute",
        zIndex: 500,
        top: props.top? props.top: "-5px",
        left: props.left? props.left: "68%",
      }}>
        <div style={{
          backgroundColor: "#D9292B",
          letterSpacing: " var(--unnamed-character-spacing-0)",
          color: "#ffffff",
          padding: "8px 5px",
          font: "normal normal 600 14px/19px Open Sans",
          width: "fit-content",
          height: "35px",
          borderRadius: "4px",
          marginTop: "5px",
        }}>
          <span>{props.msg}</span>
        </div>
        <div style={{
          position: "absolute",
          width: "0",
          height: "0",
          borderLeft: "7px solid transparent",
          borderRight: " 7px solid transparent",
          borderTop: " 7px solid #D9292B",
          top: props.arrowTop? props.arrowTop:"39px",
          left: props.arrowLeft? props.arrowLeft:"92%",
          transform: "translateX(-50%)",
        }}> </div>
      </div>}
  </>

  )
}

export default FieldError