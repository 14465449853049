import axios from 'axios';
import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import Header from './ui/Header2';
import Loader from "./img/ic_zaperon_loader.json"
import { Box, Modal } from '@mui/material';
import Lottie from 'lottie-react';

const VerifyStatus = () => {
  const navigate = useNavigate();
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const apiUrl = `${baseUrl}confirmUserActivation`;
  // console.log(baseUrl);


  const verifyActivationStatus = async (token) => {
    try {

      const headers = {
        'Content-Type': 'application/json',
        'Authorization': token
      };
      await axios.get(apiUrl, { headers });
      const expirationDate = new Date();
      expirationDate.setDate(expirationDate.getDate() + 7);
// eslint-disable-next-line
      const cookieValue = encodeURIComponent('verified') + '; expires=' + expirationDate.toUTCString();
      // eslint-disable-next-line
      document.cookie = 'verified' + '=' + true;
      navigate("/activation/confirmed")

    } catch (error) {

      navigate("/linkExpired")
    }
  }

  useEffect(() => {
    const path = window.location.pathname;
    const token = path.split("/verify=")[1];
    verifyActivationStatus(token)
    // eslint-disable-next-line
  }, [])

  return (
    <>
      <Header />
      <div style={{ background: "#EAEFFB", width: "100%", height: "100vh" }}>
        <Modal
          keepMounted
          open={true}
          style={{ zIndex: "500" }}
          aria-labelledby="keep-mounted-modal-title"
          aria-describedby="keep-mounted-modal-description"
        >
          <Box sx={{ width: "100vw", height: "100vh", background: " #00000080 0% 0% no-repeat padding-box", display: "flex", alignItems: "center", justifyContent: "center" }}>
            <Lottie animationData={Loader} />
          </Box>
        </Modal>
      </div>
    </>
  )
}

export default VerifyStatus