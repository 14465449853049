import { Box, Typography, Card, CardContent, CardMedia, Button, Grid } from '@mui/material'
import React from 'react'
import securePass from '../img/webp/02-Usecase Section/img-secure-access.webp'
import complianeImg from '../img/webp/02-Usecase Section/img-compliane_and_insurance.webp'
import phishing1 from '../img/webp/02-Usecase Section/img-mitigate-insider-threats.webp'
import phishing2 from '../img/webp/02-Usecase Section/img-prevent-phishing-attacks.webp'
import preventleaksImg from '../img/webp/02-Usecase Section/img-prevent-data-leak.webp'
import secureByodImg from '../img/webp/02-Usecase Section/img-byod-unmanaged-devces.webp'


import './css/ProtectIdentity.css'
import {ReactComponent as ArrowIcon} from "../img/visit-link-arrow.svg"
import { useNavigate } from 'react-router-dom'

export const ProtectIdentityCard = ({title, subtitle, imageLink}) => {
    return <>
<Card   sx={{ maxWidth: {lg: 384, sm: 702, xs: 702}, minWidth: {lg: 384, sm: 'fit-content', xs: '100%'}, minHeight: {md: 388, sm: 328, xs: 400}, maxHeight: 'fit-content', borderRadius: "12px", border: "2px solid #CEDCFF", boxShadow: "none" }}>
      <CardMedia
        component="img"
        sx={{
          height: 180, 
          maxWidth: '100%', 
          objectFit: 'cover', 
          transition: 'transform 0.3s', 
          '&:hover': {
            transform: 'scale(1.3)',
          },
        }}
        image={imageLink || securePass} // Replace with your image source
        alt="green iguana" // Replace with your alt text
      />
      <CardContent sx={{ padding: '24px', position: "relative", zIndex: 1, background: "#FFFFFF" }}>
        <Typography gutterBottom variant="h5" component="div" sx={{
          fontFamily: 'Poppins',
          fontWeight: 600,
          minHeight: {md: 48, sm: 24, xs: 48},
          marginBottom: "12px",
          fontSize: {
            xs: '16px',
            sm: '20px',
            md: '20px',
            lg: '20px',
          },
          lineHeight: {
            sm: '120%',
            md: '120%',
            lg: '120%',
          },
        }}>
          {title || "Prevent Phishing Attacks"}
        </Typography>
        <Typography variant="body2" color="#6E6F70" sx={{
          fontFamily: 'Poppins',
          fontWeight: 400,
          fontSize: {
            xs: '16px',
            sm: '16px',
            md: '16px',
            lg: '16px',
          },
          lineHeight: {
            sm: '150%',
            md: '150%',
            lg: '150%',
          },
        }}>
          {subtitle || "Zaperon eliminates passwords and validates 15+ factors related to user, device and context, shielding your organization from phishing threats"}
        </Typography>
      </CardContent>
    </Card>
    </>
}
const ProtectIdentity = () => {
  const navigate = useNavigate()

  return (
    <Box
    component={'div'}
    className='protectIdentityBg'
  >
  <Box component={'div'} className=''>
      <Box display={"flex"} flexDirection="column" justifyContent={"center"} >
        <Typography
          variant="h2"
          sx={{
            p: {
              lg: "80px 0px 64px 0px",
              md: "80px 0px 64px 0px",
              sm: "80px 0px 80px 0px",
              xs: "48px 0px 32px 0px",
            },
            font: {
              lg: "normal normal 600 48px/120% Poppins",
              md: "normal normal 600 48px/120% Poppins",
              sm: "normal normal 600 48px/120% Poppins",
              xs: "normal normal 600 24px/120% Poppins",
            },
            color: "#0B3558",
            textAlign: "center"
          }}
        >
        Protect Identities. Block Hackers.
        </Typography>
          
        <Box>
        <Grid width={{lg:'1200px', md: "800px"}} container sx={{ marginLeft: {md: "auto", xs: "auto"},marginRight: "auto", '& > *': { padding: {xs: '16px',sm: "32px"} } }}>
      {/* Each ProtectIdentityCard component will occupy half of the grid on md screens */}
      <Grid item xs={12} md={4}>
        <ProtectIdentityCard title={'Prevent Phishing Attacks'} subtitle={'Zaperon eliminates passwords and validates 15+ factors related to user, device and context, shielding your organization from phishing threats'} imageLink={phishing2}/>
      </Grid>
      <Grid item xs={12} md={4}>
        <ProtectIdentityCard title={'Secure Access to Resources'} subtitle={'Users can securely access any application (SaaS, on-premise, cloud and desktop), Windows/Linux servers and network devices'} imageLink={securePass} />
      </Grid>
     
     
      <Grid item xs={12} md={4}>
        <ProtectIdentityCard title={'Prevent Data Leak from Cloud Apps & Endpoints'} subtitle={'Restrict access to data residing in cloud applications and prevent data transfer from endpoints via USB, Bluetooth and Airdrop'} imageLink={preventleaksImg}/>
      </Grid>
      <Grid item xs={12} md={4}>
        <ProtectIdentityCard title={'Secure BYOD and Unmanaged Devices'} subtitle={'Ensure all personal BYOD and unmanaged 3rd party devices are compliant to your policies before granting access to apps'} imageLink={secureByodImg}/>
      </Grid>
      <Grid item xs={12} md={4}>
        <ProtectIdentityCard title={'Mitigate Insider Threats'} subtitle={'Zaperon safeguards your critical data by blocking unauthorized access and neutralizing insider threats'} imageLink={phishing1}/>
      </Grid>
      <Grid item xs={12} md={4}>
        <ProtectIdentityCard title={'Comply with regulations & insurance needs'} subtitle={'Enterprises can meet compliance requirement of GDPR, PCI-DSS, HIPAA, SOX, GBLA, RBI, SEBI and lower cyber insurance premiums'} imageLink={complianeImg}/>
      </Grid>
    </Grid>
    <Box sx={{marginLeft: "auto", marginRight: "auto", width: 'fit-content'}}>
    <Button onClick={() => navigate('/useCases')} variant="contained" endIcon={<ArrowIcon fill='#FFF' />} sx={{marginTop: "32px", marginBottom: "64px", minWidth: {xs: "85vw", lg: "305px"}, background: "#003FB9", textTransform: "capitalize", width: 'fit-content', height: 48, fontWeight: '600', fontSize: {sm: "20px", xs: "16px"}, lineHeight: "24px", fontFamily: "Poppins", marginRight: "auto", marginLeft: "auto"}}>Explore Use Cases</Button>
    </Box>
    </Box></Box>
    </Box></Box>
  )
}

export default ProtectIdentity