import { Box, Container, Typography } from '@mui/material'
import React from 'react'
import {ReactComponent as VisitLinkSvg} from "../img/visit-link-arrow.svg"
import styles from './css/Solution.module.css'
import AppIntegrationImg from "../img/img-app-integration.webp"
const IntegrateApp = () => {
  return (
    <Box component={'div'} className={styles.integrateAppBg}
  >
    <Container>
      <Box display={"flex"} flexDirection="column" padding={"0px 10px"} justifyContent={"center"} width={{lg: "100%", xs: "fit-content"}} alignItems={'center'}>
        <Typography
          variant="h2"
          sx={{
            p: {
              sm: "80px 0px 48px 0px",
              xs: "64px 0px 48px 0px",
            },
            font: {
              lg: "normal normal 600 48px/120% Poppins",
              md: "normal normal 600 48px/120% Poppins",
              sm: "normal normal 600 48px/120% Poppins",
              xs: "normal normal 600 32px/120% Poppins",
            },
            color: "#0B3558",
            textAlign: "center"
          }}
        >
        Integrates with Every Enterprise Application
        </Typography>
        <Typography onClick={() => window.location.href = 'https://docs.zaperon.com'} sx={{fontWeight: "600", cursor: "pointer", fontSize: {lg: "20px", xs: "16px"}, textAlign: "center", marginBottom: "48px"}} color={"primary"}>Explore All Integration Docs <VisitLinkSvg fill='rgba(0, 63, 185, 1)'/></Typography>
        <Box>
          <Box component={'img'} width={{lg: "fit-content", sm: "704px", xs: "328px"}} src={AppIntegrationImg} />
        </Box>
        </Box>
        </Container>
        </Box>
  )
}

export default IntegrateApp