/* eslint-disable */

import React, { useRef, useState } from 'react'
import { Box, Button, Container, Input, Typography } from '@mui/material'
import blogimg1 from "./img/Blog_Post_1.png"
import Footer from './sections/Footer';
import "./sections/css/Blogpost.css";
import link from "./img/ic_link.svg"
import Scrolldivider from './sections/Scroll_divider';
import blog1 from './img/blog1.jpeg'
import blog2 from './img/blog_post_2.png'
import { Link } from "react-router-dom";
import Header2 from "./ui/Header2";
import emailjs from '@emailjs/browser';
import { useNavigate } from "react-router-dom";
import Lottie, { LottiePlayer } from "lottie-react";
import Skeleton from '@mui/material/Skeleton';

import { useEffect } from 'react';
import fb from './Firebase'
import BlogList from './BlogList';
import LoadingAnimation from './ui/LoadingAnimation';
import { Helmet } from 'react-helmet';

const DB = fb.firestore();
const BlogsList = DB.collection('blogs');

export default function Blogpost() {

  const [blogsList, setBlogsList] = useState([]);
  const [loading, setLoading] = useState(true);

  const form = useRef();
  const navigate = useNavigate();

  // console.log("Blogs List : ", blogsList)
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setLoading(true);
    const unsubscribe = BlogsList.limit(100).onSnapshot(querySnapshot => {
      // Get all documents from collection - with IDs
      const data = querySnapshot.docs.map(doc => ({
        ...doc.data(),
        id: doc.id
      }));
      // Update state
      setBlogsList(data);
    });

    // Detach listener
    // setLoading(false);
    return unsubscribe;
  }, [])

  useEffect(() => {
    if (blogsList.length > 0) {
      let temp = [];
      for (let blog in blogsList) {
        temp.push(blogsList[blog].id)
      }
      localStorage.setItem("blogsId", JSON.stringify(temp));
      setLoading(false)
    }
  }, [blogsList])


  const handlesubmit = (e) => {
    e.preventDefault()
    emailjs.sendForm(process.env.REACT_APP_service_ID, process.env.REACT_APP_SUB_template_ID, form.current, process.env.REACT_APP_public_key)
      .then((result) => {
        console.log(result.text);
      }, (error) => {
        console.log(error.text);
      });


    navigate("/thanks");
  }
  return (
    <>
      <Helmet>
        <link rel="canonical" href={"https://www.zaperon.com/blog"} />
      </Helmet>
      {/* {loading && <div>
        <LoadingAnimation />
      </div>} */}
      {/* <Header2 /> */}
      <Container className='blogbody' display={"flex"} width={1250} >
        <div style={{ marginTop: "120px", font: "normal normal bold 32px/48px Poppins", color: "#003FB9" }}>Recent Post</div>
        {blogsList && blogsList.length > 0 &&
          <Box className='blog1' component={"div"} display={"flex"} alignItems={"center"}>
            <Box className='blog1img' component={"div"} >
              <img component={"img"} src={blogsList[0].image} style={{ minHeight: "300px" }} alt={blogsList[0].title} />
            </Box>
            <Box style={{}} className='blogdesc1' component={"div"}  >
              <div className='blog1date'>{blogsList[0].blogDate}</div>
              <Link to={`${blogsList[0].link}?id=${blogsList[0].id}`} >
                <h1 className='heading'>{blogsList[0].title}{' '}
                  <img src={link} className="hyperlink" style={{ display: "inline-flex" }} alt="" />
                </h1>
              </Link>
              <p>{blogsList[0].overviewPara}</p>
            </Box>
          </Box>}
        <Box component={"div"} width={"100%"} style={{ display: "inline-block" }}>

          <div className='allblog'> All Blog Posts</div>

        </Box>

        <div className='grid'   >

          {blogsList && blogsList.map((blog, index) => (

            index !== 0 && (

              <Box key={blog.id} className="box">
                {/* {console.log("datas , ", index, " : ", blog)} */}
                <Link to={`${blog.link}?id=${blog.id}`} className='bloglist-box' style={{textDecoration:"none" }} > <span style={{ marginTop: "-16px", color: "#0B3558", width: "90%", height: "103px" }}>
                  <div className='img-container'>
                    <div style={{ position: 'absolute', zIndex: 1 }}>
                      <Skeleton variant="rectangular" />
                    </div>
                    <img className="boximg" style={{ widows: "100%" }} src={blog.image} alt={blog.title} />
                  </div>
                  <div className="date">{blog.blogDate}</div><br />
                  <h1 className='boxhead' style={{ whiteSpace: "inherit", textDecoration:"" }}  >
                    {blog.title} {' '}
                    <img src={link} className="hyperlink" width={"18px"} height={"18px"} style={{ display: "inline-block", marginLeft: "2px" }} alt="" />
                  </h1>
                </span>
                  <p className="boxpara" style={{ marginBottom: "40px", textDecoration:"none" }}>{blog.overviewPara}</p>
                </Link>
              </Box>
            )
          ))}

        </div>

        <Box component={"div"} width={"100%"} height={39} sx={{ display: "flex", flexDirection: "row", marginTop: "40px", marginBottom: "70px" }}>
          <Button disabled style={{ marginLeft: "0", width: "134px", height: "39px", background: "#EEF3FF 0% 0% no-repeat padding-box", border: "1px solid #CDD3E0", borderRadius: "4px", color: "#CDD3E0", font: "normal normal medium 16px/25px Poppins" }}> <span style={{ font: "normal normal 400 16px/25px Poppins", textTransform: "none" }}> Previous</span></Button>
          <Container display={"flex"} width={870} component={"div"} style={{}}>
            <button width={32} style={{ border: "none", background: "#003FB9 0% 0% no-repeat padding-box", width: "32px", marginLeft: "44%", height: "32px", borderRadius: "4px" }}> <span style={{ color: "#FFFFFF" }}>1</span></button>
          </Container>
          <Button disabled style={{ marginLeft: "0", width: "134px", height: "39px", background: "#EEF3FF 0% 0% no-repeat padding-box", border: "1px solid #CDD3E0", borderRadius: "4px", color: "#CDD3E0" }}> <span style={{ font: "normal normal 400 16px/25px Poppins", textTransform: "none" }}> <span style={{ font: "normal normal 400 16px/25px Poppins !important" }} >Next</span></span></Button>
        </Box>
        <div className='subscribe' >
          <div className='sub'  >
            <h2 className='subheader' style={{color:"#FFFFFF"}}> Subscribe to our newsletter</h2>
            <p> Stay up to date with the latest news, articles, etc.</p>
          </div>
          <div className='subbtn' style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
            <form ref={form} onSubmit={handlesubmit}>
              <input className='email'
                type="email"
                name='from_email'
                id="from_email"
                required
                placeholder="Enter your email"
              />
              <button className='subscribebtn' type='submit'>  Subscribe</button>
            </form>
          </div>
        </div>
        {/* </div> */}
        <div style={{ marginTop: "32px" }}>
          <Scrolldivider className="scroll" />
        </div>
        <Footer />
      </Container>
    </>
  )
}
