/* eslint-disable */

import React from 'react'
import { useEffect, useState, useRef } from 'react';

import Header from './ui/Header2';
import Scrolldivider from './sections/Scroll_divider';

import fb from './Firebase'
import { json, useNavigate, useSearchParams } from 'react-router-dom';
import { Box, Button, Container, Skeleton, makeStyles } from '@mui/material';
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import Footer from './sections/Footer';
import emailjs from '@emailjs/browser';
import LoadingAnimation from './ui/LoadingAnimation';
import LinkedInShareButton from './ui/LinkedInShareButton';
import { Helmet } from 'react-helmet';

const DB = fb.firestore();

const BlogDetail = () => {

    const [params] = useSearchParams();
    const [data, setData] = useState("");
    const [disableNext, setDisableNext] = useState(false);
    const [disablePrev, setDisablePrev] = useState(true);
    const [loading, setLoading] = useState(true);
    const [schema, setSchema] = useState();
    const [showDate, setShowDate] = useState(true);
    const form = useRef();
    const navigate = useNavigate();


    useEffect(() => {
        // Scroll to the top of the page when the component mounts
        window.scrollTo(0, 0);
    }, []);


    const handlesubmit = (e) => {
        e.preventDefault()
        emailjs.sendForm(process.env.REACT_APP_service_ID, process.env.REACT_APP_SUB_template_ID, form.current, process.env.REACT_APP_public_key)
            .then((result) => {
                console.log(result.text);
            }, (error) => {
                console.log(error.text);
            });

        navigate("/thanks");

    }

    useEffect(() => {
        const handleScroll = () => {
            const scrollPosition = window.scrollY || document.documentElement.scrollTop;

            const visibleHeight = window.innerHeight;

            const totalHeight = document.documentElement.scrollHeight;

            if (scrollPosition + visibleHeight >= totalHeight - 100) {
                setShowDate(false);
            }
            else {
                setShowDate(true);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    // console.log("blog detail data : ", data)

    useEffect(() => {
        const fetchDocument = async (id) => {
            try {
                const docRef = DB.collection('blogs').doc(id);
                const docSnapshot = await docRef.get();

                if (docSnapshot.exists) {
                    setData(docSnapshot.data());
                    // setSchema(docSnapshot.data().schema);
                } else {
                    console.log('Document not found.');
                }
            } catch (error) {
                console.error('Error fetching document:', error);
            }
        };
        const id = params.get('id')
        if (!id) {
            navigate("/page-not-found");
        }
        fetchDocument(id);
    }, [params])

    useEffect(() => {
        if (data !== "") {
            let tempSchema = {
                "@context": "http://schema.org/",
                "@type": "Article",
                "mainEntityOfPage": {
                    "@type": "WebPage",
                    "@id": window.location.href
                },
                "author": {
                    "@type": "Organization",
                    "name": "Zaperon"
                },
                "publisher": {
                    "@type": "Organization",
                    "name": "Zaperon",
                    "logo": {
                        "@type": "ImageObject",
                        "url": "https://www.zaperon.com/static/media/ic_zaperon_logo.0f0385a7a03a960d29237b40cf8e0163.svg"
                    }
                },
                "headline": data.metaTitle,
                "image": data.image,
                "datePublished": "2023-11-15",
                "dateModified": "2023-11-15"
            };
            setSchema(tempSchema);
        }
    }, [data])

    // console.log(schema)

    useEffect(() => {
        if (data !== "") {
            setLoading(false);
            document.title = data.metaTitle;
            document.querySelector('meta[name="description"]').setAttribute('content', data.metaDesc);

            const metaTags = [
                { property: 'og:title', content: data.title },
                { property: 'og:description', content: data.ogDescription ? data.ogDescription : data.metaDesc },
                { property: 'og:image', content: data.image },
                { property: 'og:url', content: window.location.href },
                { property: 'og:type', content: 'website' },
                // Add more Open Graph meta tags as needed
            ];

            metaTags.forEach((tag) => {
                const metaTag = document.createElement('meta');
                metaTag.setAttribute('property', tag.property);
                metaTag.setAttribute('content', tag.content);
                document.head.appendChild(metaTag);
            });

            // Cleanup function to remove added meta tags on component unmount
            return () => {
                metaTags.forEach((tag) => {
                    const existingTag = document.querySelector(`meta[property="${tag.property}"]`);
                    if (existingTag) {
                        document.head.removeChild(existingTag);
                    }
                });
            };

        }
    }, [data])

    // useEffect(() => {
    //     // Check if data is available
    //     if (data) {
    //         // Log the updated head content using react-helmet API
    //         const helmet = Helmet.peek();
    //         console.log('Updated Head Content:', helmet.metaTags);
    //     }
    // }, [data]);


    // useEffect(()=>{
    //     if(data !==""){

    //     }
    // },[data])

    const nextClickHandler = () => {
        const currId = params.get('id')
        const idArrJson = localStorage.getItem("blogsId");
        const idArr = JSON.parse(idArrJson)

        for (let i = 0; i < idArr.length; i++) {
            if (currId === idArr[idArr.length - 1]) {
                setDisableNext(true);
                return;
            }
            if (currId === idArr[i]) {
                setDisablePrev(false);
                navigate(`/getBlogDetails?id=${idArr[i + 1]}`)
                window.scrollTo(0, 0);

            }
        }
    }

    const prevClickHandler = () => {
        const currId = params.get('id')
        const idArrJson = localStorage.getItem("blogsId");
        const idArr = JSON.parse(idArrJson)

        for (let i = 0; i < idArr.length; i++) {
            if (currId === idArr[0]) {
                setDisablePrev(true);
                return;
            }
            if (currId === idArr[i]) {
                setDisablePrev(false);
                navigate(`/getBlogDetails?id=${idArr[i - 1]}`)
                window.scrollTo(0, 0);

            }
        }
    }

    return (
        <>
            <Helmet>
                <link rel="canonical" href={"https://www.zaperon.com/blog"} />
            </Helmet>
            {loading && <div>
                <Skeleton variant="rectangular" height={"100vh"} />
            </div>
            }
            {/* <Header /> */}
            <div width={1140} className='detbody' component={"div"} display={"flex"} sx={{ marginTop: "80px" }}>
                {data != "" && <Helmet>
                    <meta property="og:title" content={data.title} />
                    <meta property="og:description" content={data.ogDescription ? data.ogDescription : data.metaDesc} />
                    <meta property="og:image" content={data.image} />
                    <meta property="og:url" content={window.location.href} />
                    <meta property="og:type" content="website" />
                </Helmet>}
                {schema && <Helmet>
                    <script type="application/ld+json">{JSON.stringify(schema)}</script>
                </Helmet>}
                <Helmet>
                    <link rel="canonical" href={window.location.href} />
                </Helmet>
                <Box component={"div"} display={"flex"} justifyContent={"center"} >
                    <Box display={{
                        lg: "initial",
                        md: "none"
                    }}>
                        <div className='dethead det1' style={{ marginTop: "100px", position: `${showDate ? "fixed" : "initial"}`, left: "3%" }}>
                            <p style={{ font: "normal normal bold 16px/25px Poppins", letterSpacing: "1.6px", color: "#0B3558", marginBottom: "1px" }}>
                                DATE
                            </p>
                            <p style={{ font: "normal normal normal 16px/25px Poppins", color: "#0B3558", opacity: "1" }}>
                                {data.blogDate}
                            </p>
                            <p style={{ color: "#0B3558", font: "normal normal bold 16px/25px Poppins" }}>SHARE</p>
                            <LinkedInShareButton
                                url={window.location.href}
                                title={data.title}
                            />
                            {/* <a href='https://www.linkedin.com/company/securewithzaperon/' target='_blank'>
                            <p style={{ display: "flex", marginLeft: "10px" }}>
                            <LinkedInIcon className="hovers" />
                            </p>
                        </a> */}
                        </div>
                    </Box>
                    <div className='blogimage' component={"div"} >
                        <img src={data.image} width={"100%"} style={{ maxHeight: "562px", marginTop: "40px" }} alt="What is Zero Trust security?" />
                    </div>

                </Box>

                <Container component={"div"} width={"100%"} style={{ display: "inline-block", font: "normal normal normal 18px/27px Poppins", color: "#42424E", marginTop: "-30px" }}>
                    <Box component={"span"} display={"flex"} className='html-content-box'>

                        <div style={{ width: "100vw" }} >

                            <h1 className='dethead'>{data.title}</h1>
                            <div dangerouslySetInnerHTML={{ __html: data.mainBody }} />


                        </div>

                    </Box>
                    <Box component={"div"} width={"100%"} height={39} sx={{ display: "flex", flexDirection: "row", marginTop: "40px", marginBottom: "70px" }}>
                        <Button
                            onClick={prevClickHandler}
                            disabled={disablePrev}
                            style={{
                                marginLeft: "0", width: "134px", height: "39px", background: `${disablePrev ? "#EEF3FF 0% 0% no-repeat padding-box" : "#003FB9 0% 0% no-repeat padding-box"}`, border: "1px solid #CDD3E0", borderRadius: "4px", color: "#FFFFFF"
                            }}                        >
                            <span style={{ font: "normal normal 400 16px/25px Poppins", textTransform: "none" }}> Previous</span></Button>


                        <Container display={"flex"} width={870} component={"div"} style={{}}>


                        </Container>
                        <Button
                            onClick={() => {
                                nextClickHandler()
                                // navigate("/blog/cybersecurity-solutions-working-in-silos")
                            }}
                            style={{
                                marginLeft: "0", width: "134px", height: "39px", background: `${disableNext ? "#EEF3FF 0% 0% no-repeat padding-box" : "#003FB9 0% 0% no-repeat padding-box"}`, border: "1px solid #CDD3E0", borderRadius: "4px", color: "#FFFFFF"
                            }}
                            disabled={disableNext}
                        >
                            <span style={{ font: "normal normal 400 16px/25px Poppins", textTransform: "none" }}> Next</span></Button>

                    </Box>
                    {/* <div className='top' style={{background:"transparent linear-gradient(97deg, #804A9C 0%, #003FB9 100%) 0% 0% no-repeat padding-box", height:"163px",width:"100%",borderRadius:"24px",marginBottom:"48px",justifyContent:"space-around"}}> */}
                    <div component={"div"} className='subscribe'   >
                        <div className='sub'  >
                            <h2 className='subheader' style={{ color: "#FFFFFF" }}> Subscribe to our newsletter</h2>
                            <p> Stay up to date with the latest news, articles, etc.</p>
                        </div>
                        <div className='subbtn' style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                            <form ref={form} onSubmit={handlesubmit}>
                                <input className='email'
                                    type="email"
                                    name='from_email'
                                    id="from_email"
                                    required
                                    placeholder="Enter your email"
                                />
                                <button className='subscribebtn' type='submit'>  Subscribe</button>
                            </form>
                        </div>
                    </div>
                    {/* </div> */}
                    <div style={{ marginTop: "32px" }}>
                        <Scrolldivider className="scroll" />
                    </div>
                </Container>

            </div>
            <Footer />
        </>
    )
}

export default BlogDetail