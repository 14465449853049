/* eslint-disable */

import React, { useEffect, useState } from "react";
import styles from  "./css/Solution.module.css";
import { Box, Container, Grid, Stack, Typography } from "@mui/material";
import {ReactComponent as NoPassSvg} from '../img/ic_no_password.svg'
import {ReactComponent as MfaSvg} from '../img/ic_mfa_card.svg'
import {ReactComponent as InsightSvg} from '../img/ic_realtime_insight.svg'
import {ReactComponent as IcBulletSvg} from '../img/ic_bullet.svg'
import {ReactComponent as DeviceTrustSvg} from '../img/ic_device_trust_card.svg'
import {ReactComponent as VisitLinkSvg} from "../img/visit-link-arrow.svg"
import FeatureImg1 from "../img/webp/05-Feature Section/img_feature_1.webp"
import FeatureImg2 from "../img/webp/05-Feature Section/img_feature_2.webp"
import FeatureImg3 from "../img/webp/05-Feature Section/img_feature_3.webp"
import FeatureImg4 from "../img/webp/05-Feature Section/img_feature_4.webp"
const ContentAreaResponsive = ({list1, list2, title, BigImg}) => {
  return <Box sx={{padding: {sm:"48px 0 100px 48px", xs: "32px 32px 100px 32px"}, overflow: "hidden", minHeight: {sm: 392, xs: 472}, maxHeight: {sm: 392, xs: 472}, position: "relative", background: "linear-gradient(107deg, #0B3558 1.24%, #003FB9 50.74%, #07456A 98.99%)", borderRadius: "12px", width: "93%", margin: "0 auto 32px auto", height: "fit-content", display: {xs: "flex", lg: 'none'}}}>
  <Box flex={2} width={'fit-content'} maxWidth={{xs: '100%', sm: "480px"}}>
    <p  className={styles.featureText}>{title}</p>
    <Stack flexDirection={'row'} alignItems={'center'} marginBottom={'13px'}>
    <Box style={{width: "fit-content"}}>
    <IcBulletSvg style={{width: "32px", height: "32px"}} />
    </Box>
    <Box width={'440px'}>
    <p className={styles.featureTextList}>{list1}</p>
    </Box>
    </Stack>
    <Stack flexDirection={'row'} alignItems={'center'} marginBottom={'48px'}>
    <Box style={{width: "fit-content"}}>
    <IcBulletSvg style={{width: "32px", height: "32px"}} />
    </Box>
    <Box width={'430px'}>
    <p className={styles.featureTextList}>{list2}</p>
    </Box>
    </Stack>
    <p className={styles.learnMore}>Learn More <VisitLinkSvg fill="#FFF"/></p>
  </Box>
  <Box flex={1} position={'absolute'} bottom={0} right={0}>
  <Box component={'img'} src={BigImg} sx={{width: {xs: 130, sm: 200}}} className={styles.dotGrid}  />
  </Box>
  </Box>
}
const ContentArea = ({BigImg, list1, list2, title}) => {
  return <Box style={{position: 'absolute', top: 155, left: 187, width: 458, display: "flex", alignItems: "start"}}>
  <Box>
    <p className={styles.featureText}>{title}</p>
    <Stack flexDirection={'row'} alignItems={'center'} marginBottom={'13px'}>
    <IcBulletSvg style={{width: "32px", height: "32px"}} />
    <Box width={'440px'}>
    <p className={styles.featureTextList}>{list1}</p>
    </Box>
    </Stack>
    <Stack flexDirection={'row'} alignItems={'center'} marginBottom={'48px'}>
    <IcBulletSvg style={{width: "32px", height: "32px"}} />
    <Box width={'430px'}>
    <p className={styles.featureTextList}>{list2}</p>
    </Box>
    </Stack>
    <p className={styles.learnMore}>Learn More <VisitLinkSvg fill="#FFF"/></p>
  </Box>
  <Box>
  <Box component={'img'} src={BigImg} sx={{width: 400, height: 400, transform: "translate(20px,-60px)"}} className={styles.dotGrid}  />
  </Box>
  </Box>
}

const CardBtn = ({selected, text, IconSvg, setSelected, containerMarginBottom}) => {
  return <>
    <Stack onMouseEnter={setSelected} flexDirection={'column'} justifyContent={'center'} alignItems={'center'} marginBottom={containerMarginBottom && '16px'} width={'142px'} borderRadius='4px' style={{background: selected && 'linear-gradient(91deg, #0B3558 0.45%, #003FB9 99.51%)'}} height={selected ? 126: 115}>
    <Box sx={{marginBottom: "8px"}}>
   <IconSvg fill={selected? '#FFF': "rgba(47, 94, 187, 1)"} style={{marginLeft: "auto", marginRight: "auto"}} />
</Box>

<p style={{color: selected? '#FFF': "#0B3558", fontWeight: selected? '600': '400'}} className={styles.cardBtnText}>{text || "Passwordless Authentication"}</p>
    </Stack>
  </>
}
function Solution() {
  const menuItems = ['password', 'mfa', 'device', 'insight']
  const [selected, setSelected] = useState('password')
  const [isHovered, setIsHovered] = useState(false);
  const switchMenuTime = 5000
  const getNextMenuItem = (current) => {
    const currentIndex = menuItems.indexOf(current);
    const nextIndex = (currentIndex + 1) % menuItems.length;
    return menuItems[nextIndex];
  };
  const contentObj = () => {
    switch (selected) {
      case 'password':
        return {list1: <>Replace passwords with a more secure <span style={{fontWeight: "600"}}>PKI-based digital identity</span></>, list2: <>Prevent account takeovers</>, BigImg: FeatureImg1, title: 'Eliminate Passwords' }
        case 'mfa':
          return {list1: <><span style={{fontWeight: "600"}}>Validate 15+ factors</span> related to user behaviour, device posture and context</>, list2: <><span style={{fontWeight: "600"}}>Protect assets that don’t support MFA</span> including legacy on-prem, desktop apps</>, BigImg: FeatureImg3, title: 'Enforce phishing-resistant MFA for all the Resources' }
          case 'device':
            return {list1: <>Continuously <span style={{fontWeight: "600"}}>check device posture</span></>, list2: <><span style={{fontWeight: "600"}}>Block insecure and unauthorized devices </span>from accessing apps</>,  BigImg: FeatureImg2, title: 'Validate Trust on Each Device' }
          case 'insight':
              return {list1: <><span style={{fontWeight: "600"}}>Get visibility</span> on application access and device compliance</>, list2: <>Improve response time drastically by <span style={{fontWeight: "600"}}>real-time detection of anomalies</span></>, BigImg: FeatureImg4, title: 'Stop Identity-based Threats in Real-time' }
      default:
        return {}
    }
  }
  const contentObjResponsive = (val) => {
    switch (val) {
      case 'password':
        return {list1: <>Replace passwords with a more secure <span style={{fontWeight: "600", fontSize: "16px"}}>PKI-based digital identity</span></>, list2: <>Prevent account takeovers</>, BigImg: FeatureImg1, title: 'Eliminate Passwords' }
        case 'mfa':
          return {list1: <><span style={{fontWeight: "600", fontSize: "16px"}}>Validate 15+ factors</span> related to user behaviour, device posture and context</>, list2: <><span style={{fontWeight: "600", fontSize: "16px"}}>Protect assets that don’t support MFA</span> including legacy on-prem, desktop apps</>, BigImg: FeatureImg3, title: 'Enforce phishing-resistant MFA for all the Resources' }
          case 'device':
            return {list1: <>Continuously <span style={{fontWeight: "600", fontSize: "16px"}}>check device posture</span></>, list2: <><span style={{fontWeight: "600", fontSize: "16px"}}>Block insecure and unauthorized devices </span>from accessing apps</>,  BigImg: FeatureImg2, title: 'Validate Trust on Each Device' }
          case 'insight':
              return {list1: <><span style={{fontWeight: "600", fontSize: "16px"}}>Get visibility</span> on application access and device compliance</>, list2: <>Improve response time drastically by <span style={{fontWeight: "600", fontSize: "16px"}}>real-time detection of anomalies</span></>, BigImg: FeatureImg4, title: 'Stop Identity-based Threats in Real-time' }
      default:
        return {}
    }
  }
  useEffect(() => {
    const timer = setInterval(() => {
      if (!isHovered) {
        let data = getNextMenuItem(selected);
        setSelected(data);
      }
    }, switchMenuTime);

    return () => clearInterval(timer);
  }, [selected, isHovered]);
  return (
   <Box paddingTop={{sm: "80px", xs: "64px"}} paddingBottom={{lg: "80px", sm: '80px', xs: "64px"}}>
    <Typography variant="h1" sx={{fontWeight: '600', fontFamily :"Poppins", fontSize: {sm: "48px", xs: "32px"}, color: "#0B3558", textAlign: "center", paddingBottom: {sm: '80px', xs: "48px"}, paddingLeft: {xs: "16px", sm: "32px"}, paddingRight: {xs: "16px", sm: "32px"}}}>Introducing <span className={styles.themeText}>Identity Protection 2.0</span></Typography>
    <Box onMouseLeave={() => setIsHovered(false)} onMouseEnter={() => setIsHovered(true)} sx={{display: {lg: 'block', xs: "none"}}} className={styles.bgBoard}>
    <Box marginTop={'12px'} padding={'2px'} sx={{boxSizing: "border-box",border: "double 2px transparent" ,backgroundOrigin: "border-box",backgroundClip: "content-box, border-box",backgroundImage: "linear-gradient(white, white), linear-gradient(to bottom, rgba(56, 15, 56, 1), rgba(95, 168, 255, 1))", transform: "translateX(-75px)", borderRadius: "12px"}} display={{lg: 'flex', xs: "none"}} flexDirection={'column'} justifyContent={'space-evenly'} alignItems={'center'} minWidth={'166px'} maxWidth={'fit-content'} maxHeight={'528px'} minHeight={'528px'}>
      <CardBtn IconSvg={(props) => <NoPassSvg {...props} />} setSelected={() => setSelected('password')} selected={selected === 'password'? true: false}  />
      <CardBtn IconSvg={(props) => <MfaSvg {...props} />} text={'Phishing-resistant MFA'} setSelected={() => setSelected('mfa')} selected={selected === 'mfa'? true: false} />
      <CardBtn IconSvg={(props) => <DeviceTrustSvg {...props} />} text={<>Device <br/> Trust</>} setSelected={() => setSelected('device')} selected={selected === 'device'? true: false} />
      <CardBtn IconSvg={(props) => <InsightSvg {...props} />} text={'Real-time Insights'} setSelected={() => setSelected('insight')} selected={selected === 'insight'? true: false} containerMarginBottom={0}/>
    </Box>
    <ContentArea {...contentObj()} />
    </Box>
    <ContentAreaResponsive {...contentObjResponsive('password')} />
    <ContentAreaResponsive {...contentObjResponsive('mfa')} />
    <ContentAreaResponsive {...contentObjResponsive('device')} />
    <ContentAreaResponsive {...contentObjResponsive('insight')} />


   </Box>
  );
}
export default Solution;
