/* eslint-disable */

import React from 'react'
import { Link } from 'react-router-dom'

const Card = ({ logo, name, country, link }) => {
    return (
        <div className='bgcard' style={{ width: "261px", height: "249px", backgroundColor: "#FFFFFF", borderRadius: "16px", marginBottom: "48px", marginRight: "32px", cursor: "pointer" }}>
            <a href={link} target='_blank' style={{textDecoration:"none"}}>
                <img src={logo} alt='logo' style={{ marginTop: "32px" }} />
                <p className='p' style={{ font: "normal normal 600 18px/27px Poppins", color: "#003FB9", marginTop: "24px", height: "52px", width: "229px", textAlign: "center", marginLeft: "auto", marginRight: "auto" }}>
                    {name}
                </p>
                <p className='p2' style={{ font: "normal normal medium 14px/21px Poppins", color: "#0B3558" }}>{country}</p>
            </a>
        </div>
    )
}
export default Card